import { restApi } from '../api/restApiSlice';
import Notification, { NotificationCategoryType } from './Notification';

type NotificationListQuery = {
  category: NotificationCategoryType;
  unread: boolean;
  page: number;
  pageSize: number;
};

type MarkAsReadBody = {
  loginUserNotificationIds: string[];
};

const notificationsApiSlice = restApi.injectEndpoints({
  endpoints: (builder) => ({
    getNotifications: builder.query<{ data: Notification[]; totalCount: number }, Partial<NotificationListQuery>>({
      query: (params) => ({ url: '/notifications', method: 'GET', params }),
      providesTags: ['Notifications'],
    }),
    getNotification: builder.query<Notification, string>({
      query: (notificationId) => ({ url: `/notifications/${notificationId}`, method: 'GET' }),
      transformResponse: (response: { data: Notification }) => response.data,
      providesTags: ['Notifications'],
    }),
    markAsRead: builder.mutation<MarkAsReadBody, {}>({
      query: (loginUserNotificationIds = []) => ({
        url: `/notifications/mark-as-seen`,
        method: 'PATCH',
        body: { loginUserNotificationIds },
      }),
      invalidatesTags: ['Notifications'],
    }),
  }),
});

export const { useGetNotificationsQuery, useGetNotificationQuery, useMarkAsReadMutation } = notificationsApiSlice;

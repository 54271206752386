import { legacyAPIRequest } from '../api/restApiSliceLegacy';
import { restApi } from '../api/restApiSlice';

import type ScheduledRecache from './ScheduledRecache';
import type { RecacheQueueQueryParams } from './QueryParameters';

type ListParameters = RecacheQueueQueryParams & {
  page: number;
  pageSize: number;
};

export const RecacheQueueSlice = restApi.injectEndpoints({
  endpoints: (builder) => ({
    getRecacheQueue: builder.query<ScheduledRecache[], { stateBucket: 'priority' | string; params: ListParameters }>({
      queryFn: async ({ stateBucket = 'priority', params }) => {
        try {
          const searchParams = new URLSearchParams({
            ...params,
            page: params.page.toString(),
            pageSize: params.pageSize.toString(),
          });

          const requestUri =
            stateBucket === 'priority'
              ? `priority-recache-queue?${searchParams.toString()}`
              : `recache-queue?${searchParams.toString()}`;

          const rawResult = await legacyAPIRequest({ url: requestUri, method: 'GET' });
          if (!rawResult.ok) throw new Error('Failed to fetch recache queue');

          const result = await rawResult.json();
          return { data: result };
        } catch (err) {
          const error = err as Error;
          return { error: { status: 'FETCH_ERROR', error: error.message } };
        }
      },
      providesTags: ['RecacheQueue'],
    }),
    togglePauseRecaching: builder.mutation<{ success: true }, {}>({
      queryFn: async () => {
        try {
          const requestUri = 'toggle-pause-recaching';
          const rawResult = await legacyAPIRequest({ url: requestUri, method: 'POST' });
          if (!rawResult.ok) throw new Error('Failed to toggle pause recaching');
          else return { data: { success: true } };
        } catch (err) {
          const error = err as Error;
          return { error: { status: 'FETCH_ERROR', error: error.message } };
        }
      },
    }),
  }),
});

export const { useGetRecacheQueueQuery, useTogglePauseRecachingMutation } = RecacheQueueSlice;

import { ConfigProvider, Empty, Flex, Input, Table, Tooltip, Typography } from 'antd';
import { DesktopOutlined, MobileOutlined, SearchOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';
import AnalyticsExportButton from './AnalyticsExportButton';
import { UserAgentLogo } from '../../UserAgents';

const formatTimeStamp = (data) => {
  return data ? <>{dayjs(data).format('YYYY-MM-DD')}</> : <>{'-'}</>;
};

export const AnalyticsTable = ({
  pagination = { current: 1, pageSize: 50, total: 0 },
  exportQuery,
  dataSource,
  handleTableChange,
  loading,
  onSearch,
}) => {
  return (
    <>
      <Flex justify="space-between">
        <Input
          placeholder="search url"
          allowClear
          prefix={<SearchOutlined style={{ color: '#D9D9D9' }} />}
          style={{ marginBottom: 16, width: '30%' }}
          onChange={onSearch}
        />
        <AnalyticsExportButton totalCount={pagination.total} query={exportQuery} />
      </Flex>
      <ConfigProvider renderEmpty={() => <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="No URLs found" />}>
        <Table
          bordered
          pagination={pagination}
          dataSource={dataSource}
          onChange={handleTableChange}
          loading={loading}
          scroll={{ x: 1000 }}
          sticky={{ offsetHeader: 0, offsetScroll: 10 }}
          size="middle"
        >
          <Table.Column
            title="URL"
            dataIndex="url"
            key="url"
            render={(url) => (
              <Typography.Link href={url} target="_blank" rel="noreferrer">
                {url}
              </Typography.Link>
            )}
          />
          <Table.Column
            title="Device"
            dataIndex="adaptive_type"
            key="adaptive_type"
            align="center"
            filters={[
              {
                text: 'Desktop',
                value: 'desktop',
              },
              {
                text: 'Mobile',
                value: 'mobile',
              },
            ]}
            width="10%"
            render={(adaptive_type) => (
              <Tooltip
                title={`Rendering was optimized for [${adaptive_type === 'mobile' ? 'Mobile' : 'Desktop'}] crawlers`}
                placement="top"
              >
                {adaptive_type === 'mobile' ? <MobileOutlined /> : <DesktopOutlined />}
              </Tooltip>
            )}
          />
          <Table.Column
            title="User Agent"
            dataIndex="user_agent"
            key="user_agent"
            width="10%"
            align="center"
            render={(text) => <UserAgentLogo userAgent={text} />}
          />
          <Table.Column
            title="Cached On"
            dataIndex="page_cached_at"
            key="page_cached_at"
            align="center"
            width="12%"
            render={(item) => formatTimeStamp(item)}
          />
          <Table.Column
            title="Last Requested"
            dataIndex="last_requested_at"
            key="last_requested_at"
            sorter
            align="center"
            width="15%"
            render={(item) => formatTimeStamp(item)}
          />
        </Table>
      </ConfigProvider>
    </>
  );
};

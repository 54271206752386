import React from 'react';
import { Flex } from 'antd';

type BottomActionsContainerProps = {
  leftElement?: React.ReactNode;
};

function BottomActionsContainer({ children, leftElement }: React.PropsWithChildren<BottomActionsContainerProps>) {
  return (
    <Flex justify="center" align="center" style={{ position: 'relative', width: '100%' }}>
      {leftElement ? (
        <div
          style={{
            position: 'absolute',
            left: 0,
            top: '50%',
            transform: 'translateY(-50%)',
          }}
        >
          {leftElement}
        </div>
      ) : null}
      {children}
    </Flex>
  );
}

export default BottomActionsContainer;

import React, { useEffect } from 'react';
import { Typography, Tabs as TabsNavigation, Alert, Flex, message } from 'antd';
import requireAuth from '../components/hocs/requireAuth';
import AdminTemplate from '../layout/AdminTemplate';
import { Navigate, Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import { ManualQueueTab } from '../features/scheduled-rendering/ManualQueueTab';
import { AutomaticQueueTab } from '../features/scheduled-rendering/AutomaticQueueTab';
import { useDispatch } from 'react-redux';
import { usePrerenderUser } from '../hooks/usePrerenderUser';
import { HubSpot } from '../features/CustomerSupport';
import { useTogglePauseRecachingMutation } from '../features/scheduled-rendering/RecacheQueueApiSlice';
import { prerenderUserPatch } from '../actions/prerenderUserActions';

const META_TAGS = {
  title: 'Scheduled Rendering - Prerender.io',
};

export const enum Tabs {
  ManualQueue = 'priority',
  AutomatedQueue = 'standard',
}

const TABS = [
  { key: Tabs.ManualQueue, label: 'Manual, API Queue', component: <ManualQueueTab /> },
  { key: Tabs.AutomatedQueue, label: 'Automated Queue', component: <AutomaticQueueTab /> },
];

const CanceledAccountMessage = () => {
  return (
    <Alert
      closable
      type="warning"
      message={
        <>
          <Typography.Text>
            Your account is currently canceled, and recaching is disabled. If you have any questions, please reach out
            to us{' '}
          </Typography.Text>
          <Typography.Link
            href="#/"
            onClick={(e) => {
              e.preventDefault();
              HubSpot.openHubSpotForm();
            }}
          >
            here
          </Typography.Link>
        </>
      }
    />
  );
};

const RecachingPausedMessage = ({ onResumeCaching }: { onResumeCaching: () => void }) => {
  return (
    <Alert
      closable
      type="warning"
      message={
        <>
          <Typography.Text>Recaching is currently paused. </Typography.Text>
          <Typography.Link onClick={onResumeCaching}>Resume Caching</Typography.Link>
        </>
      }
    />
  );
};

const NewScheduledRenderingPage = () => {
  const [messageApi, messageContext] = message.useMessage();
  const navigate = useNavigate();
  const location = useLocation();
  const prerenderUser = usePrerenderUser();
  const dispatch = useDispatch();
  const [togglePauseRecaching, { error: toggleRecachingError }] = useTogglePauseRecachingMutation();

  const activeTab = React.useMemo(() => location.pathname.split('/').pop(), [location.pathname]);

  const onTabClick = (tabKey: string) => {
    navigate(tabKey);
  };

  useEffect(() => {
    if (toggleRecachingError) {
      messageApi.error('Failed to resume caching. Please try again later.');
    }
  }, [toggleRecachingError]);

  return (
    <AdminTemplate metaTags={META_TAGS}>
      {messageContext}
      <div>
        <Flex vertical gap="large">
          {!prerenderUser.inProgress && prerenderUser.userPausedRecaching && (
            <RecachingPausedMessage
              onResumeCaching={() => {
                togglePauseRecaching(prerenderUser)
                  .unwrap()
                  .then(() => {
                    dispatch(prerenderUserPatch({ userPausedRecaching: !prerenderUser.userPausedRecaching }));
                  });
              }}
            />
          )}
          {!prerenderUser.inProgress && prerenderUser.recachingDisabled && <CanceledAccountMessage />}
          <Typography.Title level={2}>Scheduled Rendering</Typography.Title>
        </Flex>
        <Typography.Paragraph type="secondary">
          URLs prompted for caching and re-caching, either manually or automatically.
        </Typography.Paragraph>
      </div>
      <TabsNavigation activeKey={activeTab} items={TABS} onTabClick={onTabClick} />

      <Routes>
        {TABS.map(({ key, component }) => (
          <Route path={key} key={key} element={component} />
        ))}
        <Route path="*" element={<Navigate to={TABS[0].key} />}></Route>
      </Routes>
    </AdminTemplate>
  );
};

export default requireAuth(NewScheduledRenderingPage);

// imported by /reducers/index.js
// holds road search parameters and result

import type { PrerenderUser, PrerenderUserResponse } from '../features/PrerenderUser';
import { defaultUser, PrerenderUserAction as ActionType } from '../features/PrerenderUser';
import { stringToDate } from '../assets/dateFormatter';

type UserSetAction = { type: ActionType.UserSet; payload: PrerenderUserResponse };
type UserTokenSetAction = { type: ActionType.UserTokenSet; payload: string };
type UserLoadingSetAction = { type: ActionType.UserLoadingSet; payload: { value: boolean } };
type UserAnauthorizedAction = { type: ActionType.UserAnauthorized };
type UserTrackingCodeSetAction = { type: ActionType.UserTrackingCodeSet; payload: boolean };
type UserShowOnboardingSetAction = { type: ActionType.UserShowOnboardingSet; payload: boolean };
type UserGettingStartedStepsSetAction = {
  type: ActionType.UserGettingStartedStepsSet;
  payload: PrerenderUser['gettingStartedSteps'];
};
type UserResetAction = { type: ActionType.UserReset };
type UserUnreadNotificationsDecreaseAction = { type: ActionType.UserUnreadNotificationsDecrease; payload: number };
type UserPatchAction = { type: ActionType.UserPatch; payload: Partial<PrerenderUser> };

type ReducerAction =
  | UserSetAction
  | UserTokenSetAction
  | UserLoadingSetAction
  | UserAnauthorizedAction
  | UserTrackingCodeSetAction
  | UserShowOnboardingSetAction
  | UserGettingStartedStepsSetAction
  | UserResetAction
  | UserUnreadNotificationsDecreaseAction
  | UserPatchAction;

export default function prerenderUserReducer(state: PrerenderUser = defaultUser, action: ReducerAction): PrerenderUser {
  switch (action.type) {
    case ActionType.UserSet: {
      const {
        id,
        email,
        numPagesCached,
        token,
        signupDate,
        trackingCodeInstalled,
        cacheFreshness,
        hasBillingInfo,
        recachingDisabled,
        planName,
        costPerMonth,
        emailVerified,
        customPrice,
        customCachedPagesLimit,
        recacheDelay,
        userPausedRecaching,
        queryParamsWhitelist,
        ignoreAllQueryParams,
        hasBillingAddress,
        firstName,
        lastName,
        isMeteredBilling,
        plan,
        renderCounter,
        cancelledAt,
        hasCustomContractPricing,
        mobileAdaptive,
        settings,
        features,
        allURLCount,
        companyId,
        companyName,
        adminEmail,
        isTrial,
        isCacheFreshnessDisabled,
        nextBillingAt,
        lastCharged,
        customRecacheDelay,
        chargeBeePlanId,
        showRegistrationSteps,
        trackingCodeInstalledDate,
        isAdmin,
        billingPeriod,
        hadPaidPlan,
        csmId,
        role,
        gettingStartedSteps,
        numberOfNewNotifications,
        hasBillingManager,
        canStartTrial,
        trialEndsAt,
        amountOf429Renders,
      } = action.payload;

      return {
        ...state,
        id,
        email,
        numPagesCached,
        token,
        signupDate: new Date(signupDate),
        trackingCodeInstalled,
        cacheFreshness,
        hasBillingInfo,
        recachingDisabled,
        planName,
        costPerMonth,
        emailVerified,
        customPrice,
        hasCustomContractPricing,
        customCachedPagesLimit,
        recacheDelay,
        userPausedRecaching,
        queryParamsWhitelist,
        ignoreAllQueryParams,
        hasBillingAddress,
        firstName,
        lastName,
        isMeteredBilling,
        plan,
        renderCounter,
        cancelledAt,
        mobileAdaptive,
        settings,
        features,
        allURLCount,
        companyId,
        companyName,
        adminEmail,
        isTrial,
        isCacheFreshnessDisabled,
        customRecacheDelay,
        chargebeePlanId: chargeBeePlanId,
        inProgress: false,
        hasSession: true,
        isCancelled: !!cancelledAt && recachingDisabled,
        nextBillingAt: new Date(nextBillingAt),
        lastCharged: new Date(lastCharged),
        upgradedFromChargebeePlanId: action.payload.upgradedFromChargebeePlanId,
        upgradedAt: stringToDate(action.payload.upgradedAt),
        showRegistrationSteps,
        trackingCodeInstalledDate: stringToDate(trackingCodeInstalledDate),
        isAdmin,
        billingPeriod,
        hadPaidPlan,
        csmId,
        role,
        gettingStartedSteps,
        hasBillingManager,
        unreadNotifications:
          typeof numberOfNewNotifications === 'number' ? numberOfNewNotifications : state.unreadNotifications,
        canStartTrial,
        trialEndsAt,
        amountOf429Renders,
      };
    }

    case ActionType.UserTokenSet:
      return { ...state, token: action.payload };

    case ActionType.UserLoadingSet:
      return { ...state, inProgress: action.payload.value };

    case ActionType.UserAnauthorized:
      return { ...state, hasSession: false };

    case ActionType.UserTrackingCodeSet:
      return { ...state, trackingCodeInstalled: action.payload };

    case ActionType.UserShowOnboardingSet:
      return { ...state, showOnboarding: action.payload };

    case ActionType.UserGettingStartedStepsSet:
      return {
        ...state,
        gettingStartedSteps: {
          ...state.gettingStartedSteps,
          ...action.payload,
        },
      };
    case ActionType.UserReset:
      return defaultUser;

    case ActionType.UserUnreadNotificationsDecrease:
      if (state.unreadNotifications - action.payload > -1) {
        return { ...state, unreadNotifications: state.unreadNotifications - action.payload };
      }
      return { ...state, unreadNotifications: 0 };

    case ActionType.UserPatch:
      return {
        ...state,
        ...action.payload,
      };
    default:
  }
  return state;
}

import { ConfigProvider as ConfigProvider5, App as AntdApp } from 'antd';
import 'babel-polyfill';
import React, { Suspense } from 'react';
import { createRoot } from 'react-dom/client';
import { HelmetProvider } from 'react-helmet-async';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';

import './assets/vendor/font-awesome/css/font-awesome.min.css';
import FullPageLoading from './components/utils/FullPageLoading';
import { initKeycloak } from './features/auth/keycloakAuth';
import ApiMocksClient from './apiMocks';
import store from './store';
import { themeConfig } from './theme';
import { initChartLibrary } from './assets/charts';

import App from './App';

initChartLibrary();
initKeycloak(store.dispatch);

const MOCK_ENABLED = process.env.REACT_APP_API_MOCKS === 'true';

// Will make this toggleable later
ApiMocksClient(MOCK_ENABLED).then(() => {
  const container = document.querySelector('#root');
  if (!!container) {
    const root = createRoot(container);
    root.render(
      <Suspense fallback={<FullPageLoading />}>
        <ConfigProvider5 prefixCls="ant5" theme={themeConfig}>
          <AntdApp>
            <Provider store={store}>
              <HelmetProvider>
                <BrowserRouter>
                  <App />
                </BrowserRouter>
              </HelmetProvider>
            </Provider>
          </AntdApp>
        </ConfigProvider5>
      </Suspense>
    );
  } else {
    console.error('React root container not found');
  }
});

import React from 'react';

import { TOOLTIP_ROOT_ID } from '../../components/Tooltip';

type Source = 'recache_api' | 'site' | 'cdn' | 'sitemap' | 'recache' | 'add_url_api';

export const SourceLabels = {
  site: {
    label: 'Site',
    tooltip: 'Added through our dashboard or website',
  },
  recache_api: {
    label: 'Recache API',
    tooltip: 'Added via our API for recaching',
  },
  cdn: {
    label: 'CDN',
    tooltip: 'Processed by service.prerender.io CDN',
  },
  sitemap: {
    label: 'Sitemap',
    tooltip: 'Sourced from a sitemap service',
  },
  recache: {
    label: 'Recache',
    tooltip: 'Auto-recache for removed URLs',
  },
  add_url_api: {
    label: 'API',
    tooltip: 'Added via our API',
  },
} as const;

export function SourceTableCell({ sourceName }: { sourceName: Source }) {
  const source = SourceLabels[sourceName];

  if (!source) return <span>---</span>;

  return (
    <div data-tooltip-id={TOOLTIP_ROOT_ID} data-tooltip-content={source.tooltip} data-tooltip-place="top">
      {source.label}
    </div>
  );
}

export default Source;

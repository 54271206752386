import { formatCentsToDollar } from '../../../assets/currencies';
import ConfirmationModal from '../../../components/ConfirmationModal';
import { openModal } from '../../../actions/pageActions';
import { useDispatch } from 'react-redux';
import './billing-dues.css';
import { useEvent } from '../../events/hooks/useEvent';
import { usePrerenderUser } from '../../../hooks/usePrerenderUser';
import {
  useGetSubscriptionQuery,
  useLazyGetCollectNowLinkQuery,
  useReactivateSubscriptionMutation,
} from '../api/billingApiLegacySlice';
import { HubSpot } from '../../CustomerSupport';

const BillingDues = () => {
  const dispatch = useDispatch();

  const user = usePrerenderUser();
  const { data: subscription } = useGetSubscriptionQuery();
  const [getCollectNowLink, { isLoading: isLoadingCollectLink, error: collectLinkError }] =
    useLazyGetCollectNowLinkQuery();
  const [reactivateSubscription, { isLoading: reactivateInProgress, error: reactivateError }] =
    useReactivateSubscriptionMutation();

  const { track } = useEvent();

  if (!subscription) {
    return null;
  }

  const { totalDues } = subscription.result;

  const onPayNow = () => {
    getCollectNowLink().then((response) => {
      window.open(response.data.result.collectNowLink, '_blank', 'noreferrer');
    });
    track('Due Invoice Payment Started', { subscription_plan: user.planName, overdue_amount: totalDues });
  };

  const onReactivate = () => {
    const reactivateDetails =
      `Your account will be reactivated with the ${user.planName} plan. ` +
      'You will be charged the prorated amount now, plus past unpaid invoices and overages, if any. ' +
      'At the end of the billing period, you will be charged the total monthly fee plus overages, if any, ' +
      'until you change or cancel your plan.';
    track('Account Reactivation Started ', { subscription_plan: user.planName, overdue_amount: totalDues });

    dispatch(
      openModal(
        'planChangeModal',
        <ConfirmationModal
          title="Reactivate"
          text={reactivateDetails}
          onAction={(event) => {
            if (event.action === 'okay') {
              reactivateSubscription();
              track('Account Reactivated', { subscription_plan: user.planName, overdue_amount: totalDues });
            }
          }}
        />
      )
    );
  };

  const isUserCancelled = user.isCancelled;

  if (!isUserCancelled && totalDues === 0) {
    return null;
  }

  if (reactivateError) {
    return (
      <div className={'card p-4 billing-dues-card-danger'}>
        <div className="billing-dues-text-danger">Cannot reactivate account: {reactivateError.data.error.message}</div>
      </div>
    );
  }

  if (collectLinkError) {
    return (
      <div className={'card p-4 billing-dues-card-danger'}>
        <div className="billing-dues-text-danger">
          Cannot get collect due link: {collectLinkError.data.error.message}
        </div>
      </div>
    );
  }
  return (
    <div className={`card p-4 ${isUserCancelled ? 'billing-dues-card-danger' : 'billing-dues-card-warn'}`}>
      <div className="row">
        <div className="col">
          <h4
            className={`font-weight-base mb-1 d-flex ${
              isUserCancelled ? 'billing-dues-text-danger' : 'billing-dues-text-warn'
            }`}
          >
            <span>Unpaid Invoices</span>
            <span className="ml-2"> {`(${formatCentsToDollar(totalDues)})`}</span>
          </h4>
          {isUserCancelled ? (
            <small className="billing-dues-text-danger">Your account has been cancelled.</small>
          ) : (
            <small className="billing-dues-text-warn">
              There are unpaid invoices on your account. Please settle outstanding invoices to maintain your service.
              Questions? Click on 'Help' button, bottom left.
            </small>
          )}
        </div>
        <div className="col-auto">
          {subscription.hasHierarchies ? (
            <b>
              <span>Please reach out to us </span>
              <a
                href="#/"
                className="link-success"
                onClick={(e) => {
                  e.preventDefault();
                  HubSpot.openHubSpotForm();
                }}
              >
                here
              </a>
              <span>.</span>
            </b>
          ) : isUserCancelled ? (
            <button
              className="btn btn-sm billing-dues-button-danger"
              disabled={!isUserCancelled || reactivateInProgress}
              onClick={onReactivate}
            >
              {reactivateInProgress ? 'Reactivating...' : 'Reactivate'}
            </button>
          ) : (
            <button
              className="btn btn-sm billing-dues-button-warn"
              disabled={totalDues === 0 || isLoadingCollectLink === true}
              onClick={onPayNow}
            >
              {isLoadingCollectLink ? 'Redirecting...' : 'Pay now'}
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default BillingDues;

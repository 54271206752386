import React from 'react';
import { Select } from 'antd';
import { useNavigate } from 'react-router-dom';

import { NotificationCategory } from './Notification';

const CATEGORY_LABELS = {
  [NotificationCategory.Product]: 'Product Updated',
  [NotificationCategory.Billing]: 'Billing Updates',
  [NotificationCategory.Reports]: 'Reports',
};

function NotificationCategoryFilter({ selectedCategory = 'all' }) {
  const navigate = useNavigate();

  const categoryFilters = React.useMemo(() => {
    const categories = Object.values(NotificationCategory).map((category) => ({
      label: CATEGORY_LABELS[category],
      value: category,
    }));

    return [{ label: 'All Updates', value: 'all' }, ...categories];
  }, []);

  const onFilterChange = (value) => {
    if (value === 'all') navigate('');
    else navigate(`?category=${value}`);
  };

  return (
    <Select
      defaultValue={selectedCategory}
      options={categoryFilters}
      onChange={onFilterChange}
      style={{
        width: 200,
      }}
    />
  );
}

export default NotificationCategoryFilter;

import React, { useEffect, useState } from 'react';
import { Flex, message, Typography } from 'antd';

import requireAuth from '../components/hocs/requireAuth';
import { useEvent } from '../features/events/hooks/useEvent';
import AdminTemplate from '../layout/AdminTemplate';
import { DomainSearchDropdown } from '../components/DomainSearchDropdown';
import RenderHistoryTable from '../features/render-history/NewRenderHistoryTable';
import { usePrerenderUser } from '../hooks/usePrerenderUser';
import NewExportRenderHistoryModal from '../features/render-history/NewExportRenderHistoryModal';
import useQueryStringPagination from '../utils/useQueryStringPagination';
import useQueryStringParameters from '../utils/useQueryStringParameters';
import {
  buildRenderHistoryQueryParameters,
  RenderHistoryQueryParameters,
} from '../features/render-history/QueryParameters';
import { useGetDomainsQuery } from '../features/domain-manager/api/domainManagerApiSlice';
import { useRecacheStatsQuery } from '../features/api/rendersApiSlice';
import RenderUsageChart from '../features/render-history/RenderUsageChart';
import type { PrevNextPaginationProps } from '../components/PrevNextPagination';

const { Title, Paragraph } = Typography;

const META_TAGS = {
  title: 'Render History - Prerender.io',
};

const DEFAULT_PAGE_SIZE = 50;

const URL_LIST_SEARCHED_EVENT = 'Render history URL List Searched';

interface DomainSearchDropdownProps {
  domains: string[];
  performDomainSearch: (domain: string) => void;
  selectedDomain?: string;
}

const TypedDomainSearchDropdown = DomainSearchDropdown as React.FC<DomainSearchDropdownProps>;

const NewRenderHistoryPage = () => {
  const [messageApi, messageContext] = message.useMessage();
  const { track } = useEvent();
  const [visible, setVisible] = useState(false);
  const prerenderUser = usePrerenderUser();
  const { pagination, updatePagination } = useQueryStringPagination(DEFAULT_PAGE_SIZE);
  const { queryStrings, updateParameter, updateMultipleParameters, clearAllParameters } =
    useQueryStringParameters<RenderHistoryQueryParameters>(buildRenderHistoryQueryParameters);
  const domainsResult = useGetDomainsQuery('');
  const cachingActivityResult = useRecacheStatsQuery(
    { ...queryStrings, ...pagination },
    { refetchOnMountOrArgChange: true },
  );

  useEffect(() => {
    if (cachingActivityResult.isError) {
      messageApi.error('An error occurred while fetching render history, please try again later');
    }
    if (domainsResult.isError) {
      messageApi.error('An error occurred while fetching domains, please try again later');
    }
  }, [domainsResult.isError, cachingActivityResult.isError]);

  const paginationInfos: PrevNextPaginationProps = {
    page: pagination.page,
    pageSize: pagination.pageSize,
    onPrevClick: pagination.page > 1 ? () => updatePagination(pagination.page - 1) : undefined,
    onNextClick:
      cachingActivityResult.data?.length === pagination.pageSize
        ? () => updatePagination(pagination.page + 1)
        : undefined,
    currentPageAmount: cachingActivityResult.data?.length,
  };

  const handleSearchWithFilters = (filters: {
    sort: RenderHistoryQueryParameters['sort'];
    sortDirection: RenderHistoryQueryParameters['sortDirection'];
    filters: Partial<RenderHistoryQueryParameters>;
  }) => {
    updateMultipleParameters({
      sort: filters.sort,
      sortDirection: filters.sortDirection,
      ...filters.filters,
    });
  };

  const updateSearchQuery = (query: string) => {
    if (!query || query.length > 2) {
      updateParameter('q', query);
      track(URL_LIST_SEARCHED_EVENT, {
        search_string: query,
        subscription_plan: prerenderUser.chargebeePlanId,
      });
    }
  };

  const handleSearchConditionUpdate = (qCondition: string) => {
    updateParameter('qCondition', qCondition);
  };

  const handleSelectDomain = (domain: string) => {
    updateParameter('domain', domain);
  };

  const clearFilters = () => {
    clearAllParameters();
  };

  return (
    <AdminTemplate metaTags={META_TAGS}>
      {messageContext}
      <NewExportRenderHistoryModal visible={visible} setVisible={setVisible} params={queryStrings} />

      <Flex justify="space-between">
        <div>
          <Title level={2}>Render History</Title>
          <Paragraph type="secondary">Pages that have been recently rendered.</Paragraph>
        </div>
        {domainsResult.data?.domains.length > 1 && (
          <TypedDomainSearchDropdown
            domains={domainsResult.data?.domains}
            performDomainSearch={handleSelectDomain}
            selectedDomain={queryStrings.domain}
          />
        )}
      </Flex>

      <Flex gap="large" vertical>
        <RenderUsageChart domain={queryStrings.domain} />

        <RenderHistoryTable
          loading={cachingActivityResult.isFetching}
          dataSource={cachingActivityResult.data}
          onReloadData={cachingActivityResult.refetch}
          clearAllFilters={clearFilters}
          updateSearchQuery={updateSearchQuery}
          currentSearchQuery={queryStrings.q}
          currentQCondition={queryStrings.qCondition}
          onChangeTable={handleSearchWithFilters}
          onSearchConditionUpdate={handleSearchConditionUpdate}
          setExportModalVisible={setVisible}
          pagination={paginationInfos}
          filteredInfo={{
            adaptive_type: queryStrings.adaptive_type || [],
            time:
              queryStrings.renderedTimeLow !== undefined && queryStrings.renderedTimeHigh !== undefined
                ? [
                    {
                      renderedTimeLow: queryStrings.renderedTimeLow,
                      renderedTimeHigh: queryStrings.renderedTimeHigh,
                    },
                  ]
                : [],
            status_code:
              queryStrings.statusCodeEq || queryStrings.statusCodeLow || queryStrings.statusCodeHigh
                ? [
                    {
                      statusCodeEq: queryStrings.statusCodeEq,
                      statusCodeLow: queryStrings.statusCodeLow,
                      statusCodeHigh: queryStrings.statusCodeHigh,
                    },
                  ]
                : [],
            render_time:
              queryStrings.timedout || queryStrings.responseTimeHigh || queryStrings.responseTimeLow
                ? [
                    {
                      responseTimeLow: queryStrings.responseTimeLow,
                      responseTimeHigh: queryStrings.responseTimeHigh,
                      timedout: queryStrings.timedout,
                    },
                  ]
                : [],
            source: queryStrings.source || [],
          }}
        />
      </Flex>
    </AdminTemplate>
  );
};

export default requireAuth(NewRenderHistoryPage);

import dayjs from 'dayjs';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import { Alert, App, Button, Flex, Modal, Spin, Statistic, Typography } from 'antd';
import PlanUpgradeWhiteIcon from '../../../assets/icons/plan-upgrade-white-icon.svg';
import { formatCentsToDollarLocale, formatToDollar } from '../../../assets/currencies';
import { useEvent } from '../../events/hooks/useEvent';
import { experimentalClient } from '../../../library/experimentalClient';
import ButtonWithRole from '../../../components/ButtonWithRole';
import USER_ROLES from '../../auth/userRoles';
import { useCharbeePlanId } from '../../../hooks/usePrerenderUser';
import { CounterBar } from '../components/CounterBar';
import { useStartTrialMutation } from '../api/billingApiSlice';
import { AddonBillingType, isFree } from '@prerender/billing-shared';
import { getPrerenderUser } from '../../../actions/prerenderUserActions';
import { useDispatch } from 'react-redux';
import { CheckOutlined } from '@ant-design/icons';
import { useGetPackagesQuery } from '../api/packageApiSlice';
import { createUsNumberFormat } from '../../../utils/createUsNumberFormat';
import { formatCacheRefreshInterval } from '../../../assets/dateFormatter';

const getTrialDays = (trialEndsAt) => {
  const today = dayjs();
  const trialEnds = dayjs(trialEndsAt);

  const diffInHours = trialEnds.diff(today, 'hour');
  const days = Math.floor(diffInHours / 24);
  const hours = diffInHours % 24;

  return (
    <Statistic title="Trial period ends in" value={`${days} days, ${hours} hours`} style={{ textAlign: 'center' }} />
  );
};

function getHalfwayToTrialEnd(trialEndsAt) {
  const end = dayjs(trialEndsAt);
  const start = dayjs();
  const halfway = start.add(end.diff(start) / 2);
  return start.isAfter(halfway);
}

const RenderCounterText = ({ isTrial, isFree, recommendedPlan, halfwayToTrialEnd, isOverLimit, is429OverLimit }) => {
  if (isFree) {
    return (
      <Alert
        showIcon
        type="warning"
        message={'Any renders over your limit will result in 429 errors. Upgrade your plan to keep rendering smoothly.'}
      />
    );
  } else if (isTrial && halfwayToTrialEnd && !isOverLimit) {
    return (
      <Alert
        showIcon
        type="warning"
        message={'Keep the momentum going! Upgrade before your trial ends to avoid disrupting progress.'}
      />
    );
  } else if ((is429OverLimit && isTrial) || (isOverLimit && !isFree)) {
    return (
      <Alert
        showIcon
        type="warning"
        message={
          <>
            <Typography.Text>
              Heads up - you're over your render limit! Upgrade your plan to maintain your momentum.{' '}
            </Typography.Text>
            <Typography.Text>
              Recommended plan based on your usage: <Link to="/billing/plans">{recommendedPlan}</Link>
            </Typography.Text>
          </>
        }
      />
    );
  }
  return <></>;
};

export const BillingOverviewPlan = ({
  user,
  isUserOnCustomPlan,
  showBillingPageLink = false,
  showAccountDetails = false,
}) => {
  const dispatch = useDispatch();
  const isLegacyUser = !user.isMeteredBilling;
  const currentCount = isLegacyUser ? user.numPagesCached : user.renderCounter;
  const { message } = App.useApp();
  const chargebeePlanId = useCharbeePlanId();
  const [startTrial] = useStartTrialMutation();
  const [selectedCustomDeal, setSelectedCustomDeal] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const { track } = useEvent();
  const halfwayToTrialEnd = getHalfwayToTrialEnd(user.trialEndsAt);
  const isFreePlan = isFree(chargebeePlanId);
  const { data: packagesData, isFetching: packagesLoading, error: packagesError } = useGetPackagesQuery();

  const activePackages = useMemo(
    () => packagesData?.data.filter((p) => p.isActive && p.billingType === AddonBillingType.Subscription),
    [packagesData],
  );

  const onUpgradeLinkClick = useCallback(() => {
    track('Billing Plans Page Viewed', { subscription_plan: user.planName, location: 'Upgrade Button' });
  }, [user]);

  const onFreeTrialClick = () => {
    startTrial()
      .unwrap()
      .then(() => {
        message.success({ content: 'Free Trial activated' });
        dispatch(getPrerenderUser());
        track('Free Trial Started', {});
      })
      .catch(() => {
        message.error({ content: 'Failed to activate Free Trial' });
      });
    setShowModal(false);
  };

  useEffect(() => {
    if (isUserOnCustomPlan) {
      experimentalClient(user.token, '/deals').then((response) => {
        const sorterd = response.data.sort((a, b) => (a.beginsAt > b.beginsAt ? -1 : 1));

        if (response.data.length) {
          setSelectedCustomDeal(sorterd[0]);
        }
      });
    }
  }, [isUserOnCustomPlan]);

  const customDealBeginsAt = selectedCustomDeal?.beginsAt
    ? dayjs(selectedCustomDeal?.beginsAt).format('YYYY-MM-DD')
    : '-';

  const customRenewalDateAt = selectedCustomDeal?.renewalAt
    ? dayjs(selectedCustomDeal?.renewalAt).format('YYYY-MM-DD')
    : '-';

  return (
    <>
      <Flex justify="space-between" align="center">
        <Typography.Title level={4}>{`${user.planName} Plan`}</Typography.Title>
        {!user.isTrial && (
          <Typography.Text style={{ marginBottom: 10 }}>
            {typeof user.customPrice === 'number'
              ? formatToDollar(user.customPrice)
              : formatToDollar(user.costPerMonth)}
          </Typography.Text>
        )}
      </Flex>
      {showBillingPageLink && (
        <Typography.Text>
          You can read more about your current pricing plan <Link to="/billing/overview">here</Link>
        </Typography.Text>
      )}
      <div>
        {isUserOnCustomPlan ? (
          <>
            <div className="d-flex align-items-center justify-content-between">
              <h4>Render Counter</h4>
              {currentCount.toLocaleString()}
            </div>
            <div className="d-flex align-items-center justify-content-between">
              <h4>Renders Included</h4>
              {selectedCustomDeal?.renders || '-'}
            </div>
            <div className="d-flex align-items-center justify-content-between">
              <h4>Renewal date</h4>
              {customRenewalDateAt}
            </div>
          </>
        ) : (
          <CounterBar
            isLegacyUser={isLegacyUser}
            count={currentCount}
            limit={user.plan.limit}
            amountOf429Renders={user.amountOf429Renders}
          />
        )}
      </div>
      <div style={{ marginTop: 12, marginBottom: 8 }}>
        {isUserOnCustomPlan ? (
          <>
            <p className="mt-3">
              <span className="text-grey">
                Your plan and billing details are determined by a custom agreement with Prerender, created on{' '}
                {customDealBeginsAt}
              </span>{' '}
            </p>
            <p>
              <Link to="/custom-deal" component={(props) => <Button {...props} type="link" htmlType="span" />}>
                See your deal details here
              </Link>
            </p>
            <p>
              <span>For more information, please contact </span>
              <a style={{ color: '#63B190' }} className="font-weight-bold" href="mailto:growth@prerender.io">
                growth@prerender.io
              </a>
              <span className="text-grey">.</span>
            </p>
          </>
        ) : (
          <RenderCounterText
            isTrial={user.isTrial}
            isOverLimit={currentCount >= user.plan.limit}
            isFree={isFreePlan}
            halfwayToTrialEnd={halfwayToTrialEnd}
            recommendedPlan={user.plan.suggested?.name}
            is429OverLimit={user.amountOf429Renders > 0}
          />
        )}
      </div>

      {showAccountDetails && (
        <>
          <ul className="mt-3 pl-4">
            <li>
              {isLegacyUser ? <span>No. of cached pages: </span> : <span>No. of renders: </span>}

              <span className="font-weight-bold">{createUsNumberFormat(user?.plan.limit)}</span>
            </li>
            {user.plan.current && user.plan.current.addonCostInCents ? (
              <li>
                <span>Cost per 1000 extra renders: </span>
                <span className="font-weight-bold">
                  {formatCentsToDollarLocale(user.plan.current.addonCostInCents)}
                </span>
              </li>
            ) : (
              <></>
            )}
            {user.plan.cacheRefreshInterval && (
              <li>
                <span>Cache freshness interval: </span>
                <span className="font-weight-bold">{formatCacheRefreshInterval(user.plan.cacheRefreshInterval)}</span>
              </li>
            )}
          </ul>
          <h4 className="font-weight-bold">Packages</h4>
          {packagesLoading && (
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <Spin />
            </div>
          )}
          {!packagesLoading && packagesError && (
            <div className="h-100 w-100 d-flex align-items-center justify-content-center">
              <p style={{ color: 'red' }}>An unexpected error occured, please try again by reloading the page.</p>
            </div>
          )}
          {!packagesLoading &&
            !packagesError &&
            (activePackages.length ? (
              <ul className="mt-3 pl-4">
                {activePackages.map((p) => {
                  return (
                    <li key={p.name}>
                      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <span>{p.name}</span>
                        <span>{formatToDollar(p.costInCents / 100)}</span>
                      </div>
                    </li>
                  );
                })}
              </ul>
            ) : (
              <p>You don't have active packages</p>
            ))}
        </>
      )}

      {user.isTrial && (
        <Flex style={{ borderTop: '1px solid #e8fae5', paddingTop: 16 }} justify="center">
          {getTrialDays(user.trialEndsAt)}
        </Flex>
      )}

      <Flex justify="space-between" align="center" style={{ marginTop: 16, width: '100%' }} gap={12}>
        {!isUserOnCustomPlan ? (
          <Link
            to="/billing/plans"
            onClick={onUpgradeLinkClick}
            style={user.isTrial || !user.canStartTrial ? { width: '100%' } : {}}
          >
            <ButtonWithRole
              disabledFor={[USER_ROLES.TEAM_MEMBER, USER_ROLES.GUEST]}
              type="primary"
              style={user.isTrial || !user.canStartTrial ? { width: '100%' } : {}}
            >
              <img src={PlanUpgradeWhiteIcon} />
              <span className="ml-3">{halfwayToTrialEnd ? 'Upgrade Now' : 'Upgrade Plan'}</span>
            </ButtonWithRole>
          </Link>
        ) : null}
        {user.canStartTrial && (
          <ButtonWithRole disabledFor={[USER_ROLES.GUEST]} type="primary" onClick={() => setShowModal(true)}>
            Get Free Trial
          </ButtonWithRole>
        )}
      </Flex>
      <Modal
        title="Start Free Trial"
        open={showModal}
        onOk={onFreeTrialClick}
        onCancel={() => setShowModal(false)}
        okText="Start Free Trial"
      >
        <Flex vertical>
          <Typography.Text style={{ marginBottom: 8 }}>For the next month, you can enjoy:</Typography.Text>
          <Typography.Text>
            <CheckOutlined style={{ marginRight: 4, color: '#2da01d', fontSize: 16 }} />
            all the benefits of an Essential plan which means 50,000 more renders
          </Typography.Text>
          <Typography.Text>
            <CheckOutlined style={{ marginRight: 4, color: '#2da01d', fontSize: 16 }} />
            API access
          </Typography.Text>
        </Flex>
      </Modal>
    </>
  );
};

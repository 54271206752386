// page reducer:
export const SET_LASTERRORCODE = 'set_lasterrorcode';
export const CLEAR_LASTERRORCODE = 'clear_lasterrorcode';
export const OPEN_MODAL = 'open_modal';
export const CLOSE_MODAL = 'close_modal';
export const SET_SSR_ENTRY_URL = 'set_ssr_entry_url';
export const ERASE_PAGE_REDUCER = 'erase_page_reducer';
export const CONTENT_LOADING_IN_PROGRESS = 'content_loading_in_progress';
export const CONTENT_SET = 'content_set';
export const SET_MODAL_HEIGHT = 'set_modal_height';
export const UPDATE_PAGE_CONTENT = 'update_page_content';
// Invitation
export const REVOKE_INVITATION_SUCCEEDED = 'revoke_invitation_succeeded';
export const REVOKE_INVITATION_FAILED = 'revoke_invitation_failed';
export const REVOKE_INVITATION_IN_PROGRESS = 'revoke_invitation_in_progress';

// CachedPages reducer:
export const CACHEDPAGES_SET = 'cachedpages_set';
export const CACHEDPAGES_IN_PROGRESS = 'cachedpages_in_progress';
export const CACHEDPAGES_RESET = 'cachedpages_reset';
export const CACHEDPAGES_SET_STATUS = 'cachedpages_set_status';
// CrawlerActivity reducer:
export const CRAWLERACTIVITY_SET = 'CRAWLERACTIVITY_set';
export const CRAWLERACTIVITY_SET_PARAMS = 'CRAWLERACTIVITY_set_params';
export const CRAWLERACTIVITY_IN_PROGRESS = 'CRAWLERACTIVITY_in_progress';
export const CRAWLERACTIVITY_RESET = 'CRAWLERACTIVITY_reset';
// CrawlerStats reducer:
export const CRAWLERSTATS_OVERVIEW_SET = 'crawlerstats_overview_set';
export const CRAWLERSTATS_OVERVIEW_IN_PROGRESS = 'crawlerstats_overview_in_progress';
export const CRAWLERSTATS_OVERVIEW_RESET = 'crawlerstats_overview_reset';
export const CRAWLERSTATS_OVERVIEW_SET_PARAMS = 'crawlerstats_overview_set_params';

export const CRAWLERSTATS_RESPONSE_TIMES_SET = 'crawlerstats_response_times_set';
export const CRAWLERSTATS_RESPONSE_TIMES_IN_PROGRESS = 'crawlerstats_response_times_in_progress';
export const CRAWLERSTATS_RESPONSE_TIMES_RESET = 'crawlerstats_response_times_reset';
export const CRAWLERSTATS_RESPONSE_TIMES_SET_PARAMS = 'crawlerstats_response_times_set_params';
// RecacheQueue reducer:
export const RECACHEQUEUE_SET = 'recachequeue_set';
export const RECACHEQUEUE_IN_PROGRESS = 'recachequeue_in_progress';
export const RECACHEQUEUE_RESET = 'recachequeue_reset';
// CachingActivity reducer:
export const CACHINGACTIVITY_SET = 'cachingactivity_set';
export const CACHINGACTIVITY_IN_PROGRESS = 'cachingactivity_in_progress';
export const CACHINGACTIVITY_RESET = 'cachingactivity_reset';
export const CACHINGACTIVITY_SET_PARAMS = 'cachingActivity_set_params';
// CachingStats reducer:
export const CACHINGSTATS_SET = 'cachingstats_set';
export const CACHINGSTATS_IN_PROGRESS = 'cachingstats_in_progress';
export const CACHINGSTATS_RESET = 'cachingstats_reset';
export const CACHINGSTATS_SET_PARAMS = 'cachingStats_set_params';
// IgnoredUrls reducer:
export const IGNORED_URLS_SET = 'ignored_urls_set';
export const IGNORED_URLS_IN_PROGRESS = 'ignored_urls_in_progress';
export const IGNORED_URLS_RESET = 'ignored_urls_reset';
// Email verification reducer:
export const EMAIL_VERIFICATION_SENT = 'email_verification_sent';
export const EMAIL_VERIFICATION_DONE = 'email_verification_done';
export const EMAIL_VERIFICATION_ERROR = 'email_verification_error';
export const EMAIL_VERIFICATION_RESENDING = 'email_verification_resending';
export const EMAIL_VERIFICATION_RESENT = 'email_verification_resent';
// BillingInfo reducer:
export const SET_SHOW_HAS_BILLING_ADDRESS_BADGE = 'set_show_billing_address_badge';
export const SET_SHOW_HAS_BILLING_INFO_BADGE = 'set_show_billing_info_badge';
// Keycloak reducer
export const KEYCLOAK_AUTH_SUCCESS = 'keycloak_auth_success';
export const KEYCLOAK_AUTH_ERROR = 'keycloak_auth_error';
export const KEYCLOAK_IN_PROGRESS = 'keycloak_in_progress';

// custom-recache-delay
export const CUSTOM_RECACHE_DELAY_SET = 'custom_recache_delay_set';
export const RENDERING_SPEED_SET = 'rendering_speed_set';

// Domains
export const GET_DOMAINS_IN_PROGRESS = 'get_domains_in_progress';
export const GET_DOMAINS_SUCCESS = 'get_domains_success';

import { http, HttpResponse } from 'msw';
import { faker } from '@faker-js/faker';

const crawlStats = http.get('http://localhost:8443/api/crawl-stats', () => {
  return HttpResponse.json([
    {
      cache_hit: 1,
      domain: 'help.salesforce.com',
      render_time: 171,
      status_code: 200,
      time: 1741004563000,
      url: 'https://help.salesforce.com/s/articleView?language=en_US&id=sf.blng_abs_guidelines.htm&release=244.5.0&type=5',
      user_agent:
        'Mozilla/5.0 (Linux; Android 6.0.1; Nexus 5X Build/MMB29P) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/133.0.6943.53 Mobile Safari/537.36 (compatible; Googlebot/2.1; +http://www.google.com/bot.html)',
      user_id: 123534,
      timedout: 0,
      adaptive_type: 'desktop',
    },
    {
      cache_hit: 1,
      domain: 'support.yahoo-net.jp',
      render_time: 37,
      status_code: 200,
      time: 1741004563000,
      url: 'https://support.yahoo-net.jp/PccAuctions/s/article/H000005386',
      user_agent: 'facebookexternalhit/1.1 (+http://www.facebook.com/externalhit_uatext.php)',
      user_id: 123534,
      timedout: 0,
      adaptive_type: 'desktop',
    },
    {
      cache_hit: 1,
      domain: 'portal.u-blox.com',
      render_time: 99,
      status_code: 200,
      time: 1741004563000,
      url: 'https://portal.u-blox.com/s/question/0D5Oj00000Rdtf8KAB/how-do-i-enable-the-ubxtimtp-on-neo-f10t-',
      user_agent:
        'Mozilla/5.0 (Linux; Android 6.0.1; Nexus 5X Build/MMB29P) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/133.0.6943.53 Mobile Safari/537.36 (compatible; Googlebot/2.1; +http://www.google.com/bot.html)',
      user_id: 123534,
      timedout: 0,
      adaptive_type: 'desktop',
    },
    {
      cache_hit: 1,
      domain: 'help.linebiz.com',
      render_time: 35,
      status_code: 200,
      time: 1741004562000,
      url: 'https://help.linebiz.com/lineadshelp/s/article/L000001068?language=ja',
      user_agent:
        'Mozilla/5.0 (Linux; Android 6.0.1; Nexus 5X Build/MMB29P) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/133.0.6943.53 Mobile Safari/537.36 (compatible; Googlebot/2.1; +http://www.google.com/bot.html)',
      user_id: 123534,
      timedout: 0,
      adaptive_type: 'desktop',
    },
    {
      cache_hit: 1,
      domain: 'community.tableau.com',
      render_time: 48,
      status_code: 200,
      time: 1741004562000,
      url: 'https://community.tableau.com/s/question/0D58b0000CFgGnSCQV/bonjour-jai-un-tableau-d%C3%A9j%C3%A0-existant-la-p%C3%A9riode-donn%C3%A9e-est-la-semaine-pr%C3%A9c%C3%A9dente-donc-%C3%A0-ce-jour-semaine-du-01042024-jaimerais-savoir-sil-%C3%A9tait-possible-de-modifier-cette-p%C3%A9riode-donn%C3%A9e-%C3%A0-une-autre-sem',
      user_agent:
        'Mozilla/5.0 (Linux; Android 6.0.1; Nexus 5X Build/MMB29P) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/133.0.6943.53 Mobile Safari/537.36 (compatible; Googlebot/2.1; +http://www.google.com/bot.html)',
      user_id: 123534,
      timedout: 0,
      adaptive_type: 'desktop',
    },
    {
      cache_hit: 0,
      domain: 'support.docusign.com',
      render_time: 29,
      status_code: 504,
      time: 1741004562000,
      url: 'https://support.docusign.com/s/contact/0034000001fxrqBAAQ/sarah-geary',
      user_agent: 'meta-externalagent/1.1 (+https://developers.facebook.com/docs/sharing/webmasters/crawler)',
      user_id: 123534,
      timedout: 0,
      adaptive_type: 'desktop',
    },
    {
      cache_hit: 1,
      domain: 'parts.thermoking.com',
      render_time: 41,
      status_code: 200,
      time: 1741004562000,
      url: 'https://parts.thermoking.com/store/s/product/door-curbside-slp-spectrum-sb-989095/01t4w00000NLW22AAH?language=fr_CA',
      user_agent: 'meta-externalagent/1.1 (+https://developers.facebook.com/docs/sharing/webmasters/crawler)',
      user_id: 123534,
      timedout: 0,
      adaptive_type: 'desktop',
    },
    ...Array.from({ length: 30 }, () => ({
      cache_hit: faker.number.int({ min: 0, max: 1 }),
      domain: faker.internet.domainName(),
      render_time: faker.number.int({ min: 29, max: 171 }),
      status_code: faker.helpers.arrayElement([200, 504]),
      time: faker.date.recent().getTime(),
      url: faker.internet.url(),
      user_agent: faker.internet.userAgent(),
      user_id: 123534,
      timedout: 0,
      adaptive_type: 'desktop',
    })),
  ]);
});

export default [crawlStats];

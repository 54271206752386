import React from 'react';
import { Layout, Flex, Button } from 'antd';
import { Link } from 'react-router-dom';
import { MenuFoldOutlined, MenuUnfoldOutlined } from '@ant-design/icons';

import MetaTags from '../components/MetaTags';
import NavigationTopbar from './TopBar';
import Sidebar from './SideBar';
import Logo from './Logo';
// import UsageLimitApproachAlert from '../features/usage-limit-approach-alert/UsageLimitApproachAlert';
import './AdminTemplate.css';
import { usePrerenderUserId } from '../hooks/usePrerenderUser';
import { UpgradePlanAlert } from '../features/alerts';

const LOCAL_STORAGE_KEY = 'sidebarCollapsed';

function loadCollapsedState(userId) {
  return localStorage.getItem(`${LOCAL_STORAGE_KEY}-${userId}`) === 'true' || false;
}

function saveCollapsedState(userId, value) {
  localStorage.setItem(`${LOCAL_STORAGE_KEY}-${userId}`, value);
}

const AdminTemplate = ({ metaTags, children }) => {
  const userId = usePrerenderUserId();
  const [collapsed, setCollapsed] = React.useState(() => loadCollapsedState(userId));

  const toggleCollapsed = () => {
    setCollapsed((prev) => {
      const newValue = !prev;
      saveCollapsedState(userId, newValue);
      return newValue;
    });
  };

  return (
    <>
      <MetaTags tags={metaTags} />
      <Layout>
        <Layout.Header
          style={{
            backgroundColor: 'white',
            borderBottom: '1px solid #f0f0f0',
            padding: '0 16px',
          }}
        >
          <Flex align="center" style={{ height: '100%' }}>
            <Flex align="center" gap="middle" flex={1}>
              <Button
                icon={collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
                onClick={toggleCollapsed}
                style={{ width: 50 }}
              />

              <Link to="/" style={{ display: 'flex' }}>
                <Logo />
              </Link>
              <NavigationTopbar />
            </Flex>
          </Flex>
        </Layout.Header>

        <Layout hasSider>
          <Layout.Sider
            trigger={null}
            width={220}
            collapsible
            collapsed={collapsed}
            theme="light"
            style={{
              overflow: 'auto',
              height: '100vh',
              position: 'sticky',
              top: 0,
              left: 0,
              bottom: 0,
              paddingBottom: 60,
              scrollbarWidth: 'thin',
            }}
          >
            <Sidebar isCollapsed={collapsed} />
          </Layout.Sider>
          <Layout.Content>
            <UpgradePlanAlert />
            <div style={{ margin: '16px' }}>{children}</div>
          </Layout.Content>
        </Layout>
      </Layout>
    </>
  );
};

export default AdminTemplate;

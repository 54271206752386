import React from 'react';
import { Button, Switch, Radio } from 'antd';

import { useUserRole } from '../hooks/usePrerenderUser';
import type { UserRole } from '../features/PrerenderUser';

export function useIsDisabledForRole(disabledFor?: UserRole | UserRole[]) {
  const userRole = useUserRole();

  if (!disabledFor) return false;

  if (Array.isArray(disabledFor)) {
    return disabledFor.includes(userRole);
  }

  return disabledFor === userRole;
}

type ButtonWithRoleProps = {
  disabledFor: UserRole | UserRole[];
} & React.ComponentProps<typeof Button>;

function ButtonWithRole({ disabledFor, children = null, ...props }: React.PropsWithChildren<ButtonWithRoleProps>) {
  const disabledForRole = useIsDisabledForRole(disabledFor);
  const disabled = disabledForRole || props.disabled;

  return (
    <Button {...props} disabled={disabled} onClick={disabled ? undefined : props.onClick}>
      {children}
    </Button>
  );
}

type SwitchWithRoleProps = {
  disabledFor: UserRole | UserRole[];
} & React.ComponentProps<typeof Switch>;

export function SwitchWithRole({ disabledFor, ...props }: SwitchWithRoleProps) {
  const disabledForRole = useIsDisabledForRole(disabledFor);
  const disabled = disabledForRole || props.disabled;
  return <Switch {...props} disabled={disabled} onClick={disabled ? undefined : props.onClick} />;
}

type RadioGroupWithRoleProps = {
  disabledFor: UserRole | UserRole[];
} & React.ComponentProps<typeof Radio.Group>;

export function RadioGroupWithRole({ disabledFor, ...props }: RadioGroupWithRoleProps) {
  const disabledForRole = useIsDisabledForRole(disabledFor);
  const disabled = disabledForRole || props.disabled;

  return <Radio.Group {...props} disabled={disabled} onChange={disabled ? undefined : props.onChange} />;
}

export default ButtonWithRole;

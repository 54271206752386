import { Typography, Space, Button } from 'antd';
import { WarningFilled } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import RenderedHtml from './RenderedHtml';

/**
 * @param {Object} props
 * @param {Object} props.notification
 * @param {String} props.notification.description
 * @param {String} props.notification.createdAt - ISO date string
 * @param {Object} props.notification.metadata
 * @param {String=} props.notification.metadata.htmlContent - HTML content
 */
function RenderLimit100({ notification }) {
  const { metadata = {} } = notification;

  return (
    <Space direction="vertical" size="large">
      <Typography.Text strong>{new Date(notification.createdAt).toLocaleDateString()}</Typography.Text>
      <Typography.Title type="danger" level={4}>
        <WarningFilled /> {notification.description}
      </Typography.Title>

      <RenderedHtml html={metadata.htmlContent} />

      <Link to="/billing/overview#render-usage">
        <Button type="primary">Check usage</Button>
      </Link>
    </Space>
  );
}

export default RenderLimit100;

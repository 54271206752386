import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

type Pagination = {
  page: number;
  pageSize: number;
};

function useQueryStringPagination(defaultPageSize = 30) {
  const { search } = useLocation();
  const navigate = useNavigate();

  const pagination: Pagination = React.useMemo(() => {
    const searchParams = new URLSearchParams(search);

    const savedPage = searchParams.get('page');
    const savedPageSize = searchParams.get('pageSize');

    return {
      page: savedPage ? parseInt(savedPage) : 1,
      pageSize: savedPageSize ? parseInt(savedPageSize) : defaultPageSize,
    };
  }, [search]);

  const updatePagination = (page = 1, pageSize = defaultPageSize) => {
    const searchParams = new URLSearchParams(search);
    searchParams.set('page', page.toString());
    searchParams.set('pageSize', pageSize.toString());
    navigate(`?${searchParams.toString()}`);
  };

  return {
    pagination,
    updatePagination,
  };
}

export default useQueryStringPagination;

import { http, HttpResponse } from 'msw';
import { faker } from '@faker-js/faker';

const getSitemapsList = http.get('http://localhost:3030/v3/sitemap', () => {
  return HttpResponse.json({
    data: [
      {
        id: 646448,
        url: 'https://riskonnectokgr.my.site.com/IncidentIntake/s/sitemap-intake_detail__c-new.xml',
        userId: 123534,
        domain: 'riskonnectokgr.my.site.com',
        type: 'user_uploaded',
        revisitInterval: 604800,
        createdAt: '2025-03-03T02:25:36.544Z',
        lastVisitedAt: '2025-03-03T02:25:36.541Z',
        isEnabled: true,
        isAvailable: true,
        lastCheckedAt: '2025-03-03T04:53:46.888Z',
        isHealthy: true,
        adaptiveType: 'desktop',
      },
      {
        id: 646447,
        url: 'https://riskonnectokgr.my.site.com/IncidentIntake/s/sitemap-intake__c-new.xml',
        userId: 123534,
        domain: 'riskonnectokgr.my.site.com',
        type: 'user_uploaded',
        revisitInterval: 604800,
        createdAt: '2025-03-03T02:25:36.428Z',
        lastVisitedAt: '2025-03-03T02:25:36.423Z',
        isEnabled: true,
        isAvailable: true,
        lastCheckedAt: '2025-03-03T04:53:37.021Z',
        isHealthy: true,
        adaptiveType: 'desktop',
      },
      {
        id: 646446,
        url: 'https://recruit4.my.site.com/sitemap-managedcontent-sfdc_cms__news-new.xml',
        userId: 123534,
        domain: 'recruit4.my.site.com',
        type: 'user_uploaded',
        revisitInterval: 604800,
        createdAt: '2025-03-03T00:30:01.492Z',
        lastVisitedAt: '2025-03-03T00:30:01.494Z',
        isEnabled: true,
        isAvailable: true,
        lastCheckedAt: '2025-03-03T03:53:42.914Z',
        isHealthy: true,
        adaptiveType: 'desktop',
      },
      {
        id: 646429,
        url: 'https://cciq.my.site.com/test/sitemap.xml',
        userId: 123534,
        domain: 'cciq.my.site.com',
        type: 'user_uploaded',
        revisitInterval: 604800,
        createdAt: '2025-03-02T03:00:04.626Z',
        lastVisitedAt: '2025-03-02T03:00:04.623Z',
        isEnabled: true,
        isAvailable: true,
        lastCheckedAt: '2025-03-03T00:42:59.137Z',
        isHealthy: true,
        adaptiveType: 'desktop',
      },
      {
        id: 646428,
        url: 'https://cciq.my.site.com/test/sitemap-view-1.xml',
        userId: 123534,
        domain: 'cciq.my.site.com',
        type: 'user_uploaded',
        revisitInterval: 604800,
        createdAt: '2025-03-02T03:00:04.325Z',
        lastVisitedAt: '2025-03-02T03:00:04.323Z',
        isEnabled: true,
        isAvailable: true,
        lastCheckedAt: '2025-03-03T00:43:01.058Z',
        isHealthy: true,
        adaptiveType: 'desktop',
      },
      {
        id: 646427,
        url: 'https://billtapin.my.site.com/RnEntryFormBU/s/sitemap.xml',
        userId: 123534,
        domain: 'billtapin.my.site.com',
        type: 'user_uploaded',
        revisitInterval: 604800,
        createdAt: '2025-03-02T02:59:19.814Z',
        lastVisitedAt: '2025-03-02T02:59:19.816Z',
        isEnabled: true,
        isAvailable: true,
        lastCheckedAt: '2025-03-03T00:37:57.789Z',
        isHealthy: true,
        adaptiveType: 'desktop',
      },
      {
        id: 646426,
        url: 'https://billtapin.my.site.com/RnEntryFormBU/s/sitemap-view-1.xml',
        userId: 123534,
        domain: 'billtapin.my.site.com',
        type: 'user_uploaded',
        revisitInterval: 604800,
        createdAt: '2025-03-02T02:59:19.584Z',
        lastVisitedAt: '2025-03-02T02:59:19.581Z',
        isEnabled: true,
        isAvailable: true,
        lastCheckedAt: '2025-03-03T00:37:47.807Z',
        isHealthy: true,
        adaptiveType: 'desktop',
      },
      ...Array.from({ length: 43 }, () => ({
        id: faker.number.int(),
        url: faker.internet.url(),
        userId: faker.number.int(),
        domain: faker.internet.domainName(),
        type: 'user_uploaded',
        revisitInterval: 604800,
        createdAt: faker.date.recent().toISOString(),
        lastVisitedAt: faker.date.recent().toISOString(),
        isEnabled: true,
        isAvailable: true,
        lastCheckedAt: faker.date.recent().toISOString(),
        isHealthy: true,
        adaptiveType: 'desktop',
      })),
    ],
    total: 100,
    pages: 2,
    itemsPerPage: 50,
  });
});

export default [getSitemapsList];

import { http, HttpResponse } from 'msw';
import { faker } from '@faker-js/faker';

const getCachedPages = http.get('http://localhost:3030/v3/analytics/crawled-pages', () => {
  return HttpResponse.json({
    data: [
      {
        url: 'https://plannit.io/merchants/quebec/drummondville/valencia-entretien-paysagiste/ixriyjkshcmn',
        adaptive_type: 'desktop',
        page_cached_at: faker.date.recent().getTime(),
        last_requested_at: faker.date.recent().getTime(),
        user_agent: faker.internet.userAgent(),
      },
      ...Array.from({ length: 30 }, () => ({
        url: faker.internet.url(),
        adaptive_type: faker.helpers.arrayElement(['desktop', 'mobile']),
        page_cached_at: faker.date.recent().getTime(),
        last_requested_at: faker.date.recent().getTime(),
        user_agent: faker.internet.userAgent(),
      })),
    ],
    totalCount: 31,
  });
});

export default [getCachedPages];

import { BillingPeriod, CacheLocation, ChargeBeePlanId, PlanName, Support } from '@prerender/billing-shared';

import Role from './Role';
import { FeatureFlag } from '../../hooks/useFeatureFlag';

import type { PrerenderUserResponse } from './PrerenderUserResponse';

export type PrerenderUser = Omit<
  PrerenderUserResponse,
  | 'isBetaTester'
  | 'chargeBeePlanId'
  | 'lastCharged'
  | 'nextBillingAt'
  | 'signupDate'
  | 'trackingCodeInstalledDate'
  | 'numberOfNewNotifications'
  | 'upgradedAt'
> & {
  chargebeePlanId: ChargeBeePlanId;
  lastCharged: Date;
  nextBillingAt: Date;
  signupDate: Date;
  trackingCodeInstalledDate: Date | null;
  upgradedAt: Date | null;
  hasSession: boolean;
  inProgress: boolean;
  isCancelled: boolean;
  showOnboarding: boolean;
  unreadNotifications: number;
};

export const defaultUser: PrerenderUser = {
  adminEmail: 'default-email@company.com',
  allURLCount: 0,
  amountOf429Renders: 0,
  billingPeriod: BillingPeriod.monthly,
  cacheFreshness: 14,
  cancelledAt: null,
  canStartTrial: false,
  chargebeePlanId: ChargeBeePlanId.Basic,
  companyId: 1,
  companyName: 'default-email@company.com',
  costPerMonth: 0,
  csmId: 'aaaa',
  customCachedPagesLimit: null,
  customPrice: null,
  customRecacheDelay: null,
  email: 'default-email@company.com',
  emailVerified: true,
  features: [
    FeatureFlag.Oversight,
    FeatureFlag.DomainsDetails,
    FeatureFlag.DomainSchedulerSettings,
    FeatureFlag.SeoReport,
  ],
  firstName: 'Firstname',
  gettingStartedSteps: {
    integrationWizardCompleted: true,
    queryParameters: false,
    reviewSitemaps: false,
    teamInvited: false,
  },
  hadPaidPlan: false,
  hasBillingAddress: false,
  hasBillingInfo: false,
  hasBillingManager: false,
  hasCustomContractPricing: false,
  hasSession: false,
  id: 1,
  ignoreAllQueryParams: false,
  inProgress: false,
  isAdmin: true,
  isCacheFreshnessDisabled: false,
  isCancelled: false,
  isMeteredBilling: true,
  isTrial: false,
  lastCharged: new Date('Fri May 03 2024 12:15:29 GMT+0200'),
  lastName: null,
  mobileAdaptive: true,
  nextBillingAt: new Date('Fri April 03 2024 12:15:29 GMT+0200'),
  numPagesCached: 0,
  plan: {
    cacheRefreshInterval: [3, 3],
    current: {
      addonCostInCents: 0,
      apiAccess: false,
      cacheLimit: null,
      cacheLocation: CacheLocation.shared,
      cacheRefreshInterval: [3, 3],
      costInCents: 0,
      id: ChargeBeePlanId.Basic,
      includedCaches: 1000,
      index: 0,
      name: PlanName.Basic,
      support: Support.none,
    },
    estimate: {
      addonsPriceInCents: 0,
      currencyCode: 'USD',
      packagesPriceInCents: 0,
      planPriceInCents: 0,
      totalInCents: 0,
    },
    limit: 1000,
    name: PlanName.Basic,
    suggested: null,
  },
  planName: PlanName.Basic,
  queryParamsWhitelist: true,
  recacheDelay: 60000,
  recachingDisabled: false,
  renderCounter: 0,
  role: Role.Guest,
  settings: {
    customDesktopUserAgent:
      'Mozilla/5.0 (X11; Linux x86_64) AppleWebKit/537.36 (KHTML, like Gecko) HeadlessChrome/89.0.4389.82 Safari/537.36 Prerender (+https://github.com/prerender/prerender)',
    customMobileUserAgent:
      'Mozilla/5.0 (Linux; Android 11; Pixel 5) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/89.0.4389.72 Mobile Safari/537.36 Prerender (+https://github.com/prerender/prerender)',
    customMobileViewport: {
      height: 667,
      width: 375,
    },
    customViewport: {
      height: 718,
      width: 1440,
    },
    emailPreferences: {
      emailReportPeriod: 'WEEKLY',
      send75Percent: true,
      send100Percent: true,
      sendSuggested: true,
      send429Alerts: true,
    },
    forceHTTPS: true,
    loadImages: false,
    manualReactivate: false,
    pageLoadTimeout: 20000,
    renderRegion: 'shared',
    statusCodesToCache: [],
  },
  showOnboarding: false,
  showRegistrationSteps: false,
  signupDate: new Date('Fri May 03 2024 12:15:29 GMT+0200'),
  token: 'aaaaaa',
  trackingCodeInstalled: false,
  trackingCodeInstalledDate: null,
  trialEndsAt: null,
  unreadNotifications: 0,
  upgradedAt: null,
  upgradedFromChargebeePlanId: null,
  userPausedRecaching: false,
};

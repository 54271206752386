import { Modal, ModalFuncProps } from 'antd';
import React, { useEffect, useRef } from 'react';
import { usePrerenderUser } from '../../hooks/usePrerenderUser';

const FORM_CONTAINER_ID = 'hubspot-form-container';

type HubspotFormInstance = {};
declare global {
  interface Window {
    hbspt?: {
      forms: {
        create: (config: HubSpotFormConfig) => HubspotFormInstance;
      };
    };
    _hsq?: any[];
  }
}

interface HubSpotFormConfig {
  portalId: string;
  formId: string;
  region: string;
  target: string;
}

interface HubSpotFormModalProps {
  onClose: () => void;
}

const initHubSpotForm = (): void => {
  if (!document.querySelector('script[src*="hsforms.net"]')) {
    const script = document.createElement('script');
    script.crossOrigin = 'anonymous';
    script.type = 'text/javascript';
    script.src = '//js-eu1.hsforms.net/forms/embed/v2.js';
    document.head.appendChild(script);
  }
};

const initHubSpotChat = (onLoad?: () => void): void => {
  if (!document.querySelector('script[src*="hs-scripts.com"]')) {
    const script = document.createElement('script');
    script.id = 'hs-script-loader';
    script.async = true;
    script.defer = true;
    script.src = '//js-eu1.hs-scripts.com/25756777.js';
    script.onload = () => {
      // Initialize HubSpot's global queue if it doesn't exist
      // This queue is used for tracking and user identification
      window._hsq = window._hsq || [];
      onLoad?.();
    };
    script.onerror = (error) => {
      console.error('Error loading HubSpot script:', error);
    };
    document.head.appendChild(script);
  } else {
    window._hsq = window._hsq || [];
  }
};

const HubSpotFormModal: React.FC<HubSpotFormModalProps> = ({ onClose }) => {
  const formInstanceRef = useRef<HubspotFormInstance>();
  const hubspotFormContainerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (window.hbspt && hubspotFormContainerRef.current) {
      formInstanceRef.current = window.hbspt.forms.create({
        portalId: '25756777',
        formId: '2ac77811-8863-41c7-8f4f-df24d8b8a804',
        region: 'eu1',
        target: `#${FORM_CONTAINER_ID}`,
      });
    } else {
      console.error('HubSpot script not loaded or container not found');
    }

    return () => {
      if (formInstanceRef.current) {
        if (hubspotFormContainerRef.current) {
          hubspotFormContainerRef.current.innerHTML = '';
        }
      }
    };
  }, [onClose]);

  return <div id={FORM_CONTAINER_ID} ref={hubspotFormContainerRef} />;
};

export const openHubSpotForm = (): void => {
  let modalInstance: { destroy: () => void } | null = null;

  const handleClose = (): void => {
    if (modalInstance) {
      modalInstance.destroy();
    }
  };

  const modalConfig: ModalFuncProps = {
    content: <HubSpotFormModal onClose={handleClose} />,
    title: 'Contact Support',
    footer: null,
    icon: null,
    width: 500,
    centered: true,
    closable: true,
    maskClosable: true,
    onCancel: handleClose,
  };

  modalInstance = Modal.confirm(modalConfig);
};

export const useHubSpot = (shouldLoad: boolean): void => {
  const user = usePrerenderUser();
  const isUserIntegrated = user.trackingCodeInstalled;

  const showChat = !isUserIntegrated;

  useEffect(() => {
    if (!shouldLoad || !user.hasSession) {
      return;
    }

    initHubSpotForm();

    if (!showChat) {
      return;
    }

    const identifyUser = () => {
      if (window._hsq) {
        try {
          // Push user identification to HubSpot's queue
          // This will be processed by HubSpot when it's ready
          window._hsq.push([
            'identify',
            {
              id: String(user.id),
              email: user.email,
            },
          ]);
        } catch (error) {
          console.error('Error identifying user in HubSpot:', error);
        }
      } else {
        console.error('HubSpot not available for user identification');
      }
    };

    initHubSpotChat(identifyUser);
  }, [shouldLoad, user, isUserIntegrated, showChat]);
};

export const HubSpot = {
  openHubSpotForm,
  useHubSpot,
};

import React, { useState } from 'react';
import { Checkbox, Typography, Flex, InputNumber } from 'antd';
import WidgetFooter from './WidgetFooter';
import { FilterDropdownProps } from 'antd/lib/table/interface';

export type ResponseTimeFilter = Partial<{
  responseTimeLow: number;
  responseTimeHigh: number;
  timedout: boolean;
}>;

type ResponseTimeDropdownFilterProps = Pick<FilterDropdownProps, 'confirm' | 'clearFilters'> & {
  selectedKeys: ResponseTimeFilter[];
  setSelectedKeys: (keys: ResponseTimeFilter[]) => void;
};

export const ResponseDropdownFilter = ({
  confirm,
  selectedKeys,
  setSelectedKeys,
  clearFilters,
}: ResponseTimeDropdownFilterProps) => {
  const responseTime = selectedKeys.length ? selectedKeys[0] : {};

  const [disabled, setDisabled] = useState(
    responseTime.responseTimeLow === null && responseTime.responseTimeHigh === null && !responseTime.timedout
  );

  const onFieldChange = (field: keyof ResponseTimeFilter, value: any) => {
    setSelectedKeys([{ ...responseTime, [field]: value }]);
    setDisabled(false);
  };

  const onReset = () => {
    clearFilters?.();
    setDisabled(true);
  };

  return (
    <Flex vertical style={{ padding: 12 }}>
      <Typography.Text>Response Time in Sec.</Typography.Text>
      <Flex align="center" style={{ margin: '12px 0' }}>
        <Typography.Text>Between</Typography.Text>
        <InputNumber
          min={1}
          max={100}
          style={{ width: 80, margin: '0 6px' }}
          placeholder="e.g.1"
          value={responseTime?.responseTimeLow}
          onChange={(value) => onFieldChange('responseTimeLow', value)}
        />
        <Typography.Text>and</Typography.Text>
        <InputNumber
          min={1}
          max={100}
          style={{ width: 80, margin: '0 6px' }}
          placeholder="e.g.100"
          value={responseTime?.responseTimeHigh}
          onChange={(value) => onFieldChange('responseTimeHigh', value)}
        />
      </Flex>
      <Checkbox checked={responseTime?.timedout || false} onChange={(e) => onFieldChange('timedout', e.target.checked)}>
        Timed out
      </Checkbox>
      <WidgetFooter onOk={() => confirm()} resetDisabled={disabled} onReset={onReset} />
    </Flex>
  );
};

import { restApi } from '../../api/restApiSlice';

const date = new Date();
const timezoneOffsetInMinutes = date.getTimezoneOffset();

export const billingApiSlice = restApi.injectEndpoints({
  endpoints: (builder) => ({
    getInvoices: builder.query({
      query: () => `/billing/invoices`,
    }),
    getCostEstimate: builder.query({
      query: () => `/billing/estimation`,
    }),
    getRenderExpenses: builder.query({
      query: () => ({
        url: '/billing/expense/monthly',
      }),
    }),
    getInvoiceDownloadLink: builder.query({
      query: (invoiceId) => ({
        url: `/billing/invoices/${invoiceId}/download-link`,
      }),
    }),
    getRenderUsage: builder.query({
      query: (numberOfDays) => ({
        url: '/aggregated-metrics/renders/daily',
        params: {
          from: `-${numberOfDays}days`,
          timezoneOffset: timezoneOffsetInMinutes,
        },
      }),
    }),
    getPlans: builder.query({
      query: () => '/plans',
      providesTags: ['Plan'],
    }),
    changePlan: builder.mutation({
      query: ({ planId }) => ({
        url: '/plans',
        method: 'PATCH',
        body: { planId },
      }),
      providesTags: ['Plan'],
    }),
    isPaymentInfoValid: builder.query({
      query: () => '/billing/is-payment-info-valid',
    }),
    startTrial: builder.mutation({
      query: () => ({
        url: '/plans/trial',
        method: 'PATCH',
      }),
      invalidatesTags: ['Plan'],
    }),
  }),
});

export const {
  useGetInvoicesQuery,
  useGetCostEstimateQuery,
  useGetRenderExpensesQuery,
  useGetRenderUsageQuery,
  useLazyGetInvoiceDownloadLinkQuery,
  useGetPlansQuery,
  useIsPaymentInfoValidQuery,
  useLazyIsPaymentInfoValidQuery,
  useChangePlanMutation,
  useStartTrialMutation,
} = billingApiSlice;

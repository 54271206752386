import React from 'react';
import { Progress, theme, Typography, Flex } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';
import { TOOLTIP_ROOT_ID } from '../../../components/Tooltip';

type CounterBarProps = {
  count: number;
  limit: number;
  isLegacyUser: boolean;
  amountOf429Renders: number;
};

const calculatePercentage = (count: number, limit: number, amountOf429: number) => {
  const total = limit + (amountOf429 || count - limit);
  if (count < limit) {
    return (count / limit) * 100;
  }
  return (limit / total) * 100;
};

const calculateOverage = (count: number, limit: number, amountOf429: number) => {
  if (amountOf429) {
    return amountOf429;
  } else {
    return Math.max(0, count - limit);
  }
};

export const CounterBar = ({ count, limit, isLegacyUser, amountOf429Renders }: CounterBarProps) => {
  const { token } = theme.useToken();
  const overage = calculateOverage(count, limit, amountOf429Renders);
  const isOverLimit = count > limit;
  const is429OverLimit = amountOf429Renders > 0;

  return (
    <div style={{ marginTop: 12 }}>
      <Typography.Text strong>{isLegacyUser ? 'Number of cached pages' : 'Render Counter'}</Typography.Text>
      <Progress
        percent={calculatePercentage(count, limit, amountOf429Renders)}
        trailColor={isOverLimit || is429OverLimit ? token.colorError : undefined}
        strokeColor={token.colorPrimary}
        showInfo={false}
        status="active"
      />
      <Flex justify="space-between" align="center">
        <Typography.Text style={{ fontSize: 12, color: isOverLimit ? token.colorPrimary : undefined }}>
          {isOverLimit || is429OverLimit
            ? `Included: ${limit.toLocaleString()} renders`
            : `${count.toLocaleString()}/${limit.toLocaleString()} included renders`}
        </Typography.Text>
        <div>
          {is429OverLimit && (
            <InfoCircleOutlined
              style={{ marginRight: 4, color: token.colorError }}
              data-tooltip-id={TOOLTIP_ROOT_ID}
              data-tooltip-place="top"
              data-tooltip-content="Search engines don't see pages rendered as 429 status codes. Too many 429s could stall your SEO progress."
            />
          )}
          <Typography.Text
            style={{ fontSize: 12, color: isOverLimit || is429OverLimit ? token.colorError : undefined }}
          >
            {isOverLimit || is429OverLimit ? `Overage: ${overage.toLocaleString()} renders` : ''}
          </Typography.Text>
        </div>
      </Flex>
    </div>
  );
};

import { http, HttpResponse } from 'msw';
import { faker } from '@faker-js/faker';

const mockedResponse = [
  {
    domain: 'www.mybobs.com',
    url: 'https://www.mybobs.com/p/20077820',
    cache_state: 'Cached',
    user_id: 618242,
    last_refresh: '2025-02-24T13:38:37.259Z',
    requested_recache_at: null,
    adaptive_type: 'mobile',
    seo_score: -5,
    created_at: '2025-02-08T06:35:32.202Z',
    id: '65d53fb7-2d9d-ac69-507f-3e02fef4d7d2',
    last_delivery_at: '2025-02-14T23:58:11.000Z',
    source: 'cdn',
    seo_score_status: 'unknown',
    next_cache_at: '2025-03-01T13:38:37.259Z',
  },
  {
    domain: 'support.lastpass.com',
    url: 'https://support.lastpass.com/s/document-item?bundleId=lastpass&topicId=LastPass%2FLastPass_Authenticator_set_phone_number.html&_LANG=itit',
    cache_state: 'Caching...',
    user_id: 123534,
    last_refresh: null,
    requested_recache_at: '2024-07-03T12:00:38.183Z',
    adaptive_type: 'desktop',
    seo_score: null,
    created_at: '2024-07-03T12:00:38.183Z',
    id: '94bc3ce7-01ba-b872-2ad4-7fc1596b8941',
    last_delivery_at: '2025-02-06T13:26:45.000Z',
    source: 'sitemap',
    seo_score_status: 'unknown',
    next_cache_at: null,
  },
  {
    domain: 'support.lastpass.com',
    url: 'https://support.lastpass.com/s/document-item?bundleId=lastpass&topicId=LastPass%2FLastPass_Authenticator_set_phone_number.html&_LANG=eses',
    cache_state: 'Caching...',
    user_id: 123534,
    last_refresh: null,
    requested_recache_at: '2024-07-03T12:00:38.183Z',
    adaptive_type: 'desktop',
    seo_score: null,
    created_at: '2024-07-03T12:00:38.183Z',
    id: '85f8bef8-0e8d-a4bc-7660-8f5797851e21',
    last_delivery_at: '2025-02-05T12:04:25.000Z',
    source: 'sitemap',
    seo_score_status: 'unknown',
    next_cache_at: null,
  },
  {
    domain: 'support.lastpass.com',
    url: 'https://support.lastpass.com/s/document-item?bundleId=lastpass&topicId=LastPass%2Fapi_password_reset_email.html&_LANG=frfr',
    cache_state: 'Caching...',
    user_id: 123534,
    last_refresh: null,
    requested_recache_at: '2024-07-03T12:00:39.952Z',
    adaptive_type: 'desktop',
    seo_score: null,
    created_at: '2024-07-03T12:00:39.952Z',
    id: '6aeabc10-67be-9b01-1381-c8c4e9f7eeb2',
    last_delivery_at: '2025-02-12T18:12:16.000Z',
    source: 'sitemap',
    seo_score_status: 'unknown',
    next_cache_at: null,
  },
  {
    domain: 'support.lastpass.com',
    url: 'https://support.lastpass.com/s/document-item?bundleId=lastpass&topicId=LastPass%2Fc_navigating_your_lastpass_vault.html&_LANG=frfr',
    cache_state: 'Caching...',
    user_id: 123534,
    last_refresh: null,
    requested_recache_at: '2024-07-03T12:00:40.409Z',
    adaptive_type: 'desktop',
    seo_score: null,
    created_at: '2024-07-03T12:00:40.409Z',
    id: '087c8563-4224-59df-498f-e340054d777c',
    last_delivery_at: '2025-02-15T15:53:37.000Z',
    source: 'sitemap',
    seo_score_status: 'unknown',
    next_cache_at: null,
  },
  {
    domain: 'support.lastpass.com',
    url: 'https://support.lastpass.com/s/document-item?bundleId=lastpass&topicId=LastPass%2Fc_site_urls.html&_LANG=frfr',
    cache_state: 'Caching...',
    user_id: 123534,
    last_refresh: null,
    requested_recache_at: '2024-07-03T12:00:40.409Z',
    adaptive_type: 'desktop',
    seo_score: null,
    created_at: '2024-07-03T12:00:40.409Z',
    id: '6bbe7148-6259-9376-e09a-e95c3ad44614',
    last_delivery_at: '2025-02-22T00:06:45.000Z',
    source: 'sitemap',
    seo_score_status: 'unknown',
    next_cache_at: null,
  },
  ...Array.from({ length: 30 }, () => ({
    domain: faker.internet.domainName(),
    url: faker.internet.url(),
    cache_state: 'Caching...',
    user_id: 123534,
    last_refresh: null,
    requested_recache_at: '2024-07-03T12:00:40.409Z',
    adaptive_type: 'desktop',
    seo_score: null,
    created_at: '2024-07-03T12:00:40.409Z',
    id: faker.string.uuid(),
    last_delivery_at: '2025-02-22T00:06:45.000Z',
    source: 'sitemap',
    seo_score_status: 'unknown',
    next_cache_at: faker.helpers.arrayElement([faker.date.past(), faker.date.recent()]),
  })),
];

const getPriorityRecacheQueue = http.get('http://localhost:8443/api/priority-recache-queue', () => {
  return HttpResponse.json(mockedResponse);
});

const recacheQueue = http.get('http://localhost:8443/api/recache-queue', () => {
  return HttpResponse.json(mockedResponse);
});

export default [getPriorityRecacheQueue, recacheQueue];

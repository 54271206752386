import React from 'react';
import { Card, Empty, message } from 'antd';
import { Line } from 'react-chartjs-2';

import { getChartData } from '../../assets/charts';
import { useIsMobileView } from '../../hooks/useIsMobileView';
import { StatsEndpoint, useGetRenderHistoryStatsQuery } from './RenderHistoryApi';

type RenderUsageChartProps = {
  domain?: string;
};

function RenderUsageChart({ domain }: RenderUsageChartProps) {
  const [messageApi, messageContext] = message.useMessage();

  const totalStats = useGetRenderHistoryStatsQuery(
    { endpoint: StatsEndpoint.Total, domain },
    { refetchOnMountOrArgChange: true }
  );
  const failedStats = useGetRenderHistoryStatsQuery(
    { endpoint: StatsEndpoint.Failed, domain },
    { refetchOnMountOrArgChange: true }
  );
  const isMobile = useIsMobileView();

  React.useEffect(() => {
    if (totalStats.isError) {
      messageApi.error('Failed to fetch total render stats, please try again later');
    }
    if (failedStats.isError) {
      messageApi.error('Failed to fetch failed render stats, please try again later');
    }
  }, [totalStats.isError, failedStats.isError]);

  return (
    <>
      {messageContext}
      <Card
        title="Render Usage - Last 24 Hours"
        loading={totalStats.isLoading || failedStats.isLoading}
        styles={{ body: { height: '290px' } }}
      >
        {totalStats.isError && failedStats.isError ? (
          <Empty
            description="Could retrieve render usage stats, please try again later."
            image={Empty.PRESENTED_IMAGE_SIMPLE}
          />
        ) : (
          <Line
            data={getChartData('area', null, [
              { source: failedStats.data || [], label: 'Failed Renders', color: 'warning' },
              { source: totalStats.data || [], label: 'Total Renders', color: 'success' },
            ])}
            options={{
              animation: false,
              plugins: {
                legend: { display: true, position: isMobile ? 'top' : 'right', labels: { boxWidth: 10 } },
                tooltip: {
                  enabled: true,
                },
              },
              maintainAspectRatio: false,
              scales: {
                y: {
                  ticks: { stepSize: 1, autoSkipPadding: 20 },
                  grid: { color: '#f0f0f0' },
                  min: 0,
                },
                x: { grid: { color: '#f0f0f0' } },
              },
            }}
          />
        )}
      </Card>
    </>
  );
}

export default RenderUsageChart;

import { http, HttpResponse } from 'msw';

const getSeoScore = http.get('http://localhost:8443/api/v2/score', ({ request }) => {
  const url = new URL(request.url);
  const id = url.searchParams.get('id');
  if (!id) {
    return HttpResponse.json({ error: 'Missing id parameter' }, { status: 400 });
  }
  return HttpResponse.json({
    id,
    score: -2,
    recommendations: [
      {
        description: 'Define the <html lang="en_us"> attribute',
        documentation: 'https://docs.prerender.io/docs/html-element',
        scoreDelta: -1,
      },
      {
        description: 'Define the <meta name="description" content="Example description for SERP"> element',
        documentation: 'https://prerender.io/technical-seo-issues/',
        scoreDelta: -10,
      },
      {
        description: 'Missing og:title meta tag',
        documentation: 'https://docs.prerender.io/docs/open-graph',
        scoreDelta: -2,
      },
      {
        description: 'Missing og:type meta tag',
        documentation: 'https://docs.prerender.io/docs/open-graph',
        scoreDelta: -2,
      },
      {
        description: 'Missing og:image meta tag',
        documentation: 'https://docs.prerender.io/docs/open-graph',
        scoreDelta: -2,
      },
      {
        description: 'Missing og:url meta tag',
        documentation: 'https://docs.prerender.io/docs/open-graph',
        scoreDelta: -2,
      },
      {
        description: 'Missing og:description meta tag',
        documentation: 'https://docs.prerender.io/docs/open-graph',
        scoreDelta: -2,
      },
    ],
  });
});

export default [getSeoScore];

import React from 'react';
import { Modal } from 'antd';

import { integrationErrorMap } from '../../integrationWizard';

type IntegrationFailedModalProps = {
  open: boolean;
  onClose: () => void;
  errors?: number[];
};

function IntegrationFailedModal({ open, onClose, errors }: IntegrationFailedModalProps) {
  const handledErrors =
    (errors?.filter((errorCode) => errorCode in integrationErrorMap) as (keyof typeof integrationErrorMap)[]) || [];

  return (
    <Modal
      title="Prerender Integration Failed"
      open={open}
      onCancel={onClose}
      onOk={onClose}
      okText="Understood"
      footer={(_, { OkBtn }) => <OkBtn />}
    >
      {handledErrors?.map((errorCode) => (
        <div key={errorCode}>{integrationErrorMap[errorCode]}</div>
      ))}
    </Modal>
  );
}

export default IntegrationFailedModal;

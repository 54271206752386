export enum QueryParamNames {
  Query = 'q',
  QCondition = 'qCondition',
}

export type RecacheQueueQueryParams = Partial<{
  [QueryParamNames.Query]: string;
  [QueryParamNames.QCondition]: string;
}>;

export function isRecacheQueueParam(name: string): name is QueryParamNames {
  return Object.values(QueryParamNames).includes(name as QueryParamNames);
}

export function buildRecacheQueueQueryParams(data: Record<string, string>): RecacheQueueQueryParams {
  return Object.entries(data).reduce((acc, [name, value]) => {
    if (!isRecacheQueueParam(name)) return acc;

    const paramName = name as QueryParamNames;

    if (paramName === QueryParamNames.Query) {
      acc[paramName] = value;
      return acc;
    }

    if (paramName === QueryParamNames.QCondition) {
      acc[paramName] = value;
      return acc;
    }

    return acc;
  }, {} as RecacheQueueQueryParams);
}

import { Button, Modal, Select, Typography } from 'antd';
import { useState } from 'react';
import { useExportHistoryMutation } from '../api/rendersApiSlice';

const selectIntervalOptions = [
  { value: '1day', label: 'Past 24 hours' },
  { value: '7days', label: 'Past 7 days' },
  { value: '30days', label: 'Past 30 days' },
];

const selectFileTypeOptions = [
  { value: 'csv', label: 'CSV file' },
  // { value: 'excel', label: 'Spreadsheet (XLSX - Max 65536 rows of data allowed)' },
];

const mapExportAnalyticsResponseStatusToUserText = (status) => {
  switch (status) {
    case 'queued':
      return 'We have scheduled your export. You will get a download link in your email once the export is ready.';
    case 'already exists':
      return "You already have an export in progress. We'll notify you via email when that export finishes.";
    default:
      return "Something bad has happened. We're looking into it...";
  }
};

const NewExportRenderHistoryModal = ({ visible, setVisible, params }) => {
  const [userInput, setUserInput] = useState({
    interval: selectIntervalOptions[0].value,
    fileType: selectFileTypeOptions[0].value,
  });
  const [exportCSV, exportCSVStatus] = useExportHistoryMutation();

  const appendUserInput = (newInput) => {
    setUserInput((prevInput) => ({ ...prevInput, ...newInput }));
  };

  return (
    <Modal
      open={visible}
      title="Export Render History"
      okText="Export"
      onCancel={() => setVisible(false)}
      onOk={() => {
        exportCSV({ ...userInput, ...params });
      }}
      footer={
        exportCSVStatus.isSuccess
          ? [
              <Button key="close" type="primary" onClick={() => setVisible(false)}>
                Close
              </Button>,
            ]
          : undefined
      }
    >
      {exportCSVStatus.isSuccess ? (
        <Typography.Paragraph>
          {mapExportAnalyticsResponseStatusToUserText(exportCSVStatus.data.status)}
        </Typography.Paragraph>
      ) : exportCSVStatus.isError ? (
        <Typography.Paragraph type="danger">{exportCSVStatus.error.message}</Typography.Paragraph>
      ) : (
        <>
          <p>Select Report Interval</p>
          <Select
            options={selectIntervalOptions}
            defaultValue={selectIntervalOptions[0].value}
            className="w-100 no-border"
            onChange={(value) => appendUserInput({ interval: value })}
          />
          <p>Select Report Type</p>
          <Select
            options={selectFileTypeOptions}
            defaultValue={selectFileTypeOptions[0].value}
            className="w-100 no-border"
            onChange={(value) => appendUserInput({ fileType: value })}
          />
        </>
      )}
    </Modal>
  );
};

export default NewExportRenderHistoryModal;

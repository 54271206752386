import React from 'react';
import { Flex, Button } from 'antd';
import { FileSearchOutlined, ScheduleOutlined, EyeOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';

import useFeatureFlag, { FeatureFlag } from '../../../hooks/useFeatureFlag';
import { useCharbeePlanId, useIsPrerenderUser } from '../../../hooks/usePrerenderUser';
import { useEvent } from '../../events/hooks/useEvent';
import { TOOLTIP_ROOT_ID } from '../../../components/Tooltip';

type LinkCellProps = {
  id: number;
  domain: string;
  disabled?: boolean;
};
function LinksCell({ id, domain, disabled }: LinkCellProps) {
  const isPrerenderUser = useIsPrerenderUser();
  const chargebeePlanId = useCharbeePlanId();

  const isSeoReportPageAvailable = useFeatureFlag([FeatureFlag.SeoReport]);
  const isDetailsPageAvailable = useFeatureFlag([FeatureFlag.DomainsDetails]);
  const { track } = useEvent();

  return (
    <Flex gap={8}>
      {isSeoReportPageAvailable && (
        <div data-tooltip-id={TOOLTIP_ROOT_ID} data-tooltip-content="SEO Report Page" data-tooltip-place="top">
          <Link to={`/seo-report/${id}`}>
            <Button
              icon={<FileSearchOutlined />}
              disabled={disabled}
              onClick={() => {
                track('Seo Report Button Clicked', {
                  domain,
                  subscription_plan: chargebeePlanId,
                });
              }}
            />
          </Link>
        </div>
      )}
      {isPrerenderUser && (
        <div data-tooltip-id={TOOLTIP_ROOT_ID} data-tooltip-content="Analytics Page" data-tooltip-place="top">
          <Link to={{ pathname: `/analytics/${domain}` }}>
            <Button
              icon={<ScheduleOutlined />}
              disabled={disabled}
              onClick={() => {
                track('Analytics Button Clicked', {
                  domain,
                  subscription_plan: chargebeePlanId,
                });
              }}
            />
          </Link>
        </div>
      )}

      {isDetailsPageAvailable && (
        <Link to={domain}>
          <div data-tooltip-id={TOOLTIP_ROOT_ID} data-tooltip-content="Details Page" data-tooltip-place="top">
            <Button icon={<EyeOutlined />} disabled={disabled} />
          </div>
        </Link>
      )}
    </Flex>
  );
}

export default React.memo(LinksCell);

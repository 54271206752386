import React, { useMemo } from 'react';
import { Flex, Popover, Progress, ProgressProps, theme, ConfigProvider } from 'antd';
import { TOOLTIP_ROOT_ID } from '../../components/Tooltip';
import { BillingOverviewPlan } from '../../features/billing/overview/BillingOverviewPlan';
import { usePrerenderUser } from '../../hooks/usePrerenderUser';
import { isCustom } from '@prerender/billing-shared';

export const RenderCounter = () => {
  const { token } = theme.useToken();
  const user = usePrerenderUser();
  const isUserOnCustomPlan = isCustom(user.chargebeePlanId);
  const isLegacyUser = !user.isMeteredBilling;
  const currentCount = isLegacyUser ? user.numPagesCached : user.renderCounter;

  const percent = useMemo(() => {
    return Math.min((currentCount / user.plan.limit) * 100, 100);
  }, [currentCount, user.plan.limit]);

  const strokeColor: ProgressProps['strokeColor'] = useMemo(() => {
    if (percent >= 90) return token.colorError;
    if (percent >= 75) return token.colorWarning;
    return token.colorPrimary;
  }, [percent]);

  return (
    <ConfigProvider
      theme={{
        components: {
          Progress: {
            circleTextFontSize: percent > 99 ? '9px' : undefined,
            colorText: strokeColor,
            colorSuccess: token.colorError,
          },
        },
      }}
    >
      <Popover
        overlayInnerStyle={{ width: 380 }}
        placement="bottom"
        trigger={['click']}
        content={<BillingOverviewPlan user={user} isUserOnCustomPlan={isUserOnCustomPlan} showBillingPageLink />}
      >
        <Flex
          vertical
          style={{ cursor: 'pointer', padding: 7 }}
          data-tooltip-id={TOOLTIP_ROOT_ID}
          data-tooltip-content={`Renders ${currentCount.toLocaleString()}/${user.plan.limit.toLocaleString()}`}
          data-tooltip-place="bottom"
        >
          <Progress
            style={{ lineHeight: 1 }}
            percent={percent}
            size={34}
            type="circle"
            strokeColor={strokeColor}
            strokeWidth={10}
            format={(value) => (value ? `${value.toFixed(0)}% ` : '0%')}
          />
        </Flex>
      </Popover>
    </ConfigProvider>
  );
};

import React, { useMemo, useCallback } from 'react';
import { useLocation } from 'react-router-dom';
import { Menu, Divider } from 'antd';
import { isFree, isCustom } from '@prerender/billing-shared';

import { useCharbeePlanId, useHasTrackingCode, useIsPrerenderUser, useUserRole } from '../../hooks/usePrerenderUser';
import useFeatureFlag, { FeatureFlag } from '../../hooks/useFeatureFlag';
import menuItems, { PageKey, MenuItem, LeafMenuItem } from './menuItems';
import { UserRole } from '../../features/PrerenderUser';

type ParsedMenuItem = Pick<MenuItem, 'type' | 'label'> & {
  key: string;
  icon?: React.ReactNode;
  children?: ParsedMenuItem[];
};

const TARGET_TO_KEY = menuItems.reduce((acc, item) => {
  if (item.type === 'group') {
    item.children.forEach((child) => (acc[child.target] = child.id));
  } else {
    acc[item.target] = item.id;
  }
  return acc;
}, {} as Record<string, MenuItem['id']>);

function filterVisibleMenuItem(
  item: MenuItem,
  visibilityCheckerFn: (item: LeafMenuItem) => boolean
): MenuItem | undefined {
  if (item.type === 'group') {
    const children = item.children.filter((child) => visibilityCheckerFn(child));

    if (children.length) {
      return { ...item, children };
    }
  } else if (visibilityCheckerFn(item)) {
    return item;
  }
}

type SidebarProps = {
  isCollapsed: boolean;
};
const Sidebar = ({ isCollapsed }: SidebarProps) => {
  const location = useLocation();
  const oversightEnabled = useFeatureFlag([FeatureFlag.Oversight]);
  const customDealEnabled = useFeatureFlag([FeatureFlag.CustomDeal]);
  const hasTrackingCode = useHasTrackingCode();
  const isPrerenderUser = useIsPrerenderUser();
  const chargebeePlanId = useCharbeePlanId();
  const paidPlan = !isFree(chargebeePlanId);
  const role = useUserRole();

  const shouldBeVisible = useCallback(
    (item: LeafMenuItem) => {
      switch (item.id) {
        case PageKey.IntegrationWizard:
          return !hasTrackingCode && role !== UserRole.Guest;

        case PageKey.DomainAnalytics:
          return isPrerenderUser;

        case PageKey.Oversight:
          return oversightEnabled;

        // hidden page
        // case PageKey.FeatureRequests:
        //   return paidPlan;

        case PageKey.CustomDeal:
          return isCustom(chargebeePlanId) || customDealEnabled;

        default:
          return item.visible;
      }
    },
    [hasTrackingCode, isPrerenderUser, paidPlan, chargebeePlanId, role, customDealEnabled, oversightEnabled]
  );

  const items: ParsedMenuItem[] = useMemo(
    () =>
      menuItems.reduce((acc, item) => {
        const filteredItem = filterVisibleMenuItem(item, shouldBeVisible);
        if (filteredItem) {
          if (filteredItem.type === 'group') {
            acc.push({
              type: 'group',
              key: filteredItem.id,
              label: isCollapsed ? <Divider style={{ margin: '10px 0' }} /> : filteredItem.label,
              children: filteredItem.children.map((child) => ({
                key: child.id,
                label: child.label,
                icon: child.icon,
                type: 'item',
              })),
            });
          } else if (filteredItem.type === 'item') {
            acc.push({ key: filteredItem.id, label: filteredItem.label, icon: filteredItem.icon, type: 'item' });
          }
        }
        return acc;
      }, [] as ParsedMenuItem[]),
    [shouldBeVisible, isCollapsed]
  );

  const selectedKey = TARGET_TO_KEY[`/${location.pathname.split('/')[1]}`];

  return <Menu selectedKeys={[selectedKey]} mode="inline" items={items} inlineIndent={12} />;
};

export default React.memo(Sidebar);

import { Steps, Flex } from 'antd';
import requireAuth from '../../components/hocs/requireAuth';
import AdminTemplate from '../../layout/AdminTemplate';
import VerificationSuccessfulStep from './components/steps/VerificationSuccessfulStep';
import { useIntegrationWizardSteps } from './useIntegrationWizardSteps';
import { Navigate, Route, Routes } from 'react-router-dom';
import InviteTeamStep from './components/steps/InviteTeamStep/InviteTeamStep';
import USER_ROLES from '../auth/userRoles';
import IntegrationStep from './components/steps/IntegrationStep/IntegrationStep';
import VerifyDomainRouter from './components/steps/VerifyDomainStep';

const META_TAGS = {
  title: 'Get Started - Prerender.io',
};

const stepConfig = [
  { path: 'invite-team', code: 'invite-team', title: 'Invite team', disabled: true },
  /*{ path: 'sitemap', title: 'Import Sitemaps', component: ImportSitemapsStep, disabled: true },*/
  { path: 'integration', code: 'suggested-integration', title: 'Integrate', disabled: true },
  { path: 'verify-integration', code: 'domain', title: 'Verify integration', disabled: true },
  { path: 'verification', code: 'verification', title: 'Start Rendering', disabled: true },
];

const IntegrationWizardPage = () => {
  const { onGoBack, onGoNext, currentStep, setCurrentStep } = useIntegrationWizardSteps(stepConfig);

  return (
    <AdminTemplate metaTags={META_TAGS}>
      <Steps current={currentStep} items={stepConfig} onChange={setCurrentStep} style={{ marginBottom: 24 }} />

      <Flex align="center" justify="center" style={{ minHeight: 'calc(100vh - 64px - 32px - 16px)' }} vertical>
        <Routes>
          <Route
            path="invite-team"
            element={<InviteTeamStep onGoBack={onGoBack} onGoNext={onGoNext} currentStep={currentStep} />}
          />
          <Route path="integration" element={<IntegrationStep onGoBack={onGoBack} onGoNext={onGoNext} />} />
          <Route
            path="verify-integration/*"
            element={
              <VerifyDomainRouter
                onGoNext={onGoNext}
                onGoBack={onGoBack}
                currentStep={currentStep}
                setCurrentStep={setCurrentStep}
              />
            }
          />
          <Route path="verification" element={<VerificationSuccessfulStep onGoNext={onGoNext} />} />
          <Route path="" element={<Navigate to={stepConfig[0].path} />}></Route>
        </Routes>
      </Flex>
    </AdminTemplate>
  );
};

const isAuthorized = ({ role }) => {
  return role !== USER_ROLES.GUEST;
};
export default requireAuth(IntegrationWizardPage, isAuthorized);

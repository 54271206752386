import React from 'react';
import { getAgeString } from '../../assets/time';
import { Button, Card, ConfigProvider, Flex, Table, Typography } from 'antd';
import { DeleteOutlined, ReloadOutlined } from '@ant-design/icons';

import ButtonWithRole from '../../components/ButtonWithRole';
import { UserRole } from '../PrerenderUser';
import { BlurringOverlay } from '../../components/BlurringOverlay/BlurringOverlay';
import { RenderingQueueEmptyTable } from '../RenderingQueue';
import PrevNextPagination, { PrevNextPaginationProps } from '../../components/PrevNextPagination';
import { Tabs } from '../../pages/NewScheduledRenderingPage';
import SearchBarV2 from '../../components/SearchBarV2';
import { RecacheQueueQueryParams } from './QueryParameters';
import ScheduledRecache from './ScheduledRecache';

type RecacheQueueTableProps = {
  showBlurring: boolean;
  isLoading: boolean;
  onReloadData: () => void;
  onClearCache?: () => void;
  queue: ScheduledRecache[];
  activeTab: Tabs;
  pagination: PrevNextPaginationProps;
  currentSearchQuery: RecacheQueueQueryParams['q'];
  currentQCondition: RecacheQueueQueryParams['qCondition'];
  onSearchConditionChanged: (condition: string) => void;
  onTypingEnd: (query: string) => void;
};

const columns = [
  {
    title: 'Queued URL',
    key: 'url',
    dataIndex: 'url',
    width: '70%',
    render: (url: string) => (
      <Typography.Link href={url} target="_blank" rel="noreferrer">
        {url}
      </Typography.Link>
    ),
  },
  {
    title: 'Queued',
    dataIndex: 'next_cache_at',
    width: '15%',
    render: (nextCachedAt: ScheduledRecache['next_cache_at'] = '') =>
      nextCachedAt ? getAgeString(new Date(nextCachedAt), 'ago', 'long', true) : '---',
  },
  {
    title: 'Last Recache',
    key: 'recached',
    dataIndex: 'last_refresh',
    width: '15%',
    render: (lastRefresh: ScheduledRecache['last_refresh']) =>
      lastRefresh ? getAgeString(new Date(lastRefresh), 'ago', 'long', true) : '---',
  },
];

export const RecacheQueueTable = ({
  showBlurring,
  isLoading,
  onReloadData,
  queue,
  activeTab,
  pagination,
  currentSearchQuery,
  currentQCondition,
  onSearchConditionChanged,
  onTypingEnd,
  onClearCache,
}: RecacheQueueTableProps) => {
  return (
    <Card
      title={
        <Flex justify="space-between" align="center">
          <Typography.Title style={{ margin: 0 }} level={5}>
            Pages {activeTab === Tabs.ManualQueue ? 'manually' : 'automatically'} scheduled for recaching
          </Typography.Title>
          {activeTab === Tabs.ManualQueue && (
            <ButtonWithRole
              disabledFor={[UserRole.BillingManager, UserRole.Guest]}
              type="primary"
              danger
              onClick={onClearCache}
              disabled={!onClearCache}
              icon={<DeleteOutlined />}
            >
              Clear Manual Queue
            </ButtonWithRole>
          )}
        </Flex>
      }
      styles={{ body: { padding: 12 } }}
    >
      <BlurringOverlay
        enabled={showBlurring}
        location="Scheduled Rendering page"
        content="Complete your integration to see all pages up for re-caching."
        buttonData={null}
        opacity={0.5}
      />
      <Flex justify="end" gap="small">
        <SearchBarV2
          initialValue={currentSearchQuery}
          qCondition={currentQCondition}
          onTypingEnd={onTypingEnd}
          onSearchConditionChanged={onSearchConditionChanged}
          disabled={false}
          placeholder="Please enter at least 3 characters to start your search"
        />
        <Button icon={<ReloadOutlined />} onClick={onReloadData} disabled={isLoading} name="reload" />
      </Flex>
      <ConfigProvider
        renderEmpty={() => (
          <RenderingQueueEmptyTable hasQuery={!!currentSearchQuery} isPriorityTab={activeTab === Tabs.ManualQueue} />
        )}
      >
        <Table
          bordered={!isLoading}
          rowKey="id"
          loading={isLoading}
          columns={columns}
          dataSource={queue}
          pagination={false}
          showHeader={!!queue?.length}
          style={{ marginTop: 12 }}
          scroll={{ x: true }}
          size="middle"
          sticky={{ offsetHeader: 0, offsetScroll: 10 }}
        />
      </ConfigProvider>
      <PrevNextPagination {...pagination} />
    </Card>
  );
};

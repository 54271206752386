import React from 'react';
import { Flex } from 'antd';
import { isEnterprise } from '@prerender/billing-shared';
import dayjs from 'dayjs';

import { HelpSection } from '../../features/CustomerSupport';
import { usePrerenderUser } from '../../hooks/usePrerenderUser';
import AccountDropdown from './AccountDropdown';
import { GetStartedChecker } from '../../features/GetStartedChecker';
import { RenderCounter } from './RenderCounter';
import { NotificationsPreviewWidget } from '../../features/NotificationsCenter';
import { isCustom } from '@prerender/billing-shared';

const isSignupLessThanTwoWeeks = (signupDate) => {
  const twoWeeksAgo = dayjs().subtract(14, 'days');
  return dayjs(signupDate).isAfter(twoWeeksAgo);
};

const NavigationTopbar = () => {
  const user = usePrerenderUser();
  const eventsTrackingData = {
    userId: user.id,
    userEmail: user.email,
    companyId: user.companyId,
    companyName: user.companyName,
  };
  const isUserOnCustomPlan = isCustom(user.chargebeePlanId);

  const showGetStartedChecker =
    Object.values(user?.gettingStartedSteps).some((value) => value === false) &&
    isSignupLessThanTwoWeeks(user.signupDate);

  return (
    <Flex align="center" justify={showGetStartedChecker ? 'space-between' : 'flex-end'} flex={1}>
      {showGetStartedChecker && <GetStartedChecker />}
      <Flex align="center" gap="small">
        <HelpSection contactCSMVisible={isEnterprise(user.chargebeePlanId)} eventsTrackingData={eventsTrackingData} />
        <NotificationsPreviewWidget unreadNotificationsCount={user.unreadNotifications} />
        {!isUserOnCustomPlan && <RenderCounter />}
        <AccountDropdown />
      </Flex>
    </Flex>
  );
};

export default NavigationTopbar;

import { http, HttpResponse } from 'msw';
import { faker } from '@faker-js/faker';

const getPlanList = http.get('http://localhost:3030/v3/plans', () => {
  return HttpResponse.json({
    data: [
      {
        id: 'basic-001',
        name: 'Free',
        index: 0,
        costInCents: 0,
        addonCostInCents: 0,
        suggested: false,
        current: false,
        includedCaches: 1000,
        cacheRefreshInterval: [3, 3],
        cacheLocation: 'shared',
        available: true,
        support: 'none',
        contactUsToUse: false,
        tier: 'free-001',
        apiAccess: false,
      },
      {
        id: 'business-001',
        name: 'Business',
        index: 3,
        costInCents: 29900,
        addonCostInCents: 75,
        suggested: false,
        current: true,
        includedCaches: 3000000,
        cacheRefreshInterval: [1, 90],
        cacheLocation: 'both',
        available: false,
        support: 'normal',
        contactUsToUse: false,
        tier: 'p2020-001',
        apiAccess: false,
      },
      {
        id: 'startup-2022',
        name: 'Startup',
        index: 2,
        costInCents: 9000,
        addonCostInCents: 200,
        suggested: false,
        current: false,
        includedCaches: 100000,
        cacheRefreshInterval: [1, 7],
        cacheLocation: 'shared',
        available: true,
        support: 'low',
        contactUsToUse: false,
        tier: 'p2022-001',
        apiAccess: false,
      },
      {
        id: 'scaleup-2022',
        name: 'Scaleup',
        index: 3,
        costInCents: 29000,
        addonCostInCents: 100,
        suggested: false,
        current: false,
        includedCaches: 1000000,
        cacheRefreshInterval: [0.5, 14],
        cacheLocation: 'both',
        available: true,
        support: 'normal',
        contactUsToUse: false,
        tier: 'p2022-001',
        apiAccess: true,
      },
      {
        id: 'enterprise-2022',
        name: 'Enterprise',
        index: 4,
        costInCents: 99000,
        addonCostInCents: 50,
        suggested: false,
        current: false,
        includedCaches: 5000000,
        cacheRefreshInterval: [0.25, 30],
        cacheLocation: 'both',
        available: true,
        support: 'high',
        contactUsToUse: true,
        tier: 'p2022-001',
        apiAccess: true,
      },
      ...Array.from({ length: 5 }, (_, index) => ({
        id: `fake-plan-${index}`,
        name: `Fake plan ${index}`,
        index: 5 + index,
        costInCents: faker.number.int({ min: 10000, max: 100000 }),
        addonCostInCents: faker.number.int({ min: 50, max: 200 }),
        suggested: false,
        current: false,
        includedCaches: faker.number.int({ min: 100000, max: 10000000 }),
        cacheRefreshInterval: [faker.number.float({ min: 0.25, max: 3 }), faker.number.float({ min: 7, max: 30 })],
        cacheLocation: faker.helpers.arrayElement(['shared', 'both']),
        available: true,
        support: faker.helpers.arrayElement(['none', 'low', 'normal', 'high']),
        contactUsToUse: false,
        tier: faker.string.alphanumeric(),
        apiAccess: faker.datatype.boolean(),
      })),
    ],
  });
});

export default [getPlanList];

const enum PrerenderUserAction {
  UserSet = 'prerender_user_set',
  UserTokenSet = 'update_prerender_token',
  UserLoadingSet = 'prerender_user_in_progress',
  UserAnauthorized = 'prerender_user_unauthorized',
  UserTrackingCodeSet = 'update_prerender_user_tracking_code',
  UserShowOnboardingSet = 'show_onboarding_on_login',
  UserGettingStartedStepsSet = 'decrease_progress_count',
  UserReset = 'prerender_user_clear',
  UserUnreadNotificationsDecrease = 'decrease_notifications_unread_count',
  UserPatch = 'prerender_user_patch',
}

export default PrerenderUserAction;

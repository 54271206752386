import React from 'react';
import { Button, Divider, Flex } from 'antd';

type WidgetFooterProps = {
  onOk: () => void;
  onReset: () => void;
  resetDisabled: boolean;
};

function WidgetFooter({ onOk, onReset, resetDisabled }: WidgetFooterProps) {
  return (
    <div>
      <Divider style={{ margin: '12px 0' }} />
      <Flex justify="space-between">
        <Button size="small" type="link" onClick={onReset} disabled={resetDisabled}>
          Reset
        </Button>
        <Button type="primary" size="small" onClick={onOk}>
          OK
        </Button>
      </Flex>
    </div>
  );
}

export default WidgetFooter;

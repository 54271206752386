import React from 'react';
import { Button, Card, Flex, Table, TablePaginationConfig, Typography } from 'antd';
import { ReloadOutlined } from '@ant-design/icons';
import { FilterValue, SorterResult } from 'antd/lib/table/interface';

import { getAgeString } from '../../assets/time';
import { statusCodeBadgeColor } from '../../assets/activityPageLib';
import { StatusCodeFilter, StatusDropdownFilter } from '../../components/dropdownFilters/StatusDropdownFilter';
import { ResponseDropdownFilter, ResponseTimeFilter } from '../../components/dropdownFilters/ResponseDropdownFilter';
import { RenderedTimeFilter, RenderedTimeDropdown } from '../../components/dropdownFilters/RenderedTimeDropdown';
import { usePrerenderUser } from '../../hooks/usePrerenderUser';
import { useEvent } from '../events/hooks/useEvent';
import { createFilterEventProperties } from '../../utils/createFilterEventProperties';
import SearchBarV2 from '../../components/SearchBarV2';
import ExportButton from '../../components/ExportButton';
import { isFreePlan } from '../../chargebee/chargebee';
import PrevNextPagination, { PrevNextPaginationProps } from '../../components/PrevNextPagination';
import { RenderHistoryQueryParameters, Sort } from './QueryParameters';
import { TOOLTIP_ROOT_ID } from '../../components/Tooltip';
import Render, { Source } from './Render';
import { SourceTableCell, SourceLabels } from './SourceCell';

type RenderHistoryTableProps = {
  loading: boolean;
  dataSource: Render[];
  onChangeTable: (filters: {
    sort: RenderHistoryQueryParameters['sort'];
    sortDirection: RenderHistoryQueryParameters['sortDirection'];
    filters: Partial<RenderHistoryQueryParameters>;
  }) => void;
  onReloadData: () => void;
  clearAllFilters: () => void;
  updateSearchQuery: (query: string) => void;
  onSearchConditionUpdate: (condition: string) => void;
  currentSearchQuery?: string;
  currentQCondition?: string;
  setExportModalVisible: (visible: boolean) => void;
  pagination: PrevNextPaginationProps;
  filteredInfo: {
    time: RenderedTimeFilter[];
    status_code: StatusCodeFilter[];
    render_time: ResponseTimeFilter[];
    adaptive_type: Render['adaptive_type'][];
    source: Render['source'][];
  };
};

const deviceOptions = [
  {
    text: 'Desktop',
    value: 'desktop',
  },
  {
    text: 'Mobile',
    value: 'mobile',
  },
];

const mapToApiPayload = (filters: RenderHistoryTableProps['filteredInfo']): Partial<RenderHistoryQueryParameters> => {
  const httpStatus = filters.status_code?.[0] || {};
  const renderTime = filters.render_time?.[0] || {};
  const renderedAt = filters.time?.[0] || {};

  return {
    statusCodeEq: httpStatus.statusCodeEq,
    statusCodeLow: httpStatus.statusCodeLow,
    statusCodeHigh: httpStatus.statusCodeHigh,
    responseTimeLow: renderTime.responseTimeLow,
    responseTimeHigh: renderTime.responseTimeHigh,
    timedout: renderTime.timedout,
    renderedTimeLow: renderedAt.renderedTimeLow,
    renderedTimeHigh: renderedAt.renderedTimeHigh,
    adaptive_type: filters.adaptive_type?.length ? filters.adaptive_type : undefined,
    source: filters.source?.length ? filters.source : undefined,
  };
};

const mapToTrackingProperties = (filterResult: RenderHistoryTableProps['filteredInfo']) => {
  const httpStatus = filterResult.status_code?.[0] || {};
  const renderTime = filterResult.render_time?.[0] || {};
  const renderedAt = filterResult.time?.[0] || {};

  return {
    rendered_at: !!filterResult.render_time?.length
      ? `${renderedAt.renderedTimeLow}-${renderedAt.renderedTimeHigh}`
      : '',
    selected_device: filterResult.adaptive_type?.join(','),
    selected_status: !!filterResult.status_code?.length
      ? httpStatus.statusCodeEq || `${httpStatus.statusCodeLow}-${httpStatus.statusCodeHigh}`
      : '',
    response_time: !!filterResult.render_time?.length
      ? `${renderTime.responseTimeLow}-${renderTime.responseTimeHigh}`
      : '',
    timedout: renderTime.timedout,
  };
};

const SOURCE_FILTERS_OPTIONS = Object.entries(SourceLabels).map(([source, { label }]) => ({
  text: label,
  value: source,
}));

function RenderHistoryTable({
  loading,
  dataSource = [],
  onChangeTable,
  onReloadData,
  clearAllFilters,
  updateSearchQuery,
  onSearchConditionUpdate,
  currentSearchQuery,
  currentQCondition,
  setExportModalVisible,
  pagination,
  filteredInfo,
}: RenderHistoryTableProps) {
  const { track } = useEvent();
  const user = usePrerenderUser();
  const isFreeUser = isFreePlan(user.plan);
  const clearTableFilters = () => {
    clearAllFilters();
  };

  const onChange = (
    _latestPagination: TablePaginationConfig,
    filters: Record<string, FilterValue | null>,
    sorter: SorterResult<Render> | SorterResult<Render>[],
  ) => {
    const singleSorter = Array.isArray(sorter) ? sorter[0] : sorter;
    const dataIndex = singleSorter.column?.dataIndex as Sort | undefined;

    const selectedFiltersToTrack = createFilterEventProperties(filters, filteredInfo, mapToTrackingProperties);

    onChangeTable({
      filters: mapToApiPayload(filters as unknown as RenderHistoryTableProps['filteredInfo']),
      sort: dataIndex,
      sortDirection: singleSorter.order === 'ascend' ? 'ASC' : 'DESC',
    });
    if (Object.keys(selectedFiltersToTrack).length !== 0) {
      track(`Render History Filtered: ${Object.keys(selectedFiltersToTrack)}`, {
        subscription_plan: user.chargebeePlanId,
        ...selectedFiltersToTrack,
      });
    }
  };

  return (
    <Card
      styles={{ body: { padding: 12 } }}
      title={
        <Flex align="center" justify="space-between" gap="small">
          <SearchBarV2
            initialValue={currentSearchQuery}
            qCondition={currentQCondition ? currentQCondition : 'like'}
            onTypingEnd={updateSearchQuery}
            onEnterPressed={updateSearchQuery}
            onSearchConditionChanged={onSearchConditionUpdate}
            placeholder="Please enter at least 3 characters to start your search"
          />
          <Flex gap="small">
            <Button disabled={loading} onClick={onReloadData} size="small" icon={<ReloadOutlined />} />

            <Button disabled={loading} onClick={() => clearTableFilters()} size="small">
              Clear filters
            </Button>
            <ExportButton isFreePlan={isFreeUser} onClick={() => setExportModalVisible(true)} />
          </Flex>
        </Flex>
      }
    >
      <Table<Render>
        loading={loading}
        dataSource={dataSource}
        onChange={onChange}
        scroll={{ x: 1000 }}
        pagination={false}
        size="small"
        bordered
        sticky={{ offsetHeader: 0, offsetScroll: 10 }}
      >
        <Table.Column
          title="Rendered At"
          dataIndex="time"
          align="center"
          width={150}
          sorter={true}
          render={(time: Render['time']) => getAgeString(new Date(time), 'ago', 'long')}
          filteredValue={filteredInfo.time as unknown as string[]}
          filterDropdown={({ confirm, setSelectedKeys, selectedKeys, clearFilters }) => {
            return (
              <RenderedTimeDropdown
                confirm={confirm}
                selectedKeys={selectedKeys as unknown as RenderedTimeFilter[]}
                setSelectedKeys={setSelectedKeys as unknown as (selectedKeys: RenderedTimeFilter[]) => void}
                clearFilters={clearFilters}
                label="Rendered at (in hours)"
              />
            );
          }}
        />
        <Table.Column
          title="HTTP Status"
          dataIndex="status_code"
          align="center"
          width={140}
          sorter={true}
          render={(_statusCode: Render['status_code'], item: Render) => (
            <span className={`badge ${statusCodeBadgeColor(item)}`} style={{ fontSize: '90%', fontWeight: '500' }}>
              {item.status_code}
            </span>
          )}
          filteredValue={filteredInfo.status_code as unknown as string[]}
          filterDropdown={({ confirm, setSelectedKeys, selectedKeys, clearFilters }) => {
            return (
              <StatusDropdownFilter
                confirm={confirm}
                selectedKeys={selectedKeys as StatusCodeFilter[]}
                setSelectedKeys={(keys) => setSelectedKeys(keys.map((key) => key as unknown as string))}
                clearFilters={clearFilters}
              />
            );
          }}
        />
        <Table.Column
          title="Render Time"
          dataIndex="render_time"
          align="center"
          width={140}
          sorter={true}
          render={(renderTime: Render['render_time'], item: Render) =>
            item.timedout ? (
              <div className="badge bg-danger" style={{ fontSize: '90%', fontWeight: '500' }}>
                <span>{Math.round(renderTime)} ms</span>
              </div>
            ) : (
              <span>{Math.round(renderTime)} ms</span>
            )
          }
          filteredValue={filteredInfo.render_time as unknown as string[]}
          filterDropdown={({ confirm, setSelectedKeys, selectedKeys, clearFilters }) => (
            <ResponseDropdownFilter
              confirm={confirm}
              selectedKeys={selectedKeys as ResponseTimeFilter[]}
              setSelectedKeys={setSelectedKeys as unknown as (keys: ResponseTimeFilter[]) => void}
              clearFilters={clearFilters}
            />
          )}
        />
        <Table.Column
          title="URL"
          dataIndex="url"
          render={(url: Render['url']) => (
            <Typography.Link href={url} target="_blank" rel="noreferrer">
              {url}
            </Typography.Link>
          )}
        />
        <Table.Column
          title="Device"
          dataIndex="adaptive_type"
          align="center"
          width={120}
          filters={deviceOptions}
          filteredValue={filteredInfo.adaptive_type}
          render={(adaptiveType: Render['adaptive_type']) => (
            <i
              data-tooltip-id={TOOLTIP_ROOT_ID}
              data-tooltip-content={`Rendering was optimized for [${
                adaptiveType === 'mobile' ? 'Mobile' : 'Desktop'
              }] crawlers`}
              data-tooltip-place="left"
              data-tip={adaptiveType === 'mobile' ? 'Mobile' : 'Desktop'}
              className={adaptiveType === 'mobile' ? 'fe fe-smartphone' : 'fe fe-monitor'}
            />
          )}
        />
        <Table.Column
          title="Source"
          dataIndex="source"
          width={100}
          sorter
          filteredValue={filteredInfo.source}
          filters={SOURCE_FILTERS_OPTIONS}
          render={(source: Source) => <SourceTableCell sourceName={source} />}
        />
      </Table>
      {!!dataSource.length && <PrevNextPagination {...pagination} />}
    </Card>
  );
}

export default RenderHistoryTable;

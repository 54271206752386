// imported by /reducers/index.js
// defines the 'page' part of the 'state' object.

import {
  CLEAR_LASTERRORCODE,
  CLOSE_MODAL,
  CONTENT_LOADING_IN_PROGRESS,
  CONTENT_SET,
  ERASE_PAGE_REDUCER,
  OPEN_MODAL,
  SET_LASTERRORCODE,
  SET_MODAL_HEIGHT,
  SET_SSR_ENTRY_URL,
  UPDATE_PAGE_CONTENT,
} from '../actions/types';

import config from '../assets/config';
import { clone } from '../assets/lib';

const initialState = {
  language: 'en',
  lastErrorCode: '',
  mobileDevice: false,
  modalVisible: '',
  modalContent: {},
  modalHeight: null,
  lastOpenedModal: null,
  ssrEntryUrl: {
    path: '/',
    dir: [],
    param: {},
    hash: '',
  },
  cmsLoading: false,
  contentData: {
    default: {
      meta: {
        title: 'Members Area - Prerender',
        // eslint-disable-next-line max-len
        description:
          'Prerender is a Google-recommended dynamic rendering solution that enables Angular, React, Vue, or JavaScript sites to be crawled perfectly by search engines.',
        ogTitle: 'Prerender - Dynamic Rendering for Effective JavaScript SEO',
        // eslint-disable-next-line max-len
        ogDescription:
          'Prerender.io is a Google-recommended dynamic rendering solution that enables Angular, React, Vue, or JavaScript sites to be crawled perfectly by search engines.',
        ogImage: config.defaultOgBackgroundImage,
        ogUrl: 'https://prerender.com',
        canonical: 'https://app.prerender.com',
        robots: 'noIndex, noFollow',
      },
    },
    // NOTE: the messages here are not used at all. The messages from the contents are used
    apiError: {
      E0000: 'Network Error: Internet or Server connection unavailable.',
      E0001: 'An unknown error occured.',
      E0002: 'Loading data failed.',
      E0003: 'Data could not be saved.',
      E0004: 'Invalid input parameter.',
      E0005: 'Authorization failed.',
      E0006: 'Authorization failed.',
      E0007: 'Could not resolve URL during SSR',
      E1008: 'Please input your billing information',
    },
  },
  cookieConsent: false,
};

// sets content of state.content based on incoming action.payload.language value:
export default function pageReducer(state = initialState, action) {
  const { payload } = action;
  switch (action.type) {
    case SET_LASTERRORCODE:
      return { ...state, lastErrorCode: payload };
    case CLEAR_LASTERRORCODE:
      return { ...state, lastErrorCode: '' };
    case OPEN_MODAL:
      return { ...state, modalVisible: payload.id, modalContent: payload.content, lastOpenedModal: payload.id };
    case CLOSE_MODAL:
      return { ...state, modalVisible: '', modalContent: null, modalHeight: null };
    case SET_MODAL_HEIGHT:
      return { ...state, modalHeight: payload };
    case SET_SSR_ENTRY_URL:
      return { ...state, ssrEntryUrl: payload };
    case ERASE_PAGE_REDUCER:
      // actually, not all vales are being reset. E.g. spare targetAfterSignin:
      return {
        ...state,
        lastErrorCode: '',
        modalVisible: '',
        modalCallback: null,
        modalTxt: {},
        lastFeedUpdateTrigger: config.pastDate,
        lastFollowListUpdateTrigger: config.pastDate,
        performFollowListUpdate: false,
      };
    case UPDATE_PAGE_CONTENT: {
      // the state content tree received
      const { component, contentIds } = payload;
      const existingContent = clone(state.content);
      if (!existingContent.text[component]) existingContent.text[component] = {};
      contentIds.forEach((contentId) => {
        existingContent.text[component][contentId.id] = contentId.content;
      });
      return { ...state, content: existingContent };
    }
    case CONTENT_SET: {
      const contentCopy = clone(state.contentData);
      if (Array.isArray(payload)) {
        payload.forEach((row) => {
          contentCopy[row.component] = row.content;
        });
      }
      const updatedState = { ...state, contentData: contentCopy, cmsLoading: false };
      return updatedState;
    }
    case CONTENT_LOADING_IN_PROGRESS:
      return { ...state, cmsLoading: payload };
    default:
  }
  return state;
}

import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Flex, Typography, Layout, Button, Dropdown, Space, message, Card } from 'antd';
import { DownOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';

import requireAuth from '../../components/hocs/requireAuth';
import AdminTemplate from '../../layout/AdminTemplate';
import { useEvent } from '../events/hooks/useEvent';
import { UserInterviewBanner } from '../../components/UserInterviewBanner';

import { useHasTrackingCode, useIsPrerenderUser, usePrerenderUser, useUserRole } from '../../hooks/usePrerenderUser';
import { useIsMobileView } from '../../hooks/useIsMobileView';
import USER_ROLES from '../auth/userRoles';

const { Title, Text } = Typography;
const { Content } = Layout;

const META_TAGS = {
  title: 'Dashboard - Prerender.io',
};

import { DashboardUsageOverview } from './components/DashboardUsageOverview';
import { DeliveredToCrawlerCharts } from './components/DeliveredToCrawlerCharts';
import { DeliveredByStatusCharts } from './components/DeliveredByStatusCharts';
import { CrawlerVisitsCharts } from './components/CrawlerVisitsCharts';
import { LatestVisitsAndPagesInfo } from './components/LatestVisitsAndPagesInfo';
import { HighlightedFeatureBanner } from './components/HighlightedFeaturesBanners';
import { DomainSearchDropdown } from '../../components/DomainSearchDropdown';
import { useGetDomainsQuery } from '../../features/domain-manager/api/domainManagerApiSlice';
import { isFree } from '@prerender/billing-shared';
import { debounce } from 'lodash';
import { UserRole } from '../PrerenderUser';

const items = [
  {
    label: 'Last 1 day',
    key: '1',
  },
  {
    label: 'Last 7 days',
    key: '7',
  },
  {
    label: 'Last 30 days',
    key: '30',
  },
];

function DashboardPage() {
  const prerenderUser = usePrerenderUser();
  const navigate = useNavigate();
  const isFreePlan = isFree(prerenderUser.chargebeePlanId);

  const trackingCodeInstalled = prerenderUser.trackingCodeInstalled;

  const { track } = useEvent();

  const [days, setTimeFilterValueInDays] = useState(7);
  const [selectedDomain, setSelectedDomain] = useState(null);
  const [searchValue, setSearchValue] = useState('');
  const [showDomainSearch, setShowDomainSearch] = useState(false);
  const {
    data: domains,
    error: domainsError,
    isFetching: isFetchingDomains,
  } = useGetDomainsQuery(searchValue, { refetchOnMountOrArgChange: true });

  const isMobile = useIsMobileView();

  const domainSelectRef = useRef(null);

  const isPrerenderUser = useIsPrerenderUser();
  const role = useUserRole();
  const isIntegrated = useHasTrackingCode();

  const domainsList = useMemo(() => {
    return domains?.domains;
  }, [domains]);

  const debouncedSearch = useCallback(
    debounce((value) => {
      setSearchValue(value);
    }, 500),
    [],
  );

  const showUserInterviewBanner = role !== UserRole.Guest && !isFreePlan && isIntegrated;

  const displayTeamMembersBanner = React.useMemo(() => {
    if (!prerenderUser.trackingCodeInstalledDate) return true;

    const daysFromTrackingCodeInstalled = Math.floor(
      (new Date() - new Date(prerenderUser.trackingCodeInstalledDate)) / (1000 * 60 * 60 * 24),
    );

    return daysFromTrackingCodeInstalled < 14;
  }, [prerenderUser.trackingCodeInstalledDate]);

  useEffect(() => {
    if (domainsError) {
      message.error('Failed to load domain statistics');
    }
  }, [domainsError]);

  const handleDomainSearch = (domain) => {
    setSelectedDomain(domain);
    track('Domain Selected', { domain: domain, subscription_plan: prerenderUser.chargebeePlanId });
  };

  useEffect(() => {
    if (domains?.domains?.length > 1 && !isFreePlan) {
      setShowDomainSearch(true);
    }
  }, [domains, isPrerenderUser]);

  return (
    <AdminTemplate metaTags={META_TAGS}>
      <Content>
        <Flex gap={16} vertical={true} style={{ marginBottom: '16px' }}>
          <Flex vertical={true} style={{ marginBottom: '16px' }}>
            <Flex justify={'space-between'} align={'center'}>
              <Title level={2} style={{ marginBottom: 8 }}>
                Dashboard
              </Title>
              <Flex gap={16}>
                {showDomainSearch && (
                  <DomainSearchDropdown
                    ref={domainSelectRef}
                    domains={domainsList}
                    performDomainSearch={handleDomainSearch}
                    selectedDomain={selectedDomain}
                    loading={isFetchingDomains}
                    onSearch={debouncedSearch}
                    onClear={() => {
                      setSearchValue('');
                    }}
                  />
                )}
                <Dropdown
                  disabled={!trackingCodeInstalled}
                  menu={{
                    defaultSelectedKeys: [`${days}`],
                    selectable: true,
                    items,
                    onSelect: ({ key }) => {
                      track('Dashboard Timeframe Selected', {
                        time_selected: 1 ? `${key} day` : `${key} days`,
                        subscription_plan: prerenderUser.planName,
                      });
                      setTimeFilterValueInDays(Number(key));
                    },
                  }}
                  placement="bottom"
                  arrow
                >
                  <Button disabled={!trackingCodeInstalled}>
                    <Space>
                      {days !== 1 ? `Last ${days} days` : `Last ${days} day`}
                      <DownOutlined />
                    </Space>
                  </Button>
                </Dropdown>
              </Flex>
            </Flex>
            <Text type="secondary">Track, manage and forecast your Prerender activity and usage.</Text>
            {showUserInterviewBanner && (
              <UserInterviewBanner userId={prerenderUser.id} subscriptionPlan={prerenderUser.chargebeePlanId} />
            )}
          </Flex>

          {/* not removed for now to switch back to the old onboarding */}

          {!trackingCodeInstalled && prerenderUser.role !== USER_ROLES.GUEST && (
            <Card>
              <HighlightedFeatureBanner
                imageUrl="/assets/img/onboarding/onboarding.svg"
                title="Complete Your Integration"
                description="Integrate your site to start seeing results."
                CTA={
                  <Button
                    type="primary"
                    onClick={() => {
                      track('Integration Started', { location: 'Dashboard' });
                      navigate('/integration-wizard');
                    }}
                  >
                    Integrate Prerender
                  </Button>
                }
              />
            </Card>
          )}
          <Flex justify={'space-between'} vertical={isMobile} gap={isMobile ? 10 : 0}>
            <DashboardUsageOverview days={days} domain={selectedDomain} />
            <DeliveredToCrawlerCharts
              trackingCodeInstalled={trackingCodeInstalled}
              days={days}
              domain={selectedDomain}
            />
          </Flex>
          <Flex justify={'space-between'}>
            <DeliveredByStatusCharts
              trackingCodeInstalled={trackingCodeInstalled}
              days={days}
              domain={selectedDomain}
            />
          </Flex>
          <Flex justify={'space-between'}>
            <CrawlerVisitsCharts trackingCodeInstalled={trackingCodeInstalled} days={days} domain={selectedDomain} />
          </Flex>
          <LatestVisitsAndPagesInfo />
        </Flex>
      </Content>
    </AdminTemplate>
  );
}

export default requireAuth(DashboardPage);

import { restApi } from '../../api/restApiSlice';
import { legacyAPIRequest } from '../../api/restApiSliceLegacy';

import VerificationResult from '../VerificationResult';

export const integrationWizardApiSlice = restApi.injectEndpoints({
  endpoints: (builder) => ({
    getDomain: builder.query({
      query: () => '/integration-wizard/domain',
    }),
    setDomain: builder.mutation({
      query: (domain) => ({
        url: '/integration-wizard/domain',
        method: 'POST',
        body: { domain },
      }),
      invalidatesTags: ['IntegrationDomain'],
    }),
    getDomainStats: builder.query({
      query: (domain) => `/integration-wizard/domain/${domain}/stats`,
    }),
    getDomainSitemaps: builder.query({
      query: (domain) => `/integration-wizard/domain/${domain}/sitemaps`,
    }),
    getPreview: builder.query({
      query: (domain) => `/integration-wizard/domain/${domain}/preview`,
    }),
    verifyDomain: builder.mutation<
      {
        validationResult: VerificationResult;
        url: string;
      },
      { url: string; integrationWizardId?: string }
    >({
      queryFn: async (body) => {
        const parameters = new URLSearchParams();
        parameters.append('url', body.url);
        if (body.integrationWizardId) {
          parameters.append('integrationWizardId', body.integrationWizardId);
        }

        try {
          const rawResult = await legacyAPIRequest({
            url: `v1/check-integration?${parameters.toString()}`,
            method: 'GET',
            signal: AbortSignal.timeout(2 * 60 * 1000),
          });
          if (!rawResult.ok) throw new Error('Failed to verify domain');
          const result = await rawResult.json();
          return {
            data: {
              validationResult: result,
              url: body.url,
            },
          };
        } catch (err) {
          const error = err as Error;
          return { error: { status: 'FETCH_ERROR', error: error.message } };
        }
      },
      invalidatesTags: ['domainStatistics', 'Domains'],
    }),
  }),
});

export const {
  useGetDomainQuery,
  useSetDomainMutation,
  useGetDomainStatsQuery,
  useGetDomainSitemapsQuery,
  useGetPreviewQuery,
  useVerifyDomainMutation,
} = integrationWizardApiSlice;

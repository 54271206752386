import {
  BillingPeriod,
  CacheLocation,
  ChargeBeePlan,
  ChargeBeePlanId,
  PlanName,
  Support,
} from '@prerender/billing-shared';

import Role from './Role';
import { FeatureFlag } from '../../hooks/useFeatureFlag';

type UserSettings = {
  emailPreferences: {
    send75Percent: boolean;
    send100Percent: boolean;
    sendSuggested: boolean;
    emailReportPeriod: 'WEEKLY';
    send429Alerts: boolean;
  };
  customViewport: {
    width: number;
    height: number;
  };
  customMobileViewport: {
    width: number;
    height: number;
  };
  pageLoadTimeout: number;
  loadImages: boolean;
  renderRegion: 'shared' | 'us' | 'eu';
  forceHTTPS: boolean;
  customDesktopUserAgent: string;
  customMobileUserAgent: string;
  manualReactivate: boolean;
  statusCodesToCache: string[];
};

export type PrerenderUserResponse = {
  adminEmail: string;
  allURLCount: number;
  amountOf429Renders: number;
  billingPeriod: BillingPeriod;
  cacheFreshness: number;
  cancelledAt: string | null;
  canStartTrial: boolean;
  chargeBeePlanId: ChargeBeePlan['chargeBeePlanId'];
  companyId: number;
  companyName: string;
  costPerMonth: number;
  csmId: string;
  customCachedPagesLimit: number | null;
  customPrice: number | null;
  customRecacheDelay: number | null;
  email: string;
  emailVerified: boolean;
  features: FeatureFlag[];
  firstName: string | null;
  gettingStartedSteps: {
    integrationWizardCompleted: boolean;
    queryParameters: boolean;
    reviewSitemaps: boolean;
    teamInvited: boolean;
  };
  hasBillingAddress: boolean;
  hasBillingInfo: boolean;
  hasBillingManager: boolean;
  hasCustomContractPricing: boolean;
  hadPaidPlan: boolean;
  id: number;
  ignoreAllQueryParams: boolean;
  isAdmin: boolean;
  isBetaTester: boolean;
  isCacheFreshnessDisabled: boolean;
  isMeteredBilling: boolean;
  isTrial: boolean;
  lastCharged: string;
  lastName: string | null;
  mobileAdaptive: boolean;
  nextBillingAt: string;
  numPagesCached: number;
  numberOfNewNotifications: number;
  plan: {
    name: ChargeBeePlan['name'];
    limit: number;
    cacheRefreshInterval: number[];
    estimate: {
      totalInCents: number;
      currencyCode: string;
      planPriceInCents: number;
      addonsPriceInCents: number;
      packagesPriceInCents: number;
    };
    suggested: { name: ChargeBeePlan['name'] } | null;
    current: {
      id: ChargeBeePlan['chargeBeePlanId'];
      name: ChargeBeePlan['name'];
      index: ChargeBeePlan['index'];
      costInCents: ChargeBeePlan['costInCents'];
      addonCostInCents: ChargeBeePlan['addonCostInCents'] | null;
      includedCaches: ChargeBeePlan['includedRenderedPages'] | null;
      cacheLimit: number | null;
      cacheRefreshInterval: ChargeBeePlan['cacheRefreshInterval'];
      cacheLocation: ChargeBeePlan['cacheLocation'] | null;
      support: ChargeBeePlan['support'] | null;
      apiAccess: ChargeBeePlan['apiAccess'];
    };
  };
  planName: ChargeBeePlan['name'];
  queryParamsWhitelist: boolean;
  recacheDelay: number;
  recachingDisabled: boolean;
  renderCounter: number;
  role: Role;
  settings: UserSettings;
  showRegistrationSteps: boolean;
  signupDate: string;
  token: string;
  trackingCodeInstalled: boolean;
  trackingCodeInstalledDate: string | null;
  trialEndsAt: string | null;
  upgradedAt: string | null;
  upgradedFromChargebeePlanId: ChargeBeePlan['chargeBeePlanId'] | null;
  userPausedRecaching: boolean;
};

export const defaultUserResponse: PrerenderUserResponse = {
  id: 1,
  companyId: 1,
  email: 'default-email@company.com',
  numPagesCached: 0,
  token: 'aaaaa',
  signupDate: '2024-05-03T10:15:29.634Z',
  trackingCodeInstalled: true,
  trackingCodeInstalledDate: '2024-09-10T12:23:39.448Z',
  cacheFreshness: 28,
  hasBillingInfo: false,
  recachingDisabled: false,
  planName: PlanName.Basic,
  costPerMonth: 0,
  emailVerified: true,
  customPrice: null,
  hasCustomContractPricing: false,
  customCachedPagesLimit: null,
  recacheDelay: 60000,
  userPausedRecaching: false,
  queryParamsWhitelist: true,
  ignoreAllQueryParams: false,
  hasBillingAddress: false,
  isMeteredBilling: true,
  gettingStartedSteps: {
    integrationWizardCompleted: true,
    queryParameters: false,
    reviewSitemaps: false,
    teamInvited: false,
  },
  plan: {
    name: PlanName.Basic,
    limit: 1000,
    cacheRefreshInterval: [3, 3],
    estimate: {
      totalInCents: 0,
      currencyCode: 'USD',
      planPriceInCents: 0,
      addonsPriceInCents: 0,
      packagesPriceInCents: 0,
    },
    suggested: null,
    current: {
      id: ChargeBeePlanId.Basic,
      name: PlanName.Basic,
      index: 0,
      cacheLimit: null,
      costInCents: 0,
      addonCostInCents: 0,
      includedCaches: 1000,
      cacheRefreshInterval: [3, 3],
      cacheLocation: CacheLocation.shared,
      support: Support.none,
      apiAccess: false,
    },
  },
  firstName: 'Firstname',
  lastName: null,
  cancelledAt: null,
  renderCounter: 0,
  chargeBeePlanId: ChargeBeePlanId.Basic,
  mobileAdaptive: true,
  settings: {
    emailPreferences: {
      send75Percent: true,
      send100Percent: true,
      sendSuggested: true,
      emailReportPeriod: 'WEEKLY',
      send429Alerts: true,
    },
    customViewport: {
      width: 1440,
      height: 718,
    },
    customMobileViewport: {
      width: 375,
      height: 667,
    },
    pageLoadTimeout: 20000,
    loadImages: false,
    renderRegion: 'shared',
    forceHTTPS: true,
    customDesktopUserAgent:
      'Mozilla/5.0 (X11; Linux x86_64) AppleWebKit/537.36 (KHTML, like Gecko) HeadlessChrome/89.0.4389.82 Safari/537.36 Prerender (+https://github.com/prerender/prerender)',
    customMobileUserAgent:
      'Mozilla/5.0 (Linux; Android 11; Pixel 5) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/89.0.4389.72 Mobile Safari/537.36 Prerender (+https://github.com/prerender/prerender)',
    manualReactivate: false,
    statusCodesToCache: [],
  },
  isBetaTester: false,
  features: [
    FeatureFlag.Oversight,
    FeatureFlag.DomainsDetails,
    FeatureFlag.DomainSchedulerSettings,
    FeatureFlag.SeoReport,
  ],
  allURLCount: 100,
  companyName: 'default-email@company.com',
  adminEmail: 'default-email@company.com',
  customRecacheDelay: null,
  isTrial: false,
  isCacheFreshnessDisabled: false,
  nextBillingAt: '2024-06-03T10:15:29.634Z',
  lastCharged: '2024-05-03T10:15:29.634Z',
  upgradedFromChargebeePlanId: null,
  upgradedAt: null,
  showRegistrationSteps: false,
  isAdmin: true,
  role: Role.SuperAdmin,
  billingPeriod: BillingPeriod.monthly,
  hadPaidPlan: false,
  csmId: 'aaaaa',
  numberOfNewNotifications: 3,
  hasBillingManager: false,
  canStartTrial: false,
  trialEndsAt: null,
  amountOf429Renders: 0,
};

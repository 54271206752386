import { prerenderHtml } from './prerender';
import { fastlyHtml } from './fastly';
import { nodeHtml } from './node';
import { nginxHtml } from './nginx';
import { apacheHtml } from './apache';
import { magento2Html } from './magento-2';
import { rubyHtml } from './ruby';
import { nextJsHtml } from './NextJS';
import { iisHtml } from './iis';
import { otherHtml } from './other';
import { cloudflareHtml } from './cloudflare';

const DOCS = {
  prerender: {
    html: prerenderHtml,
  },
  cloudflare: {
    html: cloudflareHtml,
  },
  fastly: {
    html: fastlyHtml,
  },
  node: {
    html: nodeHtml,
  },
  nginx: {
    html: nginxHtml,
  },
  apache: {
    html: apacheHtml,
  },
  'magento-2': {
    html: magento2Html,
  },
  ruby: {
    html: rubyHtml,
  },
  NextJS: {
    html: nextJsHtml,
  },
  iis: {
    html: iisHtml,
  },
  other: {
    html: otherHtml,
  },
};

export function getDocument(integrationType) {
  return DOCS[integrationType];
}

import React from 'react';
import { Table, ConfigProvider, Empty } from 'antd';
import { EditOutlined } from '@ant-design/icons';

import ButtonWithRole from '../../../components/ButtonWithRole';
import { UserRole } from '../../PrerenderUser';
import { useUserRole } from '../../../hooks/usePrerenderUser';
import URLParameter from '../URLParametersTab/URLParameter';

const getColumns = (onEditClicked: (rowIndex: number) => void) => [
  {
    title: 'Parameter',
    key: 'value',
    dataIndex: 'value',
    width: '99%',
  },
  {
    title: 'Rule',
    key: 'action',
    dataIndex: 'ignore',
    className: 'col-min',
    render: (value: boolean) => (value ? 'Ignore when caching' : 'Use for caching'),
  },
  {
    title: 'Edit',
    key: 'edit',
    width: '1%',
    render: (_text: string, _record: {}, index: number) => (
      <ButtonWithRole
        disabledFor={[UserRole.BillingManager, UserRole.Guest]}
        type="text"
        onClick={() => onEditClicked(index)}
        icon={<EditOutlined />}
      >
        {''}
      </ButtonWithRole>
    ),
  },
];

type UrlParametersSettingsTableProps = {
  isLoading?: boolean;
  items?: Array<URLParameter>;
  hideIgnoreWhenCachingParams?: boolean;
  hideUseForCachingParams?: boolean;
  onRowEditClick: (item: URLParameter) => void;
  selectedRows: URLParameter[];
  onSelectedRowsChange: (selectedRows: URLParameter[]) => void;
};

function UrlParametersSettingsTable({
  isLoading = false,
  items = [],
  hideIgnoreWhenCachingParams,
  hideUseForCachingParams,
  onRowEditClick,
  selectedRows,
  onSelectedRowsChange,
}: UrlParametersSettingsTableProps) {
  const userRole = useUserRole();

  const getRowClassName = (row: URLParameter) => {
    if (row.ignore && hideIgnoreWhenCachingParams) return 'text-muted';

    if (!row.ignore && hideUseForCachingParams) return 'text-muted';

    return '';
  };

  const onEditClick = (index: number) => onRowEditClick(items[index]);

  return (
    <ConfigProvider
      renderEmpty={() => (
        <Empty
          image={Empty.PRESENTED_IMAGE_SIMPLE}
          description="Help Prerender cache your site more efficiently by indicating how we should handle query parameters after the ? in your URLs"
        />
      )}
    >
      <Table
        bordered={!!items.length}
        rowKey="id"
        loading={isLoading}
        rowSelection={{
          type: 'checkbox',
          onChange: (_ids, rows) => onSelectedRowsChange(rows),
          selectedRowKeys: selectedRows.map((row) => row.id),
          getCheckboxProps: (_record) => ({
            disabled: userRole === UserRole.BillingManager || userRole === UserRole.Guest,
          }),
        }}
        columns={getColumns(onEditClick)}
        dataSource={items}
        pagination={false}
        rowClassName={getRowClassName}
        showHeader={!!items.length}
        scroll={{ x: true }}
      />
    </ConfigProvider>
  );
}

export default UrlParametersSettingsTable;

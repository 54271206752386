import React from 'react';
import { Typography } from 'antd';

import { NotificationType } from '../Notification';
import NewUpdate from './NewUpdateNotification';
import RenderLimit75 from './RenderLimit75Notification';
import RenderLimit100 from './RenderLimit100Notification';
import PageReport from './PageReportNotification';
import BetterPlan from './BetterPlanNotification';

function NotificationBody({ notification = {} }) {
  switch (notification.type) {
    case NotificationType.Update:
      return <NewUpdate notification={notification} />;

    case NotificationType.PageReport:
      return <PageReport notification={notification} />;

    case NotificationType.RenderLimit75:
      return <RenderLimit75 notification={notification} />;

    case NotificationType.RenderLimit100:
      return <RenderLimit100 notification={notification} />;

    case NotificationType.BetterPlanFound:
      return <BetterPlan notification={notification} />;

    default:
      return <Typography.Paragraph>{notification.description}</Typography.Paragraph>;
  }
}

export default React.memo(NotificationBody);

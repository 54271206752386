/**
 * Create react HTML from content
 * Content object:
 * {
 *   type: "text" | "link"
 *   text: "content text",
 *   link: "link href"
 * }
 */

import { HubSpot } from '../features/CustomerSupport';

export const createHtmlContentFromCmText = (content, index, linkClassName = 'link-success', linkTarget = '_blank') => {
  const renderAction = () => {
    switch (content.action) {
      case 'openContactForm':
        return (
          <a
            href="#/"
            className={linkClassName}
            key={index}
            onClick={(e) => {
              e.preventDefault();
              HubSpot.openHubSpotForm();
            }}
          >
            {content.text}
          </a>
        );
      default:
        return null;
    }
  };

  switch (content.type) {
    case 'html':
      return (
        <span key={index}>
          {content.elements.map((e) => {
            if (e.span) {
              return <span>{e.span}</span>;
            } else if (e.list) {
              return (
                <ul>
                  {e.list.map((item) => (
                    <li>{item}</li>
                  ))}
                </ul>
              );
            }
          })}
        </span>
      );
    case 'text':
      return <span key={index}>{content.text}</span>;
    case 'link':
      return (
        <a key={index} href={content.link} className={linkClassName} target={linkTarget} rel="noreferrer">
          {content.text}
        </a>
      );
    case 'action':
      return renderAction();
    default:
      return null;
  }
};

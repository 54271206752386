import React from 'react';
import { Link } from 'react-router-dom';
import { Dropdown, Flex, Typography, Button } from 'antd';
import { usePrerenderUser } from '../../hooks/usePrerenderUser';
import { AppUserRoles } from '../../features/teams';
import { TOOLTIP_ROOT_ID } from '../../components/Tooltip';

const MENU_ITEMS = [
  {
    key: 'my-account',
    label: <Link to="/account">My Account</Link>,
  },
  {
    key: 'billing',
    label: <Link to="/billing">Billing</Link>,
  },
  {
    type: 'divider',
  },
  {
    key: 'sign-out',
    label: <Link to="/signout">Sign Out</Link>,
  },
];

function UserInfos({ user }) {
  const hasName = user.firstName || user.lastName;
  const hasCompanyName = !!user.companyName && user.companyName !== user.email;

  let mainText;
  const lastName = user.lastName ? ` ${user.lastName}` : '';
  if (hasName && hasCompanyName) {
    mainText = `${user.firstName || ''}${lastName} (${user.companyName})`;
  } else if (hasName) {
    mainText = `${user.firstName || ''}${lastName}`;
  } else if (hasCompanyName) {
    mainText = `(${user.companyName})`;
  } else {
    mainText = user.email;
  }

  const truncateText = (text, maxLength = 18) => {
    return text.length > maxLength ? `${text.slice(0, maxLength)}...` : text;
  };

  const truncatedText = truncateText(mainText);

  return (
    <Flex vertical>
      <Typography.Text
        style={{ textAlign: 'right' }}
        data-tooltip-id={TOOLTIP_ROOT_ID}
        data-tooltip-content={mainText !== truncatedText ? mainText : null}
        data-tooltip-place="bottom"
      >
        {truncatedText}
      </Typography.Text>
      <Typography.Text type="secondary" style={{ textAlign: 'right' }}>
        {AppUserRoles[user.role]}
      </Typography.Text>
    </Flex>
  );
}

function AccountDropdown() {
  const prerenderUser = usePrerenderUser();

  return (
    <Dropdown
      menu={{
        items: MENU_ITEMS,
      }}
      trigger={['click']}
    >
      <Button type="text" style={{ padding: 6, alignContent: 'right' }} size="large">
        <UserInfos user={prerenderUser} />
      </Button>
    </Dropdown>
  );
}

export default AccountDropdown;

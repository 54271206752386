import { useState } from 'react';
import { Flex, Typography, Layout, Form, Button } from 'antd';

import BottomActionsContainer from '../../BottomActionsContainer';
import { useInviteUserMutation } from '../../../../api/appUserApiSlice';
import InviteTeamMembersForm from './InviteTeamMembersForm';
import { useEvent } from '../../../../events/hooks/useEvent';
import { useCharbeePlanId } from '../../../../../hooks/usePrerenderUser';

const { Title, Text } = Typography;
const { Content } = Layout;

const InviteTeamStep = ({ onGoNext }) => {
  const [disabledNext, setDisabledNext] = useState(true);
  const [addedUsers, setAddedUsers] = useState([]);
  const [userStatuses, setUserStatuses] = useState({});
  const [inviteUser, inviteUserResult] = useInviteUserMutation();
  const [form] = Form.useForm();
  const { track } = useEvent();
  const subscriptionPlan = useCharbeePlanId();

  const onGoNextClick = () => {
    track('Invite team step: Remind me later clicked', { subscription_plan: subscriptionPlan });
    onGoNext();
  };

  const handleSubmit = async (values) => {
    const usersToInvite = values.users.map((user) => ({
      email: user.email,
      role: user.role,
    }));

    let statuses = { ...userStatuses };
    let allSuccess = true;

    for (let [index, user] of usersToInvite.entries()) {
      try {
        await inviteUser(user).unwrap();
        statuses[user.email] = 'success';
      } catch (error) {
        statuses[user.email] = 'error';
        allSuccess = false;
        form.setFields([
          {
            name: ['users', index, 'email'],
            errors: [error.error ? error.error : 'An unexpected error occured, please try again'],
          },
        ]);
      }
    }
    setUserStatuses(statuses);

    if (allSuccess) {
      track('Invite team step: Team members invited');
      onGoNext();
    }
  };

  return (
    <Flex vertical gap="large" style={{ height: '100%' }}>
      <Flex align="center" style={{ maxWidth: 560, margin: '0 auto', marginBottom: 32 }}>
        <Content>
          <Flex vertical align="center">
            <Title level={3}>Invite your team</Title>
            <Text>
              Bring on developers, billing managers, marketers, and more. Add unlimited users at no extra costs.
            </Text>
          </Flex>
          <InviteTeamMembersForm
            form={form}
            onFinish={handleSubmit}
            setAddedUsers={setAddedUsers}
            onInputChange={() => setDisabledNext(false)}
            userStatuses={userStatuses}
            addedUsers={addedUsers}
          />
        </Content>
      </Flex>
      <BottomActionsContainer>
        <Flex gap="small">
          <Button type="primary" onClick={form.submit} disabled={disabledNext} loading={inviteUserResult.isLoading}>
            Invite team members
          </Button>
          <Button type="text" onClick={onGoNextClick}>
            Remind me later
          </Button>
        </Flex>
      </BottomActionsContainer>
    </Flex>
  );
};

export default InviteTeamStep;

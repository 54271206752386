import { http, HttpResponse } from 'msw';
import { faker } from '@faker-js/faker';

const getRecacheStates = http.get('http://localhost:3030/v3/renders/recache-stats', () => {
  return HttpResponse.json([
    {
      domain: 'mmrf.my.site.com',
      render_time: 4442,
      status_code: 200,
      time: 1736869080000,
      url: 'https://mmrf.my.site.com/eventcheckin/s/campaignmember/00v4100000S6F37AAF/jacquelyn-jones',
      user_id: 123534,
      timedout: 0,
      adaptive_type: 'desktop',
      source: 'cdn',
    },
    {
      domain: 'mmrf.my.site.com',
      render_time: 4712,
      status_code: 200,
      time: 1736869080000,
      url: 'https://mmrf.my.site.com/eventcheckin/s/campaignmember/00v4100000S6KiBAAV/berit-griffin',
      user_id: 123534,
      timedout: 0,
      adaptive_type: 'desktop',
      source: 'manual',
    },
    {
      domain: 'mmrf.my.site.com',
      render_time: 4651,
      status_code: 200,
      time: 1736869079000,
      url: 'https://mmrf.my.site.com/eventcheckin/s/campaignmember/00v4100000S6KfaAAF/jenna-mcnab',
      user_id: 123534,
      timedout: 0,
      adaptive_type: 'desktop',
      source: 'automated',
    },
    {
      domain: 'tv-sound-monitors.philips.com',
      render_time: 4932,
      status_code: 200,
      time: 1736869079000,
      url: 'https://tv-sound-monitors.philips.com/s/product/l393/01t68000000QKtLAAW?language=no',
      user_id: 123534,
      timedout: 0,
      adaptive_type: 'desktop',
      source: 'cdn',
    },
    ...Array.from({ length: 50 }, (_, index) => ({
      domain: faker.internet.domainName(),
      render_time: faker.number.int({ min: 4000, max: 10000 }),
      status_code: faker.internet.httpStatusCode(),
      time: faker.date.recent(),
      url: faker.internet.url(),
      user_id: 1,
      timedout: faker.datatype.boolean(),
      adaptive_type: faker.helpers.arrayElement(['desktop', 'mobile']),
      source: faker.helpers.arrayElement(['manual', 'automated', 'cdn']),
    })),
  ]);
});

const getRecache500Stats = http.get('http://localhost:8443/api/recache-500-stats', () => {
  return HttpResponse.json([
    {
      time: 1736866800000,
      number: 2150,
    },
    {
      time: 1736863200000,
      number: 2810,
    },
    {
      time: 1736859600000,
      number: 2908,
    },
    {
      time: 1736856000000,
      number: 2996,
    },
    {
      time: 1736852400000,
      number: 2990,
    },
    {
      time: 1736848800000,
      number: 2813,
    },
    {
      time: 1736845200000,
      number: 2931,
    },
    {
      time: 1736841600000,
      number: 3019,
    },
    ...Array.from({ length: 30 }, (_, index) => ({
      time: faker.date.recent(),
      number: faker.number.int({ min: 2000, max: 4000 }),
    })),
  ]);
});

const getReacheOverviewStats = http.get('http://localhost:8443/api/recache-overview-stats', () => {
  return HttpResponse.json([
    {
      time: 1736866800000,
      number: 487734,
    },
    {
      time: 1736863200000,
      number: 716092,
    },
    {
      time: 1736859600000,
      number: 756160,
    },
    {
      time: 1736856000000,
      number: 838149,
    },
    {
      time: 1736852400000,
      number: 879040,
    },
    {
      time: 1736848800000,
      number: 884257,
    },
    {
      time: 1736845200000,
      number: 887221,
    },
    {
      time: 1736841600000,
      number: 886986,
    },
    {
      time: 1736838000000,
      number: 852810,
    },
    ...Array.from({ length: 30 }, (_, index) => ({
      time: faker.date.recent(),
      number: faker.number.int({ min: 300000, max: 800000 }),
    })),
  ]);
});
export default [getRecacheStates, getRecache500Stats, getReacheOverviewStats];

import React, { useCallback, useEffect, useState } from 'react';
import { message } from 'antd';
import { Container } from '../../../components/CssFrameworkComponents';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { ChargeBeePlanId } from '@prerender/billing-shared';

import { BillingChangePlanConfirmModal } from './BillingChangePlanConfirmModal';
import BillingPlansCard from './BillingPlansCard';
import XIcon from '../../../assets/icons/x-icon.svg';
import { initChargebee } from '../../../chargebee/chargebee';
import { useChangePlanMutation } from '../api/billingApiSlice';
import { useGetPlansQuery } from '../api/billingApiSlice';
import { getPrerenderUser } from '../../../actions/prerenderUserActions';
import { useEvent } from '../../events/hooks/useEvent';
import { usePrerenderUser } from '../../../hooks/usePrerenderUser';
import SpinningWheel from '../../../components/SpinningWheel';
import { UserSnap } from '../../CustomerSupport';
import InsufficientRendersDisclaimer from './InsufficientRendersDisclaimer';
import HighlightedCard from './HighlightedCard';

export function calcInsufficientRenders(currentRenders, newPlan) {
  return newPlan.id !== ChargeBeePlanId.Basic && currentRenders >= newPlan.includedCaches;
}

const BillingPlans = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const [messageApi, contextHolder] = message.useMessage();

  const [showPlansOverlay, setShowPlansOverlay] = useState(false);
  const [pendingNewPlan, setPendingNewPlan] = useState(null);
  const [showInsufficientRendersModal, setShowInsufficientRendersModal] = useState(false);
  const [showChangePlanConfirmModal, setShowChangePlanConfirmModal] = useState(false);
  const billingInfoContentData = useSelector((state) => state.page.contentData.BillingInfo);

  const user = usePrerenderUser();
  const isUserOnCustomPlan = typeof user.customPrice === 'number';

  const {
    data: plansListResponse,
    isLoading: isLoadingPlans,
    isError: isErrorLoadingPlans,
    refetch: reFetchPlans,
  } = useGetPlansQuery(null, { refetchOnMountOrArgChange: true });
  const [changePlan, { isLoading: isUpdatingPlans }] = useChangePlanMutation();

  const { track } = useEvent();
  const { openChurnQuestionnaire } = UserSnap.useUserSnap();

  const searchParams = new URLSearchParams(location.search);
  const source = searchParams.get('utm_source');

  const plansList = plansListResponse?.data;
  const currentPlan = plansList?.find((plansEntry) => plansEntry.current);

  const currentRenders = React.useMemo(
    () => Math.floor(user.numPagesCached * (30 / user.cacheFreshness)),
    [user.numPagesCached, user.cacheFreshness]
  );

  const onPlanClicked = (newPlan) => {
    const hasInsufficientRenders = calcInsufficientRenders(currentRenders, newPlan);

    setPendingNewPlan(newPlan);
    if (!hasInsufficientRenders) {
      handleSelectPlan(newPlan);
    } else {
      setShowInsufficientRendersModal(true);
    }
  };

  const onPlanUpdateConfirm = () => {
    setShowInsufficientRendersModal(false);
    handleSelectPlan(pendingNewPlan);
  };

  const onPlanUpdateCancel = () => {
    setShowInsufficientRendersModal(false);
    setPendingNewPlan(null);
  };

  const handleSelectPlan = useCallback(
    (newPlan) => {
      track('Subscription Plan Selected', {
        subscription_plan: currentPlan.name,
        selected_subscription_plan: newPlan.name,
        selected_subscription_price: newPlan.costInCents / 100,
        source: source,
      });

      if (isUserOnCustomPlan) {
        setShowPlansOverlay(true);
      } else {
        setShowChangePlanConfirmModal(true);
      }
    },
    [isUserOnCustomPlan, plansList, currentPlan]
  );

  const handleConfirmPlanChange = useCallback(
    async (planId) => {
      if (planId === 'basic-001') {
        openChurnQuestionnaire();
      }
      try {
        await changePlan({ planId }).unwrap();
        reFetchPlans();
        dispatch(getPrerenderUser);
      } catch (error) {
        console.error(error);
        messageApi.open({
          type: 'error',
          content: 'Unable to change plan. Please contact support@prerender.io.',
        });
      }
      setShowChangePlanConfirmModal(false);
    },
    [dispatch, user, openChurnQuestionnaire]
  );

  const handleCancelPlanChange = useCallback(() => {
    setShowChangePlanConfirmModal(false);
    setPendingNewPlan(null);
    track('Subscription Update Flow Cancelled', { subscription_plan: currentPlan.name, source: source });
  }, [currentPlan]);

  useEffect(() => {
    const url = window.location.href;
    initChargebee(billingInfoContentData.chargeBee.site, billingInfoContentData.chargeBee.domain, url);
  }, [billingInfoContentData.chargeBee]);

  if (!plansList) {
    return <SpinningWheel matchParent />;
  }

  return (
    <Container fluid addClass={'p-0'}>
      <InsufficientRendersDisclaimer
        newPlan={showInsufficientRendersModal ? pendingNewPlan : undefined}
        onConfirm={onPlanUpdateConfirm}
        onCancel={onPlanUpdateCancel}
        isDowngrading={pendingNewPlan?.index < currentPlan.index}
      />
      {contextHolder}
      {showChangePlanConfirmModal && pendingNewPlan ? (
        <>
          <h3 className="">Confirm Your Plan</h3>
          {isLoadingPlans || isUpdatingPlans ? (
            <div
              style={{ height: '300px', maxWidth: '600px' }}
              className="d-flex align-items-center justify-content-center"
            >
              <div className="spinner-border text-primary" role="status"></div>
            </div>
          ) : (
            <BillingChangePlanConfirmModal
              pendingNewPlan={pendingNewPlan}
              currentPlanIndex={currentPlan.index}
              onConfirmPlanChange={handleConfirmPlanChange}
              onClose={handleCancelPlanChange}
            />
          )}
        </>
      ) : (
        <>
          <div className="d-flex justify-content-between">
            <h3 className="">Prerender Plans</h3>
            {(isLoadingPlans || isUpdatingPlans) && (
              <div className="ml-3 spinner-border spinner-border-sm text-primary" role="status"></div>
            )}
          </div>
          <p className="text-base text-grey">Select an account plan that fits your needs.</p>
          <div className="position-relative">
            {showPlansOverlay && (
              <div className="plans-overlay">
                <div className="plans-overlay-content">
                  <div className="d-flex justify-content-end">
                    <button className="btn p-0" onClick={() => setShowPlansOverlay(false)}>
                      <img src={XIcon} />
                    </button>
                  </div>
                  <p className="font-weight-bold text-lg">You're on a Custom plan!</p>
                  <span>
                    Your account runs on a custom plan, so you are not able to change your plan via this page. If you
                    want to re-negotiate your plan, please send us an email at
                  </span>{' '}
                  <a className="font-weight-bold" href="mailto:billing@prerender.io">
                    billing@prerender.io
                  </a>
                  <span>.</span>
                </div>
              </div>
            )}
            {isErrorLoadingPlans ? (
              <p style={{ color: 'red' }}>
                An unexpected error occurred when retrieving the plans. Please try again by reloading the page.
              </p>
            ) : (
              <div className="plans-grid">
                {plansList?.length > 0 &&
                  plansList
                    .filter((plan) => plan.available)
                    .map((plan) => (
                      <HighlightedCard
                        key={plan.name}
                        betterPlan={location.state?.suggestedChargebeePlanId === plan.id}
                        recommendedPlan={plan.suggested}
                      >
                        <BillingPlansCard
                          plan={plan}
                          currentPlanIndex={currentPlan ? currentPlan.index : -1}
                          onPlanClicked={onPlanClicked}
                        />
                      </HighlightedCard>
                    ))}
              </div>
            )}
          </div>
        </>
      )}
    </Container>
  );
};

export default BillingPlans;

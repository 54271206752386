import { Card, Col, Row, Spin, Table, Tooltip, Typography } from 'antd';
import { statusCodeBadgeColor } from '../../assets/activityPageLib';
import { getAgeString } from '../../assets/time';
import React from 'react';
import { ResponseDropdownFilter } from '../../components/dropdownFilters/ResponseDropdownFilter';
import { StatusDropdownFilter } from '../../components/dropdownFilters/StatusDropdownFilter';
import { LoadingOutlined } from '@ant-design/icons';
import { RenderedTimeDropdown } from '../../components/dropdownFilters/RenderedTimeDropdown';
import { UserAgentDropdownFilter } from '../../components/dropdownFilters/UserAgentDropdownFilter';
import Column from 'antd/lib/table/Column';
import { usePrerenderUser } from '../../hooks/usePrerenderUser';
import { useEvent } from '../events/hooks/useEvent';
import { omitBy, isNull } from 'lodash';
import { createFilterEventProperties } from '../../utils/createFilterEventProperties';
import { UserAgentLogo } from '../UserAgents';

const adaptiveTypeOptions = [
  {
    text: 'Desktop',
    value: 'desktop',
  },
  {
    text: 'Mobile',
    value: 'mobile',
  },
];

const cacheHitOptions = [
  {
    text: 'Cache Hit',
    value: '1',
  },
  {
    text: 'Cache Miss',
    value: '0',
  },
];

const dataIndexToSortQuertMapping = {
  userAgent: 'user_agent',
  statusCode: 'status_code',
  renderTime: 'render_time',
  time: 'time',
};

const mapDataIndexToSortQueryParam = (dataIndex) => {
  if (dataIndexToSortQuertMapping[dataIndex]) {
    return dataIndexToSortQuertMapping[dataIndex];
  }
  throw new Error(`Mapping is missing for dataIndex: ${dataIndex}`);
};

const mapToApiPayload = (filters, sorter) => {
  const payload = {
    userAgent: filters.userAgent?.[0]?.selectedUserAgents?.join('|') || null,
    statusCodeEq: filters.statusCode?.[0]?.statusCodeEq || null,
    statusCodeLow: filters.statusCode?.length && filters.statusCode[0].statusCodeLow,
    statusCodeHigh: filters.statusCode?.length && filters.statusCode[0].statusCodeHigh,
    responseTimeLow: filters.renderTime?.length && filters.renderTime[0].responseTimeLow,
    responseTimeHigh: filters.renderTime?.length && filters.renderTime[0].responseTimeHigh,
    timedout: filters.renderTime?.length && filters.renderTime[0].timedout,
    renderedTimeLow: filters.time?.length && filters.time[0].renderedTimeLow,
    renderedTimeHigh: filters.time?.length && filters.time[0].renderedTimeHigh,
    adaptive_type: filters.adaptive_type?.join(',') || null,
    userAgentCaseSensitiveSearch: !!filters.userAgent?.[0]?.caseSensitiveSearch,
    q: filters.q,
    qCondition: filters.qCondition,
    cacheHit: filters.cacheHit,
  };
  if (sorter?.column?.dataIndex) {
    payload.sort = mapDataIndexToSortQueryParam(sorter.column.dataIndex);
    payload.sortDirection = sorter.order === 'ascend' ? 'ASC' : 'DESC';
  }
  return payload;
};

const mapToTrackingProperties = (filterResult) => {
  const { renderedAt, adaptive_type, statusCode, renderTime, userAgent, time, cacheHit } = omitBy(filterResult, isNull);
  return {
    selected_bots: userAgent?.[0]?.selectedUserAgents,
    rendered_at: renderedAt?.[0] && `${renderedAt[0].renderedTimeLow}-${renderedAt[0].renderedTimeHigh}`,
    selected_device: adaptive_type,
    selected_status:
      statusCode?.[0] &&
      (statusCode[0].statusCodeEq || `${statusCode[0].statusCodeLow}-${statusCode[0]?.statusCodeHigh}`),
    response_time: renderTime?.[0] && `${renderTime[0].responseTimeLow}-${renderTime[0].responseTimeHigh}`,
    timedout: renderTime?.[0]?.timedout,
    processed_status: time?.[0] && `${time[0].renderedTimeLow}-${time[0].renderedTimeHigh}`,
    cacheHit: cacheHit ? cacheHit.join(',') : '',
  };
};

export const CrawlerActivityTable = ({ initialFilters = {}, renderSearchPanel, dataSource, onChangeTable }) => {
  const { track } = useEvent();
  const user = usePrerenderUser();
  const onChange = (pagination, filters, sorter) => {
    const selectedFiltersToTrack = createFilterEventProperties(filters, {}, mapToTrackingProperties);

    onChangeTable({
      action: 'updateSearchParams',
      payload: mapToApiPayload(filters, sorter),
    });

    if (Object.keys(selectedFiltersToTrack).length !== 0) {
      track(`CDN URL Filtered: ${Object.keys(selectedFiltersToTrack)}`, {
        subscription_plan: user.chargebeePlanId,
        ...selectedFiltersToTrack,
      });
    }
  };

  const mapCrawlerActivityData = (data) => {
    return data.map((item, key) => {
      return {
        key: key,
        userAgent: <UserAgentLogo userAgent={item.user_agent} />,
        statusCode: (
          <span className={`ml-2 badge ${statusCodeBadgeColor(item)}`} style={{ fontSize: '90%', fontWeight: '500' }}>
            {item.status_code}
          </span>
        ),
        cacheHit: (
          <span
            className={`badge ${item.cache_hit === 1 ? 'ml-2 bg-soft-primary' : 'ml-1 bg-warning'}`}
            style={{ fontSize: '90%', fontWeight: '500' }}
          >
            {item.cache_hit === 1 ? 'Cache Hit' : 'Cache Miss'}
          </span>
        ),
        renderTime: item.timedout ? (
          <a
            href="https://docs.prerender.io/docs/25-empty-or-partially-rendered-pages"
            target="_blank"
            rel="noreferrer"
          >
            <div className="badge bg-danger" style={{ fontSize: '90%', fontWeight: '500' }}>
              <div>{item.render_time} ms</div>
              <div>Timed out</div>
            </div>
          </a>
        ) : (
          <span>{item.render_time} ms</span>
        ),
        url: item.url,
        adaptive_type: (
          <Tooltip
            title={`Rendering was optimized for [${item?.adaptive_type === 'mobile' ? 'Mobile' : 'Desktop'}] crawlers`}
            placement="left"
          >
            <i
              data-tip={item?.adaptive_type === 'mobile' ? 'Mobile' : 'Desktop'}
              className={item?.adaptive_type === 'mobile' ? 'fe fe-smartphone' : 'fe fe-monitor'}
            />
          </Tooltip>
        ),
        time: `${getAgeString(new Date(item.time), 'ago', 'long')}`,
      };
    });
  };

  if (!dataSource) return <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} />;

  const {
    renderedTimeHigh,
    renderedTimeLow,
    statusCodeEq,
    statusCodeHigh,
    statusCodeLow,
    responseTimeHigh,
    responseTimeLow,
    timedout,
  } = initialFilters;
  const time = renderedTimeHigh || renderedTimeLow ? [{ renderedTimeHigh, renderedTimeLow }] : [];
  const statusCode =
    statusCodeEq || statusCodeHigh || statusCodeLow ? [{ statusCodeEq, statusCodeHigh, statusCodeLow }] : null;
  const responseTime =
    responseTimeHigh || responseTimeLow || timedout ? [{ responseTimeHigh, responseTimeLow, timedout }] : null;
  const adaptiveType = initialFilters.adaptive_type ? initialFilters.adaptive_type.split(',') : null;

  return (
    <Card styles={{ body: { padding: 12 } }}>
      <Row className="pb-3 align-items-baseline">
        <Col flex="auto">{renderSearchPanel()}</Col>
      </Row>
      <Table
        size="middle"
        dataSource={mapCrawlerActivityData(dataSource.requests)}
        onChange={onChange}
        loading={dataSource.inProgress}
        pagination={false}
        scroll={{ x: 1100 }}
        sticky={{ offsetHeader: 0, offsetScroll: 10 }}
        bordered
      >
        <Column
          title="Bot"
          dataIndex="userAgent"
          width="8%"
          align="center"
          sorter="true"
          filteredValue={initialFilters.userAgent?.split('|') || null}
          filterDropdown={({ confirm, setSelectedKeys, selectedKeys, visible }) => (
            <UserAgentDropdownFilter
              email={user.email}
              close={close}
              confirm={confirm}
              selectedKeys={selectedKeys}
              setSelectedKeys={setSelectedKeys}
              isVisible={visible}
            />
          )}
        />
        <Column
          title="Status"
          dataIndex="statusCode"
          align="center"
          width="15%"
          sorter="true"
          filteredValue={statusCode}
          filterDropdown={({ confirm, setSelectedKeys, selectedKeys, clearFilters }) => (
            <StatusDropdownFilter
              confirm={confirm}
              selectedKeys={selectedKeys}
              setSelectedKeys={setSelectedKeys}
              clearFilters={clearFilters}
            />
          )}
        />
        <Column
          title="Cached"
          dataIndex="cacheHit"
          align="center"
          width="12%"
          sorter="true"
          filteredValue={initialFilters.cacheHit || null}
          filters={cacheHitOptions}
        />
        <Column
          title="Response"
          dataIndex="renderTime"
          width="12%"
          align="center"
          sorter="true"
          filteredValue={responseTime}
          filterDropdown={({ confirm, setSelectedKeys, selectedKeys, clearFilters }) => (
            <ResponseDropdownFilter
              confirm={confirm}
              selectedKeys={selectedKeys}
              setSelectedKeys={setSelectedKeys}
              clearFilters={clearFilters}
            />
          )}
        />
        <Column
          title="URL"
          dataIndex="url"
          render={(url) => (
            <Typography.Link href={url} target="_blank" rel="noreferrer">
              {url}
            </Typography.Link>
          )}
        />
        <Column
          title="Device"
          dataIndex="adaptive_type"
          align="center"
          filteredValue={adaptiveType || null}
          filters={adaptiveTypeOptions}
          width="8%"
        />
        <Column
          title="Processed"
          dataIndex="time"
          sorter="true"
          defaultSortOrder="descend"
          filteredValue={time}
          filterDropdown={({ confirm, setSelectedKeys, selectedKeys, clearFilters }) => {
            return (
              <RenderedTimeDropdown
                confirm={confirm}
                selectedKeys={selectedKeys}
                setSelectedKeys={setSelectedKeys}
                clearFilters={clearFilters}
                label="Processed at (in hours)"
              />
            );
          }}
          width="12%"
        />
      </Table>
    </Card>
  );
};

// define action creators that dispatch actions:
import config from '../assets/config';
import internalApi from '../assets/internalApi';
import { getLocalErrorCode } from '../assets/lib';

import { PrerenderUserAction } from '../features/PrerenderUser';
import {
  SET_LASTERRORCODE,
  CLEAR_LASTERRORCODE,
  OPEN_MODAL,
  CLOSE_MODAL,
  CONTENT_LOADING_IN_PROGRESS,
  CONTENT_SET,
  SET_MODAL_HEIGHT,
} from './types';

const authErrors = ['E0270', 'E0006', 'E0005'];

export function setLastErrorCode(errorCode) {
  return (dispatch) => {
    dispatch({ type: SET_LASTERRORCODE, payload: errorCode });
    if (authErrors.indexOf(errorCode) !== -1) {
      dispatch({ type: PrerenderUserAction.UserAnauthorized });
    }
  };
}

export function clearLastErrorCode() {
  return { type: CLEAR_LASTERRORCODE };
}

export function openModal(id, content) {
  return { type: OPEN_MODAL, payload: { id, content } };
}

export function reportModalHeight(modalHeight) {
  return { type: SET_MODAL_HEIGHT, payload: modalHeight };
}

export const closeModal =
  (browserHistory = null, target = null) =>
  (dispatch) => {
    dispatch({ type: CLOSE_MODAL });
    if (browserHistory && target) browserHistory.push(target);
  };

export const getContent =
  (params, callback = null) =>
  async (dispatch) => {
    // options:
    // params:  ids, page
    try {
      dispatch({ type: CONTENT_LOADING_IN_PROGRESS, payload: true });
      let filter = '';
      filter += params.component ? `&component=${params.component}` : '';
      filter += params.lang ? `&lang=${params.lang}` : '';
      filter += params.group ? `&group=${params.group}` : '';
      filter += params.groups ? `&groups=${params.groups.join()}` : '';
      const requestUri = `${config.apiUrl.cmsServer}/api/v2/cms/getcontent?${filter}`;
      const data = await internalApi('get', requestUri, null);
      dispatch({ type: CONTENT_SET, payload: data });
      // dispatch(clearLastErrorCode()); if enabled 404 error is not triggered anymore
      if (callback) callback();
    } catch (error) {
      console.log(error);
      dispatch({ type: CONTENT_LOADING_IN_PROGRESS, payload: false });
      dispatch(setLastErrorCode(getLocalErrorCode(error)));
    }
  };

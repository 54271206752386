import { Button, Flex, Spin, Typography } from 'antd';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { LeftOutlined } from '@ant-design/icons';

import { useGetDomainQuery, useSetDomainMutation, useVerifyDomainMutation } from '../../../api/integrationWizardSlice';
import EnterDomainForm from './domainSubsteps/EnterDomainForm';
import { usePrerenderUser } from '../../../../../hooks/usePrerenderUser';
import { useEvent } from '../../../../events/hooks/useEvent';
import BottomActionsContainer from '../../BottomActionsContainer';

const { Title, Paragraph, Text } = Typography;

const VerifyIntegrationStep = ({ onGoNext, onGoBack, currentStep }) => {
  const [domainInput, setDomainInput] = useState('');
  const [integrationWizardId, setIntegrationWizardId] = useState('');

  const { data, isFetching } = useGetDomainQuery(null, { refetchOnMountOrArgChange: true });
  const [setDomain, setDomainResult] = useSetDomainMutation();
  const [verifyDomain, verifyDomainResult] = useVerifyDomainMutation();

  const navigate = useNavigate();
  const prerenderUser = usePrerenderUser();
  const { track } = useEvent();

  useEffect(() => {
    if (data) {
      setDomainInput(data.domain || '');
      setIntegrationWizardId(data.id || '');
    }
  }, [data]);

  const onDomainEntered = (values) => {
    const newDomain = values.domain;
    setDomain(newDomain);
    setDomainInput('');
    handleVerification(newDomain, integrationWizardId);
  };

  const handleVerification = (newDomain, integrationWizardId) => {
    const url = newDomain.startsWith('http') ? newDomain : `https://${newDomain}`;
    verifyDomain({ url, integrationWizardId })
      .unwrap()
      .then(({ validationResult }) => {
        if (validationResult.working) {
          track('Verification Completed', {
            subscription_plan: prerenderUser.chargebeePlanId,
            url,
          });
          onGoNext();
        } else {
          track('Verification Failed', {
            subscription_plan: prerenderUser.chargebeePlanId,
            error_code: validationResult?.errors.length ? validationResult?.errors[0] : null,
            url,
          });
          navigate('/integration-wizard/verify-integration/failed', {
            state: { validationResult },
          });
        }
      })
      .catch(() => {
        track('Verification Failed - exception or timeout', { url, subscription_plan: prerenderUser.chargebeePlanId });
      });
  };

  return (
    <Flex vertical align="center" justify="center" gap="large" style={{ flexGrow: 1, width: '100%' }}>
      <Flex align="center" justify="center" vertical style={{ maxWidth: 620, marginBottom: 32 }}>
        <Title level={3}>Enter your domain</Title>
        <Paragraph>
          After integration, we test your setup by sending a request to your main page using the Bingbot user agent. If
          our system receives this request, your integration is validated.
        </Paragraph>
        <Paragraph>
          If Bingbot isn't included, we'll wait for your site to receive any other request to complete verification and
          you'll be notified once verification is successful.
        </Paragraph>

        <EnterDomainForm
          onFinish={onDomainEntered}
          domain={domainInput}
          disabled={isFetching || setDomainResult.isLoading}
          isLoading={verifyDomainResult.isLoading}
          error={setDomainResult.isError}
          onGoBack={onGoBack}
          currentStep={currentStep}
        />
        {isFetching && (
          <Text>
            Preparing for verification <Spin />
          </Text>
        )}
        {verifyDomainResult.isLoading && <Text>Verification in progress, this may take a few minutes...</Text>}
        {verifyDomainResult.isError && (
          <Text type="danger">
            An unexpected error occurred while trying to verify your integration. Please try again in a few minutes.
          </Text>
        )}
      </Flex>

      <BottomActionsContainer
        leftElement={
          <Button onClick={onGoBack} icon={<LeftOutlined />} type="text">
            Integrate
          </Button>
        }
      />
    </Flex>
  );
};

export default VerifyIntegrationStep;

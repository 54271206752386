import { Input, Form, Button, Space } from 'antd';
import { GlobalOutlined } from '@ant-design/icons';
import * as _ from 'lodash';
import { useEffect } from 'react';
import SpinningWheel from '../../../../../../components/SpinningWheel';
import { usePrerenderUser } from '../../../../../../hooks/usePrerenderUser';
import { useEvent } from '../../../../../events/hooks/useEvent';

const BLACKLISTED_DOMAINS = [
  'localhost',
  'example.com',
  'example.org',
  'example.net',
  'google.com',
  'facebook.com',
  'twitter.com',
  'test.com',
];

const EnterDomainForm = ({ onFinish, domain, disabled, error, isLoading }) => {
  const [form] = Form.useForm();
  const formDomain = Form.useWatch('domain', form);
  const { track } = useEvent();
  const prerenderUser = usePrerenderUser();

  useEffect(() => {
    const domainError = 'Entered domain is incorrect or not reachable. Please try again.';
    const trackingData = {
      domain_name: formDomain,
      subscription_plan: prerenderUser.chargebeePlanId,
    };

    if (error) {
      form.setFields([
        {
          name: 'domain',
          errors: [domainError],
        },
      ]);
      track('Integration Wizard: Domain Incorrect', {
        ...trackingData,
        error_message: domainError,
      });
    }
  }, [error, form]);

  useEffect(() => {
    if (domain) {
      form.setFieldsValue({ domain });
    }
  }, [domain, form]);

  const onSubmitForm = (values) => {
    const domainError = 'This domain is not allowed. Please enter a different domain.';
    const trackingData = {
      domain_name: values.domain,
      subscription_plan: prerenderUser.chargebeePlanId,
    };

    if (BLACKLISTED_DOMAINS.includes(values.domain)) {
      form.setFields([
        {
          name: 'domain',
          errors: [domainError],
        },
      ]);
      track('Integration Wizard: Domain Incorrect', {
        ...trackingData,
        error_message: domainError,
      });
      return;
    }
    onFinish(values);
    track('Integration Wizard: Domain Entered', {
      domain_name: values.domain,
      subscription_plan: prerenderUser.chargebeePlanId,
    });
  };

  if (_.isNil(domain) && !error) {
    return <SpinningWheel />;
  }

  return (
    <Form form={form} onFinish={onSubmitForm} initialValues={domain} style={{ marginTop: 32 }}>
      <Space.Compact style={{ width: '100%', justifyContent: 'center' }}>
        <Form.Item
          style={{ width: '100%' }}
          name="domain"
          rules={[
            {
              required: true,
              message: 'Please enter your domain',
            },
          ]}
        >
          <Input placeholder="https://example.com" prefix={<GlobalOutlined />} autoFocus disabled={disabled} />
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit" disabled={disabled} loading={isLoading}>
            Verify Domain
          </Button>
        </Form.Item>
      </Space.Compact>
    </Form>
  );
};

export default EnterDomainForm;

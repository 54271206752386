import React from 'react';
import { Avatar, Modal, Skeleton, Table } from 'antd';
import { Typography } from 'antd';
import { useGetSeoScoreQuery } from './seoScoreLegacyApiSlice';

type SeoScoreModalProps = {
  open: boolean;
  onClose: () => void;
  url: { id: string };
};

export const SeoScoreModal = ({ open, onClose, url }: SeoScoreModalProps) => {
  const { data: seoScore, isLoading } = useGetSeoScoreQuery(url?.id, {
    skip: !open || !url?.id,
  });

  const hasRecommendations = seoScore?.recommendations && seoScore.recommendations.length > 0;

  return (
    <Modal
      title="Seo Score"
      open={open}
      onCancel={onClose}
      cancelText="Close"
      footer={(_, { CancelBtn }) => <CancelBtn />}
      width={550}
      destroyOnClose
    >
      <Skeleton loading={isLoading} active>
        <div>
          <Typography.Paragraph>
            Don’t worry about the score value, it’s just our number!
            <br />
            The score value is calculated based on existing and missing commonly used elements and it is designed to
            help you easily find pages that may need improvement using simple SEO tips and tricks. Feel free to check
            our{' '}
            <Typography.Link href="https://docs.prerender.io/docs/how-this-feature-works" target="_blank">
              recommendations
            </Typography.Link>{' '}
            and use them to optimize your pages.
          </Typography.Paragraph>
          {hasRecommendations ? (
            <Table dataSource={seoScore.recommendations} pagination={false} size="small">
              <Table.Column
                title="Score"
                dataIndex="scoreDelta"
                key="scoreDelta"
                render={(scoreDelta) => <Avatar>{scoreDelta}</Avatar>}
                width="10%"
                align="center"
              />
              <Table.Column title="Description" dataIndex="description" key="description" width="60%" />
              <Table.Column
                title="Documentation"
                dataIndex="documentation"
                key="documentation"
                render={(documentation) => (
                  <Typography.Link href={documentation} target="_blank" rel="noreferrer">
                    Knowledge Base
                  </Typography.Link>
                )}
                width="20%"
              />
            </Table>
          ) : (
            <Typography.Paragraph>
              All is well; we have no recommendation for your content at this time.
            </Typography.Paragraph>
          )}
        </div>
      </Skeleton>
    </Modal>
  );
};

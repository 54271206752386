// imported by /reducers/index.js
// holds road search parameters and result

import { matchWith } from '../assets/lib';

import {
  RECACHEQUEUE_SET,
  RECACHEQUEUE_IN_PROGRESS,
  RECACHEQUEUE_RESET,
  CUSTOM_RECACHE_DELAY_SET,
  RENDERING_SPEED_SET,
} from '../actions/types';
import { calculateStats, EMPTY_STATS } from '../assets/pagingFunctions';

const DEFAULT_PAGE_SIZE = 200;

const emptyState = {
  inProgress: false,
  params: {
    page: 1,
    pageSize: DEFAULT_PAGE_SIZE,
    q: '',
    qCondition: 'contains',
  },
  stats: EMPTY_STATS,
  pages: [],
};

const initialState = {
  priority: emptyState,
  standard: emptyState,
};

export default (state = initialState, action) => {
  const { payload } = action;
  const bucket = payload && payload.stateBucket ? payload.stateBucket : 'priority';
  switch (action.type) {
    case RECACHEQUEUE_SET: {
      const newState = {
        inProgress: false,
        params: matchWith(state[bucket].params, payload.params),
        stats: calculateStats(payload, DEFAULT_PAGE_SIZE),
        pages: payload.data,
      };
      return {
        ...state,
        priority: bucket === 'priority' ? newState : state.priority,
        standard: bucket === 'standard' ? newState : state.standard,
      };
    }
    case RENDERING_SPEED_SET: {
      return { ...state, renderingSpeed: payload.data };
    }
    case RECACHEQUEUE_IN_PROGRESS:
      return {
        ...state,
        priority: bucket === 'priority' ? { ...state.priority, inProgress: payload.value } : state.priority,
        standard: bucket === 'standard' ? { ...state.standard, inProgress: payload.value } : state.standard,
      };

    case RECACHEQUEUE_RESET:
      return initialState;
    default:
      break;
  }
  return state;
};

import { BillingUser as User } from './user';
import { calculateCustomDealAddonsCost, getUserPlans } from './utils';
import { PlanWithCost, PlanCost, CustomDealDetails } from './types';

const percents: Array<number> = [
  0, // cant do 0 days
  0.91,
  0.44,
  0.28,
  0.2,
  0.16,
  0.13,
  0.11,
  0.09,
  0.08,
  0.07,
  0.02,
  0.02,
  0.02,
  0.02,
  0.02,
  0.02,
  0.02,
  0.02,
  0.02,
  0.02,
  0.02,
  0.02,
  0.02,
  0.02,
  0.02,
  0.01,
  0.01,
  0.01,
  0.01,
  0.01,
];

function getLegacyCostsByDay(days: number): PlanCost {
  if (days === 60) {
    return {
      free: 0,
      startup: 5,
      business: 11,
      growth: 20,
      premium: 35,
      enterprise: 60,
      upToOneMillion: 90,
      upToTwoMillion: 160,
      upToThreeMillion: 250,
      upToFourMillion: 350,
      upToFiveMillion: 400,
    };
  } else if (days === 90) {
    return {
      free: 0,
      startup: 4,
      business: 8,
      growth: 16,
      premium: 28,
      enterprise: 40,
      upToOneMillion: 70,
      upToTwoMillion: 110,
      upToThreeMillion: 180,
      upToFourMillion: 280,
      upToFiveMillion: 340,
    };
  } else {
    let startup = 15;
    let business = 35;
    let growth = 65;
    let premium = 120;
    let enterprise = 275;

    const pricePerRecache = 0.00015386666;

    if (days < 7) {
      for (let i = 6; i >= days; i -= 1) {
        startup += startup * percents[i];
        business += business * percents[i];
        growth += growth * percents[i];
        premium += premium * percents[i];
        enterprise += enterprise * percents[i];
      }
    } else if (days > 7) {
      for (let i = 8; i <= days; i += 1) {
        startup -= startup * percents[i];
        business -= business * percents[i];
        growth -= growth * percents[i];
        premium -= premium * percents[i];
        enterprise -= enterprise * percents[i];
      }
    }

    return {
      free: 0,
      startup: Math.ceil(startup),
      business: Math.ceil(business),
      growth: Math.ceil(growth),
      premium: Math.ceil(premium),
      enterprise: Math.ceil(enterprise),
      upToOneMillion: Math.ceil((30 / days) * 1000000 * pricePerRecache),
      upToTwoMillion: Math.ceil((30 / days) * 2000000 * pricePerRecache),
      upToThreeMillion: Math.ceil((30 / days) * 3000000 * pricePerRecache),
      upToFourMillion: Math.ceil((30 / days) * 4000000 * pricePerRecache),
      upToFiveMillion: Math.ceil((30 / days) * 5000000 * pricePerRecache),
    };
  }
}

export function getLegacyPlan(numCached: number, cacheFreshness: number): [string, number, number | null] {
  const costs: PlanCost = getLegacyCostsByDay(cacheFreshness);
  return numCached > 5000000
    ? ['High Volume', 0, null]
    : numCached > 4000000
    ? ['Up To Five Million', costs.upToFiveMillion, 5000000]
    : numCached > 3000000
    ? ['Up To Four Million', costs.upToFourMillion, 4000000]
    : numCached > 2000000
    ? ['Up To Three Million', costs.upToThreeMillion, 3000000]
    : numCached > 1000000
    ? ['Up To Two Million', costs.upToTwoMillion, 2000000]
    : numCached > 500000
    ? ['Up To One Million', costs.upToOneMillion, 1000000]
    : numCached > 200000
    ? ['Enterprise', costs.enterprise, 500000]
    : numCached > 100000
    ? ['Premium', costs.premium, 200000]
    : numCached > 50000
    ? ['Growth', costs.growth, 100000]
    : numCached > 20000
    ? ['Business', costs.business, 50000]
    : numCached > 250
    ? ['Startup', costs.startup, 20000]
    : ['Free', 0, 250];
}

export function calculateLegacyPlan(user: User, calculatedNumCached?: number): PlanWithCost {
  if (user.custom_price || user.custom_price === 0) {
    return {
      name: 'Custom',
      totalInCents: user.custom_price * 100,
      costPerMonth: user.custom_price,
      currencyCode: 'USD',
      planPriceInCents: user.custom_price * 100,
      addonsPriceInCents: 0,
      suggestedPlanName: null,
      limit: user.custom_cached_pages_limit || 0,
      cacheRefreshInterval: [1, 90],
    };
  }

  const numCached = calculatedNumCached !== undefined ? calculatedNumCached : user.cached_num_cached;

  const [name, costPerMonth, limit] = getLegacyPlan(numCached, user.environment.cache_freshness);

  const costPerMonthInCents = costPerMonth * 100;
  return {
    name,
    totalInCents: user.billing_period === 'yearly' ? costPerMonthInCents * 12 : costPerMonthInCents,
    costPerMonth,
    currencyCode: 'USD',
    planPriceInCents: costPerMonthInCents,
    addonsPriceInCents: 0,
    suggestedPlanName: null,
    limit,
    cacheRefreshInterval: [1, 90],
  };
}

export const calculateLegacyPlanForInvoice = async (user: User, calculatedNumCached: number) =>
  calculateLegacyPlan(user, calculatedNumCached);

function calculateMeteredPlan(user: User, customDeal: CustomDealDetails | null): PlanWithCost {
  const { addonCostInCents: planAddonCostInCent, currentPlan, suggestedPlan } = getUserPlans(user);

  const isCustomPrice = Number.isFinite(user.custom_price);
  const planCostInCents = customDeal
    ? customDeal.price
    : isCustomPrice
    ? user.custom_price * 100
    : currentPlan.costInCents;
  const addonCostInCents = customDeal
    ? calculateCustomDealAddonsCost(user, customDeal)
    : isCustomPrice
    ? 0
    : planAddonCostInCent;

  const total: number = planCostInCents + addonCostInCents;

  return {
    name: currentPlan.name,
    totalInCents: total,
    costPerMonth: total / 100,
    currencyCode: currentPlan.currencyCode,
    planPriceInCents: planCostInCents,
    addonsPriceInCents: addonCostInCents,
    suggestedPlanName: suggestedPlan ? suggestedPlan.name : null,
    limit: customDeal?.includedRenders || currentPlan.includedRenderedPages,
    cacheRefreshInterval: currentPlan.cacheRefreshInterval,
  };
}

export function calculatePlan(user: User, customDeal: CustomDealDetails | null): PlanWithCost {
  return user.is_metered_billing ? calculateMeteredPlan(user, customDeal) : calculateLegacyPlan(user);
}

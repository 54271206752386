import { useMemo, useState } from 'react';
import { Card, Flex, Typography, Button, theme } from 'antd';
import { CheckCircleFilled, LeftOutlined } from '@ant-design/icons';

import BottomActionsContainer from '../../BottomActionsContainer';
import Integrations from './Integrations';
import SpinningWheel from '../../../../../components/SpinningWheel';
import './documentation.css';
import { usePrerenderUser } from '../../../../../hooks/usePrerenderUser';
import { useEvent } from '../../../../events/hooks/useEvent';
import CurrentToken from '../../../../SecurityAndAccess/PrerenderToken/CurrentToken';
import { getDocument } from './docs/getDocument';

const { Paragraph, Title } = Typography;

const PRERENDER_DOC = 'prerender';

const IntegrationsTitle = ({ selectedOption }) => {
  if (selectedOption === PRERENDER_DOC) {
    return (
      <div>
        <Title level={4}>How does Prerender work?</Title>
      </div>
    );
  }
  return (
    <div>
      <Title level={4}>Follow this guide to integrate your site</Title>
      <Paragraph>
        Integration is quick, but it can be confusing if you’re not a developer. Marketers, bring on your dev team here.
      </Paragraph>
      <Paragraph>First, start by choosing your preferred integration on the left-hand side.</Paragraph>
    </div>
  );
};

const IntegrationStep = ({ onGoBack, onGoNext }) => {
  const prerenderUser = usePrerenderUser();
  const { track } = useEvent();
  const [selectedOption, setSelectedOption] = useState(PRERENDER_DOC);
  const { token } = theme.useToken();

  const docContent = useMemo(() => {
    const doc = getDocument(selectedOption);
    return doc?.html;
  }, [selectedOption]);

  const onDocButtonClick = () => {
    setSelectedOption(PRERENDER_DOC);
  };

  const onContinueClick = () => {
    track('Integration Wizard: Integration method selected', {
      integration_method: selectedOption !== PRERENDER_DOC ? selectedOption : null,
      subscription_plan: prerenderUser.chargebeePlanId,
    });
    onGoNext();
  };

  return (
    <Flex vertical gap="large" style={{ height: '100%', flexGrow: 1, width: '100%' }}>
      <Flex style={{ width: '100%', flexGrow: 1, width: '100%' }}>
        <div style={{ width: 260 }}>
          <Flex vertical gap="small">
            <Button
              type="default"
              block
              style={
                selectedOption === PRERENDER_DOC
                  ? {
                      backgroundColor: token.colorPrimaryBg,
                      borderColor: token.colorPrimary,
                    }
                  : {}
              }
              onClick={onDocButtonClick}
            >
              {selectedOption === PRERENDER_DOC && (
                <div style={{ position: 'absolute', color: token.colorPrimary, right: 28 }}>
                  <CheckCircleFilled />
                </div>
              )}
              How does Prerender work
            </Button>

            <Card title="Prerender token (API Token)" size="small" bordered>
              <CurrentToken showBanner={false} tokenStyle={{ fontSize: 16 }} />
            </Card>

            <Card title="Integration methods" size="small" bordered>
              <Integrations selectedMethod={selectedOption} setSelectedMethod={setSelectedOption} />
            </Card>
          </Flex>
        </div>
        <div style={{ width: 2, background: 'grey', margin: '0 8px', borderRadius: '4px' }}></div>
        <Flex vertical style={{ flexGrow: 1 }} gap="small">
          <IntegrationsTitle selectedOption={selectedOption} />
          <Card style={{ height: '100%', position: 'relative' }}>
            <div style={{ position: 'absolute', inset: 0, size: '100%', overflowY: 'scroll' }} key={selectedOption}>
              {!docContent && <SpinningWheel matchParent />}

              {docContent && <div className="documentation" dangerouslySetInnerHTML={{ __html: docContent }}></div>}
            </div>
          </Card>
        </Flex>
      </Flex>
      <BottomActionsContainer
        leftElement={
          <Button onClick={onGoBack} icon={<LeftOutlined />} type="text">
            Invite Team
          </Button>
        }
      >
        <Button type="primary" onClick={onContinueClick}>
          Verify Integration
        </Button>
      </BottomActionsContainer>
    </Flex>
  );
};

export default IntegrationStep;

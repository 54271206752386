import React from 'react';
import { message, Space, Tooltip, Radio, RadioChangeEvent, Spin } from 'antd';
import { useDispatch } from 'react-redux';

import { useEvent } from '../../events/hooks/useEvent';
import { usePrerenderUser } from '../../../hooks/usePrerenderUser';
import { prerenderUserPatch } from '../../../actions/prerenderUserActions';
import { useSetQueryParamsSettingsMutation } from '../../api/environmentApiSlice';
import { RadioGroupWithRole } from '../../../components/ButtonWithRole';
import { UserRole } from '../../PrerenderUser';

enum ParamSettings {
  IgnoreAllParams = 'ignoreAllParams',
  UseSpecificParams = 'useSpecificParams',
  IgnoreSomeParams = 'ignoreSomeParams',
}

function mapToRadioValue(ignoreAllQueryParams: boolean, queryParamsWhitelist: boolean) {
  if (ignoreAllQueryParams) return ParamSettings.IgnoreAllParams;
  if (queryParamsWhitelist) return ParamSettings.UseSpecificParams;
  return ParamSettings.IgnoreSomeParams;
}

const RADIO_OPTIONS = [
  {
    label: 'Ignore specific query parameters',
    tooltip:
      "Add the query parameters that need to be ignored when caching. These need to be marked as 'Ignore when Caching'",
    value: ParamSettings.IgnoreSomeParams,
  },
  {
    label: 'Ignore all query parameters',
    tooltip: 'Ignore all query parameters when caching',
    value: ParamSettings.IgnoreAllParams,
  },
  {
    label: 'Only cache specific query parameters',
    tooltip:
      "Ignore all query parameters when caching with the exception of specific ones. These need to be marked as 'Use for Caching'",
    value: ParamSettings.UseSpecificParams,
  },
];

function QueryParamsSettings() {
  const [setQueryParamsSettings, setQueryParamsSettingsResult] = useSetQueryParamsSettingsMutation();

  const { track } = useEvent();
  const dispatch = useDispatch();
  const prerenderUser = usePrerenderUser();

  React.useEffect(() => {
    if (setQueryParamsSettingsResult.isError) {
      message.error('An error occurred while updating the URL parameters settings. Please try again.');
    }
  }, [setQueryParamsSettingsResult.isError]);

  const onChange = (e: RadioChangeEvent) => {
    track('URL Parameters Settings Applied', {
      subscription_plan: prerenderUser.chargebeePlanId,
      list_caching_rule: e.target.value,
    });

    const payload = {
      ignoreAllQueryParams: e.target.value === ParamSettings.IgnoreAllParams,
      queryParamsWhitelist: e.target.value === ParamSettings.UseSpecificParams,
    };

    setQueryParamsSettings(payload)
      .unwrap()
      .then(() => {
        dispatch(prerenderUserPatch(payload));
      });
  };

  return (
    <Spin spinning={setQueryParamsSettingsResult.isLoading} tip="Saving preference...">
      <RadioGroupWithRole
        disabledFor={[UserRole.BillingManager, UserRole.Guest]}
        value={mapToRadioValue(prerenderUser.ignoreAllQueryParams, prerenderUser.queryParamsWhitelist)}
        onChange={onChange}
      >
        <Space direction="vertical" size="small">
          {RADIO_OPTIONS.map((item, index) => (
            <Tooltip key={index} title={item.tooltip} placement="top">
              <Radio value={item.value}>{item.label}</Radio>
            </Tooltip>
          ))}
        </Space>
      </RadioGroupWithRole>
    </Spin>
  );
}

export default QueryParamsSettings;

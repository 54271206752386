import React, { useState } from 'react';
import { Flex, Typography, InputNumber } from 'antd';
import type { FilterDropdownProps } from 'antd/es/table/interface';
import WidgetFooter from './WidgetFooter';

export type RenderedTimeFilter = Partial<{
  renderedTimeLow: number;
  renderedTimeHigh: number;
}>;

type RenderedTimeDropdownProps = Pick<FilterDropdownProps, 'confirm' | 'clearFilters'> & {
  selectedKeys: RenderedTimeFilter[];
  setSelectedKeys: (selectedKeys: RenderedTimeFilter[]) => void;
  label: string;
};

export const RenderedTimeDropdown = ({
  confirm,
  selectedKeys,
  setSelectedKeys,
  clearFilters,
  label,
}: RenderedTimeDropdownProps) => {
  const renderedTime = selectedKeys?.length > 0 ? selectedKeys[0] : {};
  const [disabled, setDisabled] = useState(
    renderedTime.renderedTimeLow === undefined && renderedTime.renderedTimeHigh === undefined
  );

  const onFieldChange = (field: keyof RenderedTimeFilter, value?: number) => {
    setSelectedKeys([{ ...renderedTime, [field]: value }]);
    setDisabled(false);
  };

  const onReset = () => {
    clearFilters?.();
    setDisabled(true);
  };

  return (
    <Flex vertical style={{ padding: 12 }}>
      <Typography.Paragraph>{label}</Typography.Paragraph>
      <Flex gap="small" align="center">
        Between
        <InputNumber
          placeholder="e.g.0"
          value={renderedTime.renderedTimeLow}
          onChange={(value) => onFieldChange('renderedTimeLow', value ?? undefined)}
          min={0}
          max={23}
        />
        and
        <InputNumber
          placeholder="e.g.24"
          value={renderedTime.renderedTimeHigh}
          onChange={(value) => onFieldChange('renderedTimeHigh', value ?? undefined)}
          min={1}
          max={24}
        />
        ago
      </Flex>
      <WidgetFooter onOk={() => confirm()} onReset={onReset} resetDisabled={disabled} />
    </Flex>
  );
};

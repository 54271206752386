import { restApiLegacy } from '../api/restApiSliceLegacy';

type Recommendation = {
  description: string;
  documentation: string;
  scoreDelta: number;
};

type SeoScoreResponse = {
  recommendations: Recommendation[];
  score: number;
  id: string;
};

const seoScoreApiSlice = restApiLegacy.injectEndpoints({
  endpoints: (builder) => ({
    getSeoScore: builder.query<SeoScoreResponse, string>({
      query: (id) => ({
        url: `/v2/score?id=${id}`,
        method: 'GET',
      }),
      providesTags: [
        { type: 'CachedPages', id: 'seoScore' },
        { type: 'CachedPages', id: 'URL' },
      ],
    }),
  }),
});

export const { useGetSeoScoreQuery } = seoScoreApiSlice;

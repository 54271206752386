import React from 'react';
import { Button, Badge, Popover, theme } from 'antd';
import { BellOutlined } from '@ant-design/icons';

import PreviewWidgetContent from './PreviewWidgetContent';
import { TOOLTIP_ROOT_ID } from '../../../components/Tooltip';

type PreviewWidgetProps = {
  unreadNotificationsCount: number;
};

function PreviewWidget({ unreadNotificationsCount }: PreviewWidgetProps) {
  const { token } = theme.useToken();

  return (
    <Popover trigger="click" content={<PreviewWidgetContent />} placement="bottom" destroyTooltipOnHide>
      <Badge
        count={unreadNotificationsCount}
        color={token.colorPrimary}
        offset={[-5, 5]}
        styles={{ root: { lineHeight: 'normal' } }}
      >
        <Button
          size="large"
          type="text"
          icon={<BellOutlined style={{ fontSize: 24 }} />}
          data-tooltip-id={TOOLTIP_ROOT_ID}
          data-tooltip-content="Notifications"
          data-tooltip-place="bottom"
        />
      </Badge>
    </Popover>
  );
}

export default PreviewWidget;

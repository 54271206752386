import React from 'react';
import { Flex, Modal, App, Typography } from 'antd';
import { CheckOutlined, DeleteOutlined } from '@ant-design/icons';

import ButtonWithRole from '../../../components/ButtonWithRole';
import { UserRole } from '../../PrerenderUser';
import { useCharbeePlanId, useUserRole } from '../../../hooks/usePrerenderUser';
import { useEvent } from '../../events/hooks/useEvent';
import { TOOLTIP_ROOT_ID } from '../../../components/Tooltip';
import { useDeleteDomainMutation } from '../api/domainManagerApiSlice';

type ActionsCellProps = {
  domain: string;
  numberOfUrls: string;
  id: number;
  verified: boolean;
  disabled?: boolean;
  onVerifyIntegration: (domain: string) => void;
};

function ActionsCell({ domain, id, numberOfUrls, verified = false, onVerifyIntegration, disabled }: ActionsCellProps) {
  const [deleteModalOpen, setDeleteModalOpen] = React.useState(false);
  const [deleteDomain, deleteDomainResult] = useDeleteDomainMutation();

  const { message } = App.useApp();
  const role = useUserRole();
  const chargebeePlanId = useCharbeePlanId();

  const { track } = useEvent();

  const onDelete = () => {
    deleteDomain(id)
      .unwrap()
      .then(() => {
        message.success('Domain deleted successfully');
        track('Domain Deleted', { domain, subscription_plan: chargebeePlanId, numberOfUrls });
      })
      .catch(() => {
        message.error('Failed to delete domain');
      })
      .finally(() => setDeleteModalOpen(false));
  };

  return (
    <Flex gap={8} justify="center">
      <Modal
        open={deleteModalOpen}
        onCancel={() => setDeleteModalOpen(false)}
        title={`Deleting domain: ${domain}`}
        closable
        okText="Delete"
        okButtonProps={{ danger: true, type: 'primary', loading: deleteDomainResult.isLoading }}
        onOk={onDelete}
      >
        {parseInt(numberOfUrls) === 0 ? (
          <Typography.Paragraph>
            Are you sure you want to delete this domain <Typography.Text code>{domain}</Typography.Text>? This action is
            irreversible.
          </Typography.Paragraph>
        ) : (
          <Typography.Paragraph>
            Removing this domain <Typography.Text code>{domain}</Typography.Text>{' '}
            <Typography.Text strong>will remove all {numberOfUrls}</Typography.Text> associated pages from the cache.
            <br /> Please note the cache clear process might take some time.
          </Typography.Paragraph>
        )}
      </Modal>
      {!verified && (
        <div
          data-tooltip-id={TOOLTIP_ROOT_ID}
          data-tooltip-content={
            [UserRole.BillingManager, UserRole.TeamMember, UserRole.Guest].includes(role)
              ? 'Action only allowed for Account Owner and admin'
              : 'Verify Prerender integration'
          }
          data-tooltip-place="top"
        >
          <ButtonWithRole
            disabledFor={[UserRole.BillingManager, UserRole.TeamMember, UserRole.Guest]}
            icon={<CheckOutlined />}
            onClick={() => onVerifyIntegration(domain)}
            disabled={disabled}
          />
        </div>
      )}
      <div
        data-tooltip-id={TOOLTIP_ROOT_ID}
        data-tooltip-content={
          [UserRole.BillingManager, UserRole.TeamMember, UserRole.Guest].includes(role)
            ? 'Action only allowed for Account Owner and admin'
            : 'Delete domain'
        }
        data-tooltip-place="top"
      >
        <ButtonWithRole
          danger
          disabledFor={[UserRole.BillingManager, UserRole.TeamMember, UserRole.Guest]}
          icon={<DeleteOutlined />}
          loading={deleteDomainResult.isLoading}
          disabled={disabled}
          onClick={() => setDeleteModalOpen(true)}
        />
      </div>
    </Flex>
  );
}

export default React.memo(ActionsCell);

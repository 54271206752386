export const NotificationCategory = {
  Product: 'product',
  Billing: 'billing',
  Reports: 'reports',
};

export const NotificationType = {
  Update: 'update',
  PageReport: 'page-report',
  RenderLimit75: 'render-limit-75',
  RenderLimit100: 'render-limit-100',
  BetterPlanFound: 'better-plan-found',
  Text: 'text',
};

export type NotificationCategoryType = typeof NotificationCategory[keyof typeof NotificationCategory];
type NotificationTypeType = typeof NotificationType[keyof typeof NotificationType];

type Notification = {
  id: string;
  description: string;
  category: NotificationCategoryType;
  type: NotificationTypeType;
  metadata: Record<string, any>; // TODO: define metadata types
  createdAt: string;
  seenAt?: string;
};

export default Notification;

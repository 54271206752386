import { Alert, Col, Flex, message, Modal, Row, Table, Typography } from 'antd';
import React, { useEffect, useMemo, useState } from 'react';

import { usePrerenderUser } from '../../hooks/usePrerenderUser';
import { PrerenderUser } from '../PrerenderUser';
import { useClearManualQueueMutation } from '../RenderingQueue';
import { useEvent } from '../events/hooks/useEvent';
import { RecacheQueueTable } from './RecacheQueueTable';
import useQueryStringPagination from '../../utils/useQueryStringPagination';
import { PrevNextPaginationProps } from '../../components/PrevNextPagination';
import { Tabs } from '../../pages/NewScheduledRenderingPage';
import { useGetRecacheQueueQuery } from './RecacheQueueApiSlice';
import useQueryStringParameters from '../../utils/useQueryStringParameters';
import { buildRecacheQueueQueryParams, RecacheQueueQueryParams } from './QueryParameters';

const { Link } = Typography;

const PAGE_SIZE = 100;

const DAY_IN_MS = 24 * 60 * 60 * 1000;

const getDelayBetweenUrls = (user: PrerenderUser) => {
  const { cacheFreshness, allURLCount, customRecacheDelay } = user;
  let multiplier = 1;
  let delayBetweenURLs = Math.floor((cacheFreshness * DAY_IN_MS) / (allURLCount || 1));

  if (customRecacheDelay) {
    delayBetweenURLs = customRecacheDelay;
  }

  if (delayBetweenURLs > 60000) {
    delayBetweenURLs = 60000;
  }

  if (!customRecacheDelay) {
    multiplier = 0.99;
  }
  return Math.ceil(delayBetweenURLs * multiplier);
};

export const ManualQueueTab = () => {
  const [messageApi, messageContext] = message.useMessage();
  const prerenderUser = usePrerenderUser();
  const { track } = useEvent();

  const [openConfirmModal, setOpenConfirmModal] = useState(false);
  const { pagination, updatePagination } = useQueryStringPagination(PAGE_SIZE);
  const { queryStrings, updateParameter } =
    useQueryStringParameters<RecacheQueueQueryParams>(buildRecacheQueueQueryParams);

  const [clearManualQueue, { isLoading: isClearingManualQueue }] = useClearManualQueueMutation();
  const recacheQueueResult = useGetRecacheQueueQuery({
    stateBucket: 'priority',
    params: { ...queryStrings, ...pagination },
  });

  useEffect(() => {
    if (recacheQueueResult.isError) {
      messageApi.error('An error occurred while fetching manual queue, please try again later');
    }
  }, [recacheQueueResult.isError]);

  const paginationInfos: PrevNextPaginationProps = {
    page: pagination.page,
    pageSize: pagination.pageSize,
    onPrevClick: pagination.page > 1 ? () => updatePagination(pagination.page - 1) : undefined,
    onNextClick:
      recacheQueueResult.data?.length === pagination.pageSize ? () => updatePagination(pagination.page + 1) : undefined,
    currentPageAmount: recacheQueueResult.data?.length ?? 0,
  };

  const onClearManualQueue = () => {
    clearManualQueue({})
      .unwrap()
      .then(() => {
        messageApi.success('Manual queue cleared successfully');
        recacheQueueResult.refetch();
        track('Render Queue Cleared Manually', {
          subscription_plan: prerenderUser.chargebeePlanId,
        });
      })
      .catch(() => {
        messageApi.error('Failed to clear manual queue');
      })
      .finally(() => {
        setOpenConfirmModal(false);
      });
  };

  const updateSearchCondition = (condition: string) => {
    updateParameter('qCondition', condition);
  };

  const updateSearchQuery = (query: string) => {
    if (!query || query?.length > 2) {
      updateParameter('q', query);
      track('Manual Recache Queue List Searched', {
        search_string: query,
        subscription_plan: prerenderUser.chargebeePlanId,
      });
    }
  };

  const recacheMetrics = useMemo(() => {
    const delayBetweenURLs = getDelayBetweenUrls(prerenderUser);
    return [
      {
        key: 'recache-metrics',
        num1Min: Math.ceil(60000 / delayBetweenURLs).toLocaleString('en-US'),
        num1Hour: Math.ceil((60000 * 60) / delayBetweenURLs).toLocaleString('en-US'),
        num1Day: Math.ceil((60000 * 60 * 24) / delayBetweenURLs).toLocaleString('en-US'),
      },
    ];
  }, [prerenderUser.cacheFreshness, prerenderUser.allURLCount, prerenderUser.customRecacheDelay]);

  const isLoading = recacheQueueResult.isFetching || prerenderUser.inProgress || isClearingManualQueue;

  return (
    <Flex vertical gap="middle">
      {messageContext}
      <Modal
        open={openConfirmModal}
        title="Clear Manual/API Queue"
        onOk={onClearManualQueue}
        onCancel={() => setOpenConfirmModal(false)}
        okButtonProps={{ loading: isClearingManualQueue, type: 'primary', danger: true }}
        okText="Clear Manual Queue"
      >
        <Typography.Paragraph>
          Do you want to clear all the URLs from the recaching queue? <br /> It will delete URLs from both manually and
          API generated requests.
        </Typography.Paragraph>
      </Modal>
      <Alert
        type="info"
        showIcon
        message={
          <span>
            Manual/API entries are created manually by adding URLs or selecting them to be re-cached from the Cache
            Manager URL list or via{' '}
            <Link href="https://docs.prerender.io/docs/6-api?highlight=Recache" target="_blank">
              API calls
            </Link>
          </span>
        }
      />
      <Row>
        <Col span={18}>
          <Table
            bordered
            size="small"
            dataSource={recacheMetrics}
            pagination={false}
            title={() => <Typography.Text strong>Render Speed (Number of URLs per interval)</Typography.Text>}
          >
            <Table.Column title="In 1 min" dataIndex="num1Min" key="num1Min" />
            <Table.Column title="In 1 hour" dataIndex="num1Hour" key="num1hour" />
            <Table.Column title="In 1 day" dataIndex="num1Day" key="num1day" />
          </Table>
        </Col>
      </Row>
      <RecacheQueueTable
        isLoading={isLoading}
        onClearCache={prerenderUser.trackingCodeInstalled ? () => setOpenConfirmModal(true) : undefined}
        showBlurring={!prerenderUser.trackingCodeInstalled}
        onReloadData={recacheQueueResult.refetch}
        queue={recacheQueueResult.data || []}
        activeTab={Tabs.ManualQueue}
        pagination={paginationInfos}
        currentSearchQuery={queryStrings.q || ''}
        currentQCondition={queryStrings.qCondition || 'like'}
        onSearchConditionChanged={updateSearchCondition}
        onTypingEnd={updateSearchQuery}
      />
    </Flex>
  );
};

import { http, HttpResponse } from 'msw';
import { faker } from '@faker-js/faker';

const getCachedPages = http.get('http://localhost:8443/api/cached-pages', () => {
  return HttpResponse.json([
    {
      domain: 'plannit.io',
      url: 'https://plannit.io/merchants/quebec/drummondville/valencia-entretien-paysagiste/ixriyjkshcmn',
      cache_state: 'Cached',
      user_id: 279127,
      last_refresh: '2025-02-26T10:11:30.340Z',
      requested_recache_at: null,
      adaptive_type: 'desktop',
      seo_score: null,
      created_at: '2025-01-02T16:48:04.216Z',
      id: 'de4f0f32-cf06-b7e2-2a2e-6e52662247a0',
      last_delivery_at: '2025-01-30T16:39:17.000Z',
      source: 'cdn',
      seo_score_status: 'unknown',
      next_cache_at: '2025-03-05T10:11:30.399Z',
    },
    {
      domain: 'plannit.io',
      url: 'https://plannit.io/merchants/quebec/la-prairie/botaniq/8jd3xavstbyn',
      cache_state: 'Cached',
      user_id: 279127,
      last_refresh: '2025-02-26T10:11:25.409Z',
      requested_recache_at: null,
      adaptive_type: 'desktop',
      seo_score: null,
      created_at: '2025-01-08T22:31:39.795Z',
      id: '9fd744c5-630b-abdd-cdeb-b9863a519168',
      last_delivery_at: '2025-01-31T17:00:54.000Z',
      source: 'cdn',
      seo_score_status: 'unknown',
      next_cache_at: '2025-03-05T10:11:25.465Z',
    },
    {
      domain: 'plannit.io',
      url: 'https://plannit.io/merchants/quebec/labelle/deneigement-labelle-neige-/l1s18au4xxf9?language=fr',
      cache_state: 'Cached',
      user_id: 279127,
      last_refresh: '2025-02-26T10:10:51.456Z',
      requested_recache_at: null,
      adaptive_type: 'mobile',
      seo_score: null,
      created_at: '2025-01-21T15:29:50.743Z',
      id: '75432854-434a-94df-350c-fa2e472f9352',
      last_delivery_at: '2025-02-16T23:12:30.000Z',
      source: 'cdn',
      seo_score_status: 'unknown',
      next_cache_at: '2025-03-05T10:10:51.521Z',
    },
    {
      domain: 'plannit.io',
      url: 'https://plannit.io/merchants/quebec/montreal/ph-digital/ld4f9wx4xyyp?language=en',
      cache_state: 'Cached',
      user_id: 279127,
      last_refresh: '2025-02-26T10:08:25.906Z',
      requested_recache_at: null,
      adaptive_type: 'desktop',
      seo_score: 14,
      created_at: '2025-01-26T17:17:18.209Z',
      id: '9fcacc51-eed5-cb86-3d3d-9fd1ac962490',
      last_delivery_at: '2025-01-26T17:17:18.000Z',
      source: 'cdn',
      seo_score_status: 'unknown',
      next_cache_at: '2025-03-05T10:08:26.503Z',
    },
    {
      domain: 'plannit.io',
      url: 'https://plannit.io/merchants/quebec/gaspe/comite-sportif-de-stmajorique/lyai8uapshuq',
      cache_state: 'Cached',
      user_id: 279127,
      last_refresh: '2025-02-26T10:08:21.402Z',
      requested_recache_at: null,
      adaptive_type: 'desktop',
      seo_score: 14,
      created_at: '2024-12-14T04:10:59.350Z',
      id: 'd2750aee-9f21-fc5a-79fa-0eabbc58d9c1',
      last_delivery_at: '2025-01-30T17:06:32.000Z',
      source: 'cdn',
      seo_score_status: 'unknown',
      next_cache_at: '2025-03-05T10:08:21.488Z',
    },
    {
      domain: 'plannit.io',
      url: 'https://plannit.io/merchants/florida/miami/walters/zhd52bwy0lcv?language=fr',
      cache_state: 'Cached',
      user_id: 279127,
      last_refresh: '2025-02-26T10:07:19.061Z',
      requested_recache_at: null,
      adaptive_type: 'mobile',
      seo_score: null,
      created_at: '2025-02-26T10:07:19.061Z',
      id: 'f7babc4a-dd6f-612f-62d0-423c30abe69e',
      last_delivery_at: null,
      source: 'cdn',
      seo_score_status: 'unknown',
      next_cache_at: '2025-03-05T10:07:19.112Z',
    },
    ...Array.from({ length: 30 }, (_, index) => ({
      domain: faker.internet.domainName(),
      url: faker.internet.url(),
      cache_state: 'Cached',
      user_id: faker.number.int({ min: 100000, max: 999999 }),
      last_refresh: faker.date.anytime(),
      requested_recache_at: null,
      adaptive_type: faker.helpers.arrayElement(['desktop', 'mobile']),
      seo_score: faker.number.int({ min: 1, max: 100 }),
      created_at: faker.date.anytime(),
      id: faker.string.uuid(),
      last_delivery_at: faker.date.anytime(),
      source: faker.helpers.arrayElement(['cdn', 'origin']),
      seo_score_status: faker.helpers.arrayElement(['unknown', 'good', 'bad']),
      next_cache_at: faker.date.anytime(),
    })),
  ]);
});

export default [getCachedPages];

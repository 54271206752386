export const enum AlertCodes {
  LITE_PLAN = 'litePlan-closed-date',
  UPGRADE_PLAN = 'upgradePlan-closed-date',
}

export function isOlderThan30Days(date: number): boolean {
  const now = Date.now();
  const thirtyDays = 30 * 24 * 60 * 60 * 1000;
  return now - date > thirtyDays;
}

export function restoreAlertsState(): void {
  localStorage.removeItem(AlertCodes.LITE_PLAN);
  localStorage.removeItem(AlertCodes.UPGRADE_PLAN);
}

export function isAlertClosed(alertCode: AlertCodes): boolean {
  const value = localStorage.getItem(alertCode);
  if (value === null) return false;

  const closedDate = parseInt(value, 10);

  if (isNaN(closedDate) || isOlderThan30Days(closedDate)) {
    localStorage.removeItem(alertCode);
    return false;
  }
  return true;
}

export function setAlertClosed(alertCode: AlertCodes): void {
  localStorage.setItem(alertCode, Date.now().toString());
}

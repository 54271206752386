import React, { useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Alert, Button } from 'antd';
import { ChargeBeePlanId } from '@prerender/billing-shared';

import { usePrerenderUser } from '../../hooks/usePrerenderUser';
import { useEvent } from '../events/hooks/useEvent';
import { isFreePlan } from '../../chargebee/chargebee';
import './DashboardAlert.css';
import { setAlertClosed, isAlertClosed, AlertCodes } from './alerts';

// AB Testing messages and groups according to the https://www.notion.so/saas-group/Header-Banner-Messages-0ed3cf6893184b86adf9ab323f06d82b
const MessageGroups = [
  {
    abGroup: '4',
    title: '260% Faster Indexing Starts Now',
    description: (
      <span>
        Experience the full potential of Prerender! Upgrade and <b>get found faster</b> by search engines and{' '}
        <b>boost SEO performance.</b>
      </span>
    ),
  },
  {
    abGroup: '6',
    title: 'Unlock Your Website’s Full Potential Now!',
    description: 'Your website can achieve more with Prerender. Unlock all the benefits you and your website deserve.',
  },
];

const UpgradeToPaidAlert = () => {
  const user = usePrerenderUser();
  const navigate = useNavigate();

  const { track } = useEvent();
  // AB Testing based on user id
  const message = MessageGroups[user.id % 2];

  useEffect(() => {
    track('Upgrade To Paid Alert Shown', { group: message.abGroup });
  }, []);

  const onUpgradeClick = () => {
    track('Upgrade To Paid Alert Clicked', { group: message.abGroup });
    navigate(`/billing/plans?utm_source=opportunity_banner_${message.abGroup}`);
  };

  const afterClose = () => {
    setAlertClosed(AlertCodes.UPGRADE_PLAN);
  };

  const isClosed = isAlertClosed(AlertCodes.UPGRADE_PLAN);
  if (isClosed) return null;

  return (
    <Alert
      message={message.title}
      description={message.description}
      banner={true}
      type="success"
      action={
        <Button type="primary" onClick={onUpgradeClick}>
          Upgrade Now
        </Button>
      }
      closable
      afterClose={afterClose}
    />
  );
};

const UpgradePlanAlert = () => {
  const user = usePrerenderUser();
  const location = useLocation();
  const showUpgradeAlert =
    isFreePlan(user.plan) &&
    !user.isCancelled &&
    user.trackingCodeInstalled &&
    !location.pathname.startsWith('/billing');

  if (showUpgradeAlert) return <UpgradeToPaidAlert />;

  return <></>;
};

export default UpgradePlanAlert;

import { http, HttpResponse } from 'msw';
import { faker } from '@faker-js/faker';

const getNotifications = http.get('http://localhost:3030/v3/notifications', () => {
  return HttpResponse.json({
    data: [
      {
        id: '49471538-a95e-4594-87f6-6064bfd2e882',
        description: 'Remove domain entries from the Domain Manager menu',
        category: 'product',
        type: 'update',
        metadata: {
          link: '/url-2',
          htmlContent:
            '<p data-block-id="15314f12-5049-4ce8-9fd3-15b79d597c34">This new feature is only available for users with Account Owner and Admin permissions. The Delete button will appear next to the domains when there are 0 domains cached, and the number of cached pages has not been updated for more than two days.</p><p data-block-id="451afd13-cd5a-420a-a501-da54a45e01c0" style="text-align:start;box-sizing:border-box;margin:0px 0px 1rem;padding:0px;color:rgb(26, 26, 27);font-family:Roboto, sans-serif;font-size:17px;font-style:normal;font-variant-ligatures:normal;font-variant-caps:normal;font-weight:400;letter-spacing:0.85px;orphans:2;text-indent:0px;text-transform:none;widows:2;word-spacing:0px;-webkit-text-stroke-width:0px;background-color:rgb(255, 255, 255);text-decoration-thickness:initial;text-decoration-style:initial;text-decoration-color:initial;"><img data-block-id="dea4b498-f4b1-4ffd-bdb9-26efc7b8e18c" src="https://cdn.document360.io/e5f3f977-bd66-4d96-92dc-172d32f21542/Images/Documentation/image(104).png" class="adv-wysiwyg-img fr-fic fr-dii" mediatype="img" alt="" title="image(104).png" width="auto" height="auto" dataalign="left" datadisplay="flex" data-type="media-content" fixaspectratio="false" autoaspectratio="true" shadow="no" border="no" round="no" link="" newtab=""></p>',
        },
        createdAt: '2024-10-17T08:22:16.175Z',
        seenAt: null,
      },
      {
        id: '5843c52d-e477-4ff0-ba12-d2e24f2ab890',
        description: 'Role-based access control',
        category: 'product',
        type: 'update',
        metadata: {
          link: '/url-1',
          htmlContent:
            '<p data-block-id="48d391bc-b87a-440b-976b-9e112a643e3b">From now on, you can now add unlimited team members with different permission levels. The new team roles offer a spectrum read-only, providing a flexible system tailored to each member\'s specific responsibilities.</p><p data-block-id="8bafde4d-afbe-45db-815a-44793921b8e2">This new feature improves collaboration and workflow efficiency by enabling delegated access control, specialized roles for billing and administration, and a scalable team structure. <a href="/v1/docs/28-team-accounts-multi-users" target="_self" translate="no">You can read more about it here</a>. Test</p><p data-block-id="01aea3d0-72fe-4cc1-a455-bcd15b6c92d1"><img data-block-id="ed2e19af-16c5-4325-a730-a099b2ef9513" src="https://cdn.document360.io/e5f3f977-bd66-4d96-92dc-172d32f21542/Images/Documentation/image(96).png" class="adv-wysiwyg-img fr-fic fr-dii" mediatype="img" alt="" title="image(96).png" width="auto" height="auto" dataalign="left" datadisplay="flex" data-type="media-content" fixaspectratio="false" autoaspectratio="true" shadow="no" border="no" round="no" link="" newtab=""></p>',
        },
        createdAt: '2024-10-17T08:16:44.699Z',
        seenAt: null,
      },
      {
        id: '2ea780ca-bcb9-4efc-b29a-6dcec231391c',
        description: '75% limit',
        category: 'billing',
        type: 'render-limit-75',
        metadata: {},
        createdAt: '2024-10-15T16:18:29.699Z',
        seenAt: null,
      },
      {
        id: '7ec78dd0-bb3b-452e-a1a4-16b0c2aa9bfd',
        description: '100% limit',
        category: 'billing',
        type: 'render-limit-100',
        metadata: {},
        createdAt: '2024-10-15T13:47:14.119Z',
        seenAt: '2024-10-15T13:52:15.078Z',
      },
      ...Array.from({ length: 20 }, () => ({
        id: faker.database.mongodbObjectId(),
        description: faker.lorem.sentence(),
        category: faker.helpers.arrayElement(['product', 'billing', 'reports']),
        type: faker.helpers.arrayElement([
          'update',
          'page-report',
          'render-limit-75',
          'render-limit-100',
          'better-plan-found',
          'text',
        ]),
        metadata: {},
        createdAt: faker.date.recent().toISOString(),
        seenAt: faker.datatype.boolean() ? faker.date.recent().toISOString() : null,
      })),
    ],
    totalCount: 24,
  });
});

export default [getNotifications];

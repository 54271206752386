import { restApi } from '../../api/restApiSlice';

import type Domain from '../Domain';
import { DomainListQueryParameters } from '../DomainListPage/DomainListQueryParameters';

export enum ExportStatus {
  Queued = 'queued',
  NotQueue = 'not queued',
  AlreadyExists = 'already exists',
}

type ExportQueryParameters = Partial<{
  sort: DomainListQueryParameters['sort'];
  sortDirection: DomainListQueryParameters['sortDirection'];
  q: string;
}>;

const domainStatisticsApiSlice = restApi.injectEndpoints({
  endpoints: (builder) => ({
    getDomainStatistics: builder.query<{ data: Domain[]; totalCount: number }, DomainListQueryParameters>({
      query: (params) => ({ url: `/domain-statistics`, method: 'GET', params }),
      providesTags: ['domainStatistics'],
    }),
    exportDomainsCsv: builder.mutation<{ status: ExportStatus }, ExportQueryParameters>({
      query: (params) => ({ url: `/domain-statistics/export`, method: 'POST', params }),
    }),
    deleteDomain: builder.mutation({
      query: (id) => ({ url: `/domain-statistics/${id}`, method: 'DELETE' }),
      invalidatesTags: ['domainStatistics', 'Domains'],
    }),
    getDomains: builder.query({
      query: (query) => ({ url: `/domains`, method: 'GET', params: { query } }),
      providesTags: ['Domains'],
    }),
  }),
});

export const {
  useGetDomainStatisticsQuery,
  useLazyGetDomainStatisticsQuery,
  useExportDomainsCsvMutation,
  useDeleteDomainMutation,
  useGetDomainsQuery,
} = domainStatisticsApiSlice;

const domainDetailsApiSlice = restApi.injectEndpoints({
  endpoints: (builder) => ({
    getDomainDetails: builder.query({
      query: (domain) => ({ url: `/domains/${domain}/config`, method: 'GET' }),
      providesTags: ['domainDetails'],
    }),
    getExtraStats: builder.query({
      query: (params) => ({ url: '/analytics/stats', method: 'GET', params }),
      providesTags: ['domainDetails'],
    }),
    updateDomainDetails: builder.mutation({
      query: ({ domain, ...body }) => ({ url: `/domains/${domain}/config`, method: 'PATCH', body }),
      invalidatesTags: ['domainDetails'],
    }),
  }),
});

export const { useGetDomainDetailsQuery, useUpdateDomainDetailsMutation, useGetExtraStatsQuery } =
  domainDetailsApiSlice;

const accountDetailsApiSlice = restApi.injectEndpoints({
  endpoints: (builder) => ({
    getAccountDetails: builder.query({
      query: () => ({ url: `/domains/company/config`, method: 'GET' }),
      providesTags: ['AccountDetails'],
    }),
  }),
});

export const { useGetAccountDetailsQuery } = accountDetailsApiSlice;

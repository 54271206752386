import { restApi } from '../api/restApiSlice';
import { legacyAPIRequest } from '../api/restApiSliceLegacy';

export enum StatsEndpoint {
  Total = 'recache-overview-stats',
  Failed = 'recache-500-stats',
}

type RenderHistoryStats = {
  time: number;
  number: number;
}[];

const renderHistoryApi = restApi.injectEndpoints({
  endpoints: (builder) => ({
    getRenderHistoryStats: builder.query<RenderHistoryStats, { endpoint: StatsEndpoint; domain?: string }>({
      queryFn: async ({ endpoint, domain }) => {
        const queryString = !!domain ? `?${new URLSearchParams({ domain }).toString()}` : '';

        try {
          const rawResult = await legacyAPIRequest({ url: `${endpoint}${queryString.toString()}`, method: 'GET' });
          if (!rawResult.ok) throw new Error('Failed to fetch render history stats');
          const result = await rawResult.json();
          return { data: result };
        } catch (err) {
          const error = err as Error;
          return { error: { status: 'FETCH_ERROR', error: error.message } };
        }
      },
    }),
  }),
});

export const { useGetRenderHistoryStatsQuery } = renderHistoryApi;

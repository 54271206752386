import React from 'react';
import { Flex, theme } from 'antd';
import { CheckOutlined, WarningOutlined } from '@ant-design/icons';

import { TOOLTIP_ROOT_ID } from '../../../components/Tooltip';

function VerifiedStateCell({ verified, deleted }: { verified: boolean; deleted: boolean }) {
  const { token } = theme.useToken();

  return (
    <Flex justify="center">
      {verified ? (
        <CheckOutlined style={{ fontSize: 20, color: deleted ? token.colorTextSecondary : token.colorPrimary }} />
      ) : (
        <div
          data-tooltip-id={TOOLTIP_ROOT_ID}
          data-tooltip-content="Prerender integration not detected"
          data-tooltip-place="top"
        >
          <WarningOutlined style={{ fontSize: 20, color: deleted ? token.colorTextSecondary : token.red }} />
        </div>
      )}
    </Flex>
  );
}

export default React.memo(VerifiedStateCell);

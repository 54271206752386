import { Alert, Flex, message } from 'antd';
import React, { useEffect } from 'react';
import { usePrerenderUser } from '../../hooks/usePrerenderUser';
import { RecacheQueueTable } from './RecacheQueueTable';
import useQueryStringPagination from '../../utils/useQueryStringPagination';
import { PrevNextPaginationProps } from '../../components/PrevNextPagination';
import { Tabs } from '../../pages/NewScheduledRenderingPage';
import { useGetRecacheQueueQuery } from './RecacheQueueApiSlice';
import { buildRecacheQueueQueryParams, RecacheQueueQueryParams } from './QueryParameters';
import useQueryStringParameters from '../../utils/useQueryStringParameters';
import { useEvent } from '../events/hooks/useEvent';

const PAGE_SIZE = 100;

export const AutomaticQueueTab = () => {
  const [messageApi, messageContext] = message.useMessage();
  const prerenderUser = usePrerenderUser();
  const { track } = useEvent();

  const { pagination, updatePagination } = useQueryStringPagination(PAGE_SIZE);
  const { queryStrings, updateParameter } =
    useQueryStringParameters<RecacheQueueQueryParams>(buildRecacheQueueQueryParams);

  const recacheQueueResult = useGetRecacheQueueQuery({
    stateBucket: 'standard',
    params: { ...queryStrings, ...pagination },
  });

  useEffect(() => {
    if (recacheQueueResult.isError) {
      messageApi.error('An error occurred while fetching automated queue, please try again later');
    }
  }, [recacheQueueResult.isError]);

  const paginationInfos: PrevNextPaginationProps = {
    page: pagination.page,
    pageSize: pagination.pageSize,
    onPrevClick: pagination.page > 1 ? () => updatePagination(pagination.page - 1) : undefined,
    onNextClick:
      recacheQueueResult.data?.length === pagination.pageSize ? () => updatePagination(pagination.page + 1) : undefined,
    currentPageAmount: recacheQueueResult.data?.length ?? 0,
  };

  const updateSearchCondition = (condition: string) => {
    updateParameter('qCondition', condition);
  };

  const updateSearchQuery = (query: string) => {
    if (!query || query?.length > 2) {
      updateParameter('q', query);
      track('Automated Recache Queue List Searched', {
        search_string: query,
        subscription_plan: prerenderUser.chargebeePlanId,
      });
    }
  };

  return (
    <Flex vertical gap="middle">
      {messageContext}
      <Alert
        type="info"
        showIcon
        message={
          <span>
            <span>
              The Automated Queue is a list of URLs that are automatically re-cached by Prerender.io at a frequency
              determined by the cache settings.
            </span>
          </span>
        }
      />
      <RecacheQueueTable
        isLoading={recacheQueueResult.isFetching || prerenderUser.inProgress}
        showBlurring={!prerenderUser.trackingCodeInstalled}
        onReloadData={recacheQueueResult.refetch}
        queue={recacheQueueResult.data || []}
        activeTab={Tabs.AutomatedQueue}
        pagination={paginationInfos}
        currentSearchQuery={queryStrings.q || ''}
        currentQCondition={queryStrings.qCondition || 'like'}
        onSearchConditionChanged={updateSearchCondition}
        onTypingEnd={updateSearchQuery}
      />
    </Flex>
  );
};

import React from 'react';
import { Card, message } from 'antd';

import ButtonWithRole from '../../../components/ButtonWithRole';
import { UserRole } from '../../PrerenderUser';
import { usePrerenderUser } from '../../../hooks/usePrerenderUser';
import { CheckListOptions } from '../../../components/PageElementsLib';
import URLParameter from './URLParameter';
import URLParameterEditor from './URLParameterEditor';
import UrlParametersSettingsTable from './UrlParametersSettingsTable';
import {
  useGetURLParametersQuery,
  useUpdateURLParameterMutation,
  useDeleteURLParameterMutation,
} from '../CacheManagerApi';

const enum SelectedRowsWidgetActions {
  UpdateRuleIgnore = 'updateRuleIgnore',
  UpdateRuleUse = 'updateRuleUse',
  DeleteParams = 'deleteParams',
}

function URLParameters() {
  const [selectedRows, setSelectedRows] = React.useState<URLParameter[]>([]);
  const [paramEditorModalVisible, setParamEditorModalVisible] = React.useState(false);
  const [URLBeingEdited, setURLBeingEdited] = React.useState<URLParameter>();

  const getURLParameterResult = useGetURLParametersQuery();
  const [updateURLParameter, updateURLParameterResult] = useUpdateURLParameterMutation();
  const [deleteURLParameter, deleteURLParameterResult] = useDeleteURLParameterMutation();

  const prerenderUser = usePrerenderUser();
  const [messageApi, messageContext] = message.useMessage();

  React.useEffect(() => {
    if (updateURLParameterResult.isError) messageApi.error('Failed to update URL parameter. Please try again later.');
    if (deleteURLParameterResult.isError) messageApi.error('Failed to delete URL parameter. Please try again later.');
  }, [updateURLParameterResult.isError, deleteURLParameterResult.isError]);

  const onSelectedItemsChange = (rows: URLParameter[]) => setSelectedRows(rows);

  const onRowEditClick = (item: URLParameter) => {
    setURLBeingEdited(item);
    setParamEditorModalVisible(true);
  };

  const onSelectedRowsWidgetAction = async (action: SelectedRowsWidgetActions) => {
    switch (action) {
      case SelectedRowsWidgetActions.UpdateRuleIgnore:
        await updateURLParameter(selectedRows.map((row) => ({ id: row.id, value: row.value, ignore: true }))).unwrap();
        setSelectedRows([]);
        break;
      case SelectedRowsWidgetActions.UpdateRuleUse:
        await updateURLParameter(selectedRows.map((row) => ({ id: row.id, value: row.value, ignore: false }))).unwrap();
        setSelectedRows([]);
        break;
      case SelectedRowsWidgetActions.DeleteParams:
        await deleteURLParameter(selectedRows.map((row) => row.id)).unwrap();
        setSelectedRows([]);
        break;
    }
  };

  return (
    <>
      {messageContext}
      <Card
        title="URL parameters"
        extra={
          <ButtonWithRole
            disabledFor={[UserRole.BillingManager, UserRole.Guest]}
            type="primary"
            size="small"
            onClick={() => setParamEditorModalVisible(true)}
          >
            Add parameter
          </ButtonWithRole>
        }
      >
        <URLParameterEditor
          open={paramEditorModalVisible}
          onClose={() => setParamEditorModalVisible(false)}
          urlParam={URLBeingEdited}
        />

        <UrlParametersSettingsTable
          isLoading={getURLParameterResult.isLoading}
          items={getURLParameterResult.data}
          hideIgnoreWhenCachingParams={prerenderUser.ignoreAllQueryParams || prerenderUser.queryParamsWhitelist}
          hideUseForCachingParams={prerenderUser.ignoreAllQueryParams || !prerenderUser.queryParamsWhitelist}
          onSelectedRowsChange={onSelectedItemsChange}
          onRowEditClick={onRowEditClick}
          selectedRows={selectedRows}
        />
      </Card>

      {/* @ts-ignore */}
      <CheckListOptions
        show
        count={selectedRows.length}
        name="Parameter"
        actions={[
          { name: SelectedRowsWidgetActions.UpdateRuleIgnore, text: 'Ignore' },
          { name: SelectedRowsWidgetActions.UpdateRuleUse, text: 'Use' },
          { name: SelectedRowsWidgetActions.DeleteParams, icon: 'trash-2' },
        ]}
        isActive={!updateURLParameterResult.isLoading && !deleteURLParameterResult.isLoading}
        onsubmit={onSelectedRowsWidgetAction}
        onclose={() => setSelectedRows([])}
      />
    </>
  );
}

export default URLParameters;

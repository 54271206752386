import React, { Suspense } from 'react';
import { useLocation, useRoutes } from 'react-router-dom';
import { RecoilRoot } from 'recoil';
import { ModalContainer } from './components/ModalLib';
import TrackerComponent from './components/TrackerComponent';
import CustifyJsSnipet from './features/custify/jsSnipet';
import { ViewEventListener } from './features/events/ViewEventListener';
import { UserSnap, HubSpot, useHotJar } from './features/CustomerSupport';
import './styles/app.css';

import { GlobalTooltipRoot } from './components/Tooltip';

import routes from './Routes';

export default () => {
  const elements = useRoutes(routes);
  const location = useLocation();

  const isDevelopment = process.env.NODE_ENV === 'development';

  const shouldLoadCustomerSupport = !isDevelopment && location.pathname !== '/raw-html';

  HubSpot.useHubSpot(shouldLoadCustomerSupport);

  useHotJar();

  return (
    <RecoilRoot>
      <UserSnap.UserSnapApiProvider>
        <TrackerComponent>
          <ViewEventListener />
          {!isDevelopment && <CustifyJsSnipet />}
          <Suspense fallback={<h1>Loading...</h1>}>
            <ModalContainer />
            <GlobalTooltipRoot />
            {elements}
          </Suspense>
        </TrackerComponent>
      </UserSnap.UserSnapApiProvider>
    </RecoilRoot>
  );
};

import React from 'react';
import { Routes, Route, Navigate, useNavigate, useLocation } from 'react-router-dom';
import { Typography, Tabs as TabsNavigation } from 'antd';

import { useEvent } from '../features/events/hooks/useEvent';
import AdminTemplate from '../layout/AdminTemplate';
import requireAuth from '../components/hocs/requireAuth';
import { CacheManagerTab, UrlParametersTab, IgnoredURLsTab } from '../features/cache-manager';

const enum Tabs {
  CacheManager = 'cache-manager',
  UrlParameters = 'url-parameters',
  IgnoredURLs = 'ignored-urls',
}

const KEY_LABEL_MAP = {
  [Tabs.CacheManager]: 'Cache Manager',
  [Tabs.UrlParameters]: 'URL Parameters',
  [Tabs.IgnoredURLs]: 'Ignored URLs',
};

const TABS = [
  { key: Tabs.CacheManager, label: KEY_LABEL_MAP[Tabs.CacheManager], component: CacheManagerTab },
  { key: Tabs.UrlParameters, label: KEY_LABEL_MAP[Tabs.UrlParameters], component: UrlParametersTab },
  { key: Tabs.IgnoredURLs, label: KEY_LABEL_MAP[Tabs.IgnoredURLs], component: IgnoredURLsTab },
];

const META_TAGS = {
  title: 'Cache Manager - Prerender.io',
};

function NewCacheManagerPage() {
  const navigate = useNavigate();
  const location = useLocation();
  const { track } = useEvent();

  const activeTab = React.useMemo(() => location.pathname.split('/').pop(), [location.pathname]);

  const onTabClick = (activeKey: string) => {
    track(`${KEY_LABEL_MAP[activeKey as Tabs]} tab viewed`);
    navigate(activeKey);
  };

  return (
    <AdminTemplate metaTags={META_TAGS}>
      <div>
        <Typography.Title level={2}>Cache Manager</Typography.Title>
        <Typography.Paragraph type="secondary">
          This section allows you to keep an eye on your cached pages with the options to manually re-cache or remove
          them as well as adjust your cache settings.
        </Typography.Paragraph>
      </div>
      <TabsNavigation activeKey={activeTab} items={TABS} onTabClick={onTabClick} />

      <Routes>
        {TABS.map(({ key, component: Component }) => (
          <Route path={key} key={key} element={<Component />} />
        ))}
        <Route path="*" element={<Navigate to={TABS[0].key} />}></Route>
      </Routes>
    </AdminTemplate>
  );
}

export default requireAuth(NewCacheManagerPage);

import { setupWorker } from 'msw/browser';

import userApi from './user';
import csmApi from './csm';
import domainManagerApi from './domainManager';
import billing from './billing';
import renderHistory from './renderHistory';
import notifications from './notifications';
import scheduleRendering from './scheduledRendering';
import integrationWizardDocs from './integrationWizard';
import cacheManager from './cacheManager';
import crawledPages from './crawledPages';
import sitemaps from './sitemaps';
import scheduledRendering from './scheduledRendering';
import cdnAnalytics from './cdnAnalytics';
import seoScore from './seoScore';

const ENV = process.env.REACT_APP_STAGE;

const handlers = [
  ...csmApi,
  ...userApi,
  ...domainManagerApi,
  ...renderHistory,
  ...notifications,
  ...scheduleRendering,
  ...integrationWizardDocs,
  ...cacheManager,
  ...crawledPages,
  ...sitemaps,
  ...scheduledRendering,
  ...cdnAnalytics,
  ...seoScore,
  ...billing,
];

function enableMockingOnDevelopment(mocksEnabled = false): Promise<void | ServiceWorkerRegistration> {
  if (ENV === 'production') {
    return new Promise((resolve) => resolve());
  }

  if (!mocksEnabled) {
    console.info('Mocks are disabled');
    return new Promise((resolve) => resolve());
  }

  const worker = setupWorker(...handlers);

  return worker.start({
    // This is going to perform unhandled requests
    // but print no warning whatsoever when they happen.
    onUnhandledRequest: 'bypass',
  });
}

export default enableMockingOnDevelopment;

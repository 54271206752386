import React, { useState } from 'react';
import { InputNumber, Select, Typography, Flex } from 'antd';
import { FilterDropdownProps } from 'antd/lib/table/interface';

import WidgetFooter from './WidgetFooter';

const enum SelectOptions {
  Equals = 'equals',
  Between = 'between',
}

export type StatusCodeFilter = Partial<{
  statusCodeLow: number;
  statusCodeHigh: number;
  statusCodeEq: number;
}>;

type StatusDropdownFilterProps = Pick<FilterDropdownProps, 'confirm' | 'clearFilters'> & {
  selectedKeys: StatusCodeFilter[];
  setSelectedKeys: (keys: StatusCodeFilter[]) => void;
};

const QUERY_OPTIONS = [
  { value: SelectOptions.Equals, label: 'Equals' },
  { value: SelectOptions.Between, label: 'Between' },
];

export function StatusDropdownFilter({
  confirm,
  selectedKeys,
  setSelectedKeys,
  clearFilters,
}: StatusDropdownFilterProps) {
  const statusCode = selectedKeys.length ? selectedKeys[0] : {};

  const [selectedValue, setSelectedValue] = useState<SelectOptions>(
    !!statusCode.statusCodeHigh || !!statusCode.statusCodeLow ? SelectOptions.Between : SelectOptions.Equals
  );
  const [equalsResetDisabled, setEqualsResetDisabled] = useState(!statusCode.statusCodeEq);
  const [betweenResetDisabled, setBetweenResetDisabled] = useState(
    !statusCode.statusCodeLow && !statusCode.statusCodeHigh
  );

  const onReset = () => {
    clearFilters?.();
    setEqualsResetDisabled(true);
    setBetweenResetDisabled(true);
  };

  const resetDisabled = selectedValue === SelectOptions.Equals ? equalsResetDisabled : betweenResetDisabled;
  return (
    <Flex vertical style={{ padding: 12 }}>
      <Typography.Paragraph>HTTP Status Code</Typography.Paragraph>
      <Flex gap="small" align="center">
        <Select
          onChange={(value) => setSelectedValue(value)}
          options={QUERY_OPTIONS}
          value={selectedValue}
          style={{ width: 120 }}
        />
        {selectedValue === 'between' && (
          <>
            <InputNumber
              min={100}
              max={599}
              placeholder="200"
              value={statusCode.statusCodeLow}
              onChange={(value) => {
                if (value) setSelectedKeys([{ statusCodeLow: value, statusCodeHigh: statusCode.statusCodeHigh }]);
                else setSelectedKeys([{ statusCodeHigh: statusCode.statusCodeHigh }]);
                setBetweenResetDisabled(false);
                setEqualsResetDisabled(true);
              }}
            />
            <Typography.Text>and</Typography.Text>
            <InputNumber
              min={100}
              max={599}
              placeholder="499"
              value={statusCode.statusCodeHigh}
              onChange={(value) => {
                if (value) setSelectedKeys([{ statusCodeHigh: value, statusCodeLow: statusCode.statusCodeLow }]);
                else setSelectedKeys([{ statusCodeLow: statusCode.statusCodeLow }]);
                setBetweenResetDisabled(false);
                setEqualsResetDisabled(true);
              }}
            />
          </>
        )}
        {selectedValue === 'equals' && (
          <InputNumber
            min={100}
            max={599}
            placeholder="200"
            value={statusCode.statusCodeEq}
            onChange={(value) => {
              if (value) setSelectedKeys([{ statusCodeEq: value }]);
              else setSelectedKeys([]);
              setEqualsResetDisabled(false);
              setBetweenResetDisabled(true);
            }}
          />
        )}
      </Flex>
      <WidgetFooter onReset={onReset} onOk={() => confirm()} resetDisabled={resetDisabled} />
    </Flex>
  );
}

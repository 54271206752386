import type Render from './Render';

export enum QueryParamNames {
  Sort = 'sort',
  SortDirection = 'sortDirection',
  Q = 'q',
  QCondition = 'qCondition',
  Domain = 'domain',
  Notnull = 'notnull',
  RenderedTimeLow = 'renderedTimeLow',
  RenderedTimeHigh = 'renderedTimeHigh',
  StatusCodeEq = 'statusCodeEq',
  StatusCodeLow = 'statusCodeLow',
  StatusCodeHigh = 'statusCodeHigh',
  ResponseTimeLow = 'responseTimeLow',
  ResponseTimeHigh = 'responseTimeHigh',
  Timedout = 'timedout',
  AdaptiveType = 'adaptive_type',
  Source = 'source',
}

export type Sort = 'time' | 'status_code' | 'render_time' | 'source';

export type RenderHistoryQueryParameters = Partial<{
  [QueryParamNames.Sort]: Sort;
  [QueryParamNames.SortDirection]: 'DESC' | 'ASC';
  [QueryParamNames.Q]: string;
  [QueryParamNames.QCondition]: string;
  [QueryParamNames.Domain]: Render['domain'];
  [QueryParamNames.Notnull]: string;
  [QueryParamNames.AdaptiveType]: Render['adaptive_type'][];
  [QueryParamNames.RenderedTimeLow]: number;
  [QueryParamNames.RenderedTimeHigh]: number;
  [QueryParamNames.StatusCodeEq]: number;
  [QueryParamNames.StatusCodeLow]: number;
  [QueryParamNames.StatusCodeHigh]: number;
  [QueryParamNames.ResponseTimeLow]: number;
  [QueryParamNames.ResponseTimeHigh]: number;
  [QueryParamNames.Timedout]: boolean;
  [QueryParamNames.Source]: Render['source'][];
}>;

export function isRenderHistoryParam(name: string): name is QueryParamNames {
  return Object.values(QueryParamNames).includes(name as QueryParamNames);
}

export function buildRenderHistoryQueryParameters(data: Record<string, string>): RenderHistoryQueryParameters {
  return Object.entries(data).reduce((acc, [name, value]) => {
    if (!isRenderHistoryParam(name)) return acc;

    const paramName = name as QueryParamNames;

    if (paramName === QueryParamNames.AdaptiveType) {
      acc[paramName] = value.split(',') as Render['adaptive_type'][];
      return acc;
    }

    if (paramName === QueryParamNames.SortDirection) {
      acc[paramName] = value as 'DESC' | 'ASC';
      return acc;
    }

    if (paramName === QueryParamNames.Sort) {
      acc[paramName] = value as Sort;
      return acc;
    }

    if (paramName === QueryParamNames.Timedout) {
      acc[paramName] = value === 'true';
      return acc;
    }

    if (paramName === QueryParamNames.RenderedTimeLow) {
      acc[paramName] = parseInt(value, 10);
      return acc;
    }

    if (paramName === QueryParamNames.RenderedTimeHigh) {
      acc[paramName] = parseInt(value, 10);
      return acc;
    }

    if (paramName === QueryParamNames.StatusCodeEq) {
      acc[paramName] = parseInt(value, 10);
      return acc;
    }

    if (paramName === QueryParamNames.StatusCodeLow) {
      acc[paramName] = parseInt(value, 10);
      return acc;
    }

    if (paramName === QueryParamNames.StatusCodeHigh) {
      acc[paramName] = parseInt(value, 10);
      return acc;
    }

    if (paramName === QueryParamNames.ResponseTimeLow) {
      acc[paramName] = parseInt(value, 10);
      return acc;
    }

    if (paramName === QueryParamNames.ResponseTimeHigh) {
      acc[paramName] = parseInt(value, 10);
      return acc;
    }

    if (paramName === QueryParamNames.Source) {
      acc[paramName] = value.split(',') as Render['source'][];
      return acc;
    }

    acc[paramName] = value;

    return acc;
  }, {} as RenderHistoryQueryParameters);
}

import { chargeBeePlans, PlanIdToNameMap } from './plans';
import { BillingUser as User } from './user';
import {
  ChargeBeePlan,
  PlanName,
  ChargeBeePlanId,
  PlanTier,
  UserPlans,
  BillingPeriod,
  CustomDealDetails,
} from './types';

export const isEnterprise = (planId: ChargeBeePlanId) =>
  planId === ChargeBeePlanId.Enterprise ||
  planId === ChargeBeePlanId.Enterprise2022 ||
  planId === ChargeBeePlanId.CustomEnterprise ||
  planId === ChargeBeePlanId.CustomEnterpriseYearly ||
  planId === ChargeBeePlanId.Custom2024;

export const isFree = (planId: ChargeBeePlanId) => planId === ChargeBeePlanId.Basic;

export const isCustom = (planId: ChargeBeePlanId) =>
  planId === ChargeBeePlanId.CustomEnterprise || planId === ChargeBeePlanId.CustomEnterpriseYearly;

export const getPlanById = (chargebeePlanId: string): ChargeBeePlan => {
  const plan = chargeBeePlans.find((p) => p.chargeBeePlanId === chargebeePlanId);

  if (!plan) throw new Error(`PlanId not found: ${chargebeePlanId}`);

  return plan;
};

export const isPlanAvailable = (user: User, currentTier: PlanTier, plan: ChargeBeePlan) => {
  if (plan.chargeBeePlanId === ChargeBeePlanId.Lite2024) {
    return user.chargebee_plan_id === ChargeBeePlanId.Lite2024;
  }
  switch (currentTier) {
    case PlanTier.Free:
    case PlanTier.Legacy:
    case PlanTier.P2024:
      return plan.tier === PlanTier.Free || plan.tier === PlanTier.P2024;
    case PlanTier.P2020:
    case PlanTier.P2022:
      return plan.tier === PlanTier.Free || plan.tier === PlanTier.P2022;
    case PlanTier.Custom:
      return false;
  }
};

export const getAvailablePlans = (user: User, currentTier: PlanTier) =>
  chargeBeePlans.filter((plan) => isPlanAvailable(user, currentTier, plan));

export const getHighestMeteredPlan = (chargebeePlanIds: Array<string>): ChargeBeePlan | undefined => {
  try {
    const meteredPlansInput = chargebeePlanIds.map(getPlanById);

    return chargeBeePlans
      .filter((plan) => !plan.legacy)
      .slice()
      .sort((rp, lp) => rp.index - lp.index)
      .reverse()
      .find((meteredPlan) => meteredPlansInput.includes(meteredPlan));
  } catch (error) {
    return undefined;
  }
};

const calculateAddonsCost = (user: User, plan: ChargeBeePlan): number => {
  if (plan.legacy) {
    return 0;
  }

  return (
    plan.addonCostInCents * Math.max(0, Math.ceil((user.cached_render_counter - plan.includedRenderedPages) / 1000))
  );
};

export const calculateCustomDealAddonsCost = (user: User, customDeal: CustomDealDetails): number => {
  return (
    customDeal.extraRendersPrice *
    Math.max(0, Math.ceil((user.cached_render_counter - customDeal.includedRenders) / 1000))
  );
};

const calculatePlanCost = (user: User, plan: ChargeBeePlan): number => {
  if (plan.legacy) {
    return 0;
  }

  return plan.costInCents + calculateAddonsCost(user, plan);
};

const getSuggestedPlan = (pUser: User, currentPlan: ChargeBeePlan): ChargeBeePlan => {
  if (currentPlan.legacy || isEnterprise(currentPlan.chargeBeePlanId)) {
    return null;
  }

  // make a dummy estimation for renders
  const user = {
    ...pUser,
    cached_render_counter: Math.max(
      Math.floor(pUser.cached_num_cached * (30 / pUser.environment.cache_freshness)),
      pUser.cached_render_counter
    ),
  };

  const availablePlans = getAvailablePlans(user, currentPlan.tier).filter(
    (p) => p.chargeBeePlanId !== ChargeBeePlanId.Basic
  );

  if (user.cached_render_counter < getPlanById(ChargeBeePlanId.Basic).includedRenderedPages) {
    return null;
  }

  // do not suggest free
  let suggestedPlan: ChargeBeePlan = isFree(currentPlan.chargeBeePlanId) ? availablePlans[0] : null;
  const currentPlanCost: number = isFree(currentPlan.chargeBeePlanId)
    ? calculatePlanCost(user, availablePlans[0])
    : calculatePlanCost(user, currentPlan);

  // we need the find the last plan which cost less than the actual plan + addons
  for (let i = availablePlans.length - 1; i > availablePlans.indexOf(currentPlan); i--) {
    const p: ChargeBeePlan = availablePlans[i];
    const planCost = calculatePlanCost(user, p);
    if (planCost < currentPlanCost) {
      suggestedPlan = p;
      break;
    }
  }

  if (
    suggestedPlan &&
    (suggestedPlan.index <= currentPlan.index || suggestedPlan.chargeBeePlanId == ChargeBeePlanId.Lite2024)
  ) {
    suggestedPlan = null;
  }

  return suggestedPlan;
};

const getPlans = (user: User, currentPlan: ChargeBeePlan, suggestedPlan: ChargeBeePlan): Array<ChargeBeePlan> =>
  chargeBeePlans
    .filter((plan) => {
      if (user.is_metered_billing) {
        return !plan.legacy;
      } else {
        return true;
      }
    })
    .map((plan) => {
      if (plan.chargeBeePlanId === currentPlan.chargeBeePlanId) {
        return { ...plan, current: true };
      } else if (suggestedPlan && plan.chargeBeePlanId === suggestedPlan.chargeBeePlanId) {
        return { ...plan, suggested: true };
      } else {
        return { ...plan };
      }
    })
    .map((plan) => ({
      ...plan,
      available: isPlanAvailable(user, currentPlan.tier, plan),
    }));

export const getUserPlans = (user: User): UserPlans => {
  const currentPlan: ChargeBeePlan = getPlanById(user.chargebee_plan_id);
  const addonCostInCents: number = calculateAddonsCost(user, currentPlan);
  const suggestedPlan: ChargeBeePlan = getSuggestedPlan(user, currentPlan);
  const plans: Array<ChargeBeePlan> = getPlans(user, currentPlan, suggestedPlan)
    .map((p) => ({ ...p }))
    .filter((plan) => plan.available || plan.chargeBeePlanId === currentPlan.chargeBeePlanId);

  return {
    currentPlan,
    addonCostInCents,
    suggestedPlan,
    plans,
  };
};

export function chargeBeePlanIdToPlanName(planId: ChargeBeePlanId): string {
  const planName = PlanIdToNameMap[planId];
  return planName || PlanName.Legacy;
}

export function calculateCostToPlanPercentage(billedBy: number, plan: ChargeBeePlan) {
  return Math.round((billedBy / plan.includedRenderedPages) * 100);
}

export function calculateBillingCycleElapsedPercentage(
  lastCharged: Date,
  billingPeriod: BillingPeriod,
  currentTime = new Date()
) {
  const nextCharge = new Date(lastCharged);

  if (billingPeriod === 'yearly') {
    nextCharge.setFullYear(lastCharged.getFullYear() + 1);
  } else {
    nextCharge.setMonth(lastCharged.getMonth() + 1);
  }

  const totalTime = +nextCharge - +lastCharged; // e.g. 30 days
  const elapsedTime = +currentTime - +lastCharged; // e.g. 14 days

  return Math.round((elapsedTime / totalTime) * 100); // e.g. (14 / 30) * 100 = 47%
}

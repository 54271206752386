import React from 'react';
import { Button, Flex, Typography, Divider, Spin, Empty, Checkbox } from 'antd';
import { CheckOutlined } from '@ant-design/icons';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import { useEvent } from '../../events/hooks/useEvent';
import { useGetNotificationsQuery, useMarkAsReadMutation } from '../NotificationsApiSlice';
import { getAgeString } from '../../../assets/time';
import { decreaseNotificationCount, prerenderUserPatch } from '../../../actions/prerenderUserActions';

const CACHED_API_EXPIRATION = 60 * 30; // Seconds => 30 minutes

function PreviewWidgetContent() {
  const [selectedIds, setSelectedIds] = React.useState<Record<string, boolean>>({});

  const notificationListResult = useGetNotificationsQuery(
    { unread: true },
    { refetchOnMountOrArgChange: CACHED_API_EXPIRATION }
  );
  const [markAsRead, marAsReadResult] = useMarkAsReadMutation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { track } = useEvent();

  const numberOfSelected = Object.keys(selectedIds).length;
  const unreadNotifications = notificationListResult.data?.data || [];

  const onNotificationToggle = (id: string, checked: boolean) => {
    setSelectedIds((prev) => {
      const newIds = { ...prev };
      if (checked) {
        newIds[id] = true;
      } else {
        delete newIds[id];
      }
      return newIds;
    });
  };

  const onMarkAsReadClicked = () => {
    if (numberOfSelected === 0) {
      track('Notification widget - Marked all as read');
      markAsRead(unreadNotifications.map((notification) => notification.id))
        .unwrap()
        .then(() => {
          dispatch(prerenderUserPatch({ unreadNotifications: 0 }));
        });
    } else {
      track(`Notification widget - Marked ${numberOfSelected} as read`);
      markAsRead(Object.keys(selectedIds))
        .unwrap()
        .then(() => {
          dispatch(decreaseNotificationCount(numberOfSelected));
          setSelectedIds({});
        });
    }
  };

  const onNotificationClick = (id: string) => {
    track('Notification widget - Notification clicked');
    navigate(`/notifications/${id}`);
  };

  return (
    <div style={{ maxWidth: '400px', width: '350px' }}>
      <Flex align="center" justify="space-between" gap={16}>
        <Typography.Title style={{ margin: 0 }} level={5}>
          New notifications
        </Typography.Title>
        {!!unreadNotifications.length && (
          <Button
            variant="text"
            color="primary"
            icon={<CheckOutlined />}
            onClick={onMarkAsReadClicked}
            loading={marAsReadResult.isLoading}
          >
            {numberOfSelected ? `Mark ${numberOfSelected} as read` : 'Mark all as read'}
          </Button>
        )}
      </Flex>
      <Divider style={{ margin: '12px 0' }} />
      {notificationListResult.isLoading && <Spin />}

      {!unreadNotifications.length && <Empty description="You are all caught up" />}

      <div style={{ maxHeight: 400, overflowY: 'auto', scrollbarWidth: 'thin', paddingRight: 5 }}>
        <Flex justify="center" vertical gap={22}>
          {unreadNotifications.map((notification) => (
            <div
              key={notification.id}
              onClick={() => onNotificationClick(notification.id)}
              style={{ cursor: 'pointer' }}
            >
              <Typography.Text strong>{notification.description}</Typography.Text>
              <Flex align="center" justify="space-between" style={{ marginTop: 5 }}>
                <Typography.Text type="secondary">
                  {getAgeString(new Date(notification.createdAt), 'ago', 'full')}
                </Typography.Text>
                <div onClick={(e) => e.stopPropagation()}>
                  <Checkbox
                    onChange={(e) => onNotificationToggle(notification.id, e.target.checked)}
                    checked={selectedIds[notification.id]}
                  />
                </div>
              </Flex>
            </div>
          ))}
        </Flex>
      </div>
      <Link to="/notifications">
        <Button style={{ marginTop: 16 }} block>
          All notifications
        </Button>
      </Link>
    </div>
  );
}

export default PreviewWidgetContent;

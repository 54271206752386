// HOC
// a higher-order component is a function that takes a component and returns a new component.
// Higher order component for components that require login:

import React, { useEffect } from 'react';
import { connect, ConnectedProps, useStore } from 'react-redux';
import { Navigate, useNavigate } from 'react-router-dom';

import { RootState, AppDispatch } from '../../reduxTypes';
import { getPrerenderUserThrows } from '../../actions/prerenderUserActions';
import SpinningWheel from '../SpinningWheel';
import { PrerenderUser } from '../../features/PrerenderUser';

function mapStateToProps(state: RootState) {
  return {
    kcError: state.keycloak.error,
    kcLoggedIn: state.keycloak.loggedIn,
    hasSession: state.prerenderUser.hasSession,
    featureFlags: state.prerenderUser.features,
    role: state.prerenderUser.role,
    email: state.prerenderUser.email,
    chargebeePlanId: state.prerenderUser.chargebeePlanId,
  };
}

// provide access to selected actions:
function mapDispatchToProps(dispatch: AppDispatch) {
  return {
    getPrerenderUserThrows: () => dispatch(getPrerenderUserThrows()),
  };
}

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

export type IsAuthorizedFn = (
  props: Pick<PrerenderUser, 'role' | 'email' | 'chargebeePlanId'> & { featureFlags: PrerenderUser['features'] }
) => boolean;

export default function requireAuth(
  ComposedComponent: React.ComponentType<PropsFromRedux>,
  isAuthorizedFn?: IsAuthorizedFn
) {
  const RequireAuth = (props: PropsFromRedux) => {
    const { kcError, kcLoggedIn, hasSession, getPrerenderUserThrows: doGetPrerenderUserThrows } = props;

    const navigate = useNavigate();
    const store = useStore();

    useEffect(() => {
      if (kcLoggedIn && !hasSession) {
        doGetPrerenderUserThrows()
          .then(() => {
            const { token, showRegistrationSteps, id } = store.getState().prerenderUser;
            if (showRegistrationSteps) {
              navigate('/registration');
            } else {
              window.saveJourney(token, id);
            }
          })
          .catch((e: Error) => {
            console.error('Unhandled auth error', e);
            navigate('/error-uncaught');
          });
      }
    }, [kcLoggedIn, hasSession, doGetPrerenderUserThrows, navigate, store]);

    if (kcError) return <h3>{kcError}</h3>;

    if (!kcLoggedIn || !hasSession) return <SpinningWheel />;

    if (isAuthorizedFn) {
      const { role, email, featureFlags, chargebeePlanId } = props;
      if (!isAuthorizedFn({ featureFlags, role, email, chargebeePlanId })) return <Navigate to="/404" />;
    }

    return <ComposedComponent {...props} />;
  };

  return connector(RequireAuth);
}

import React from 'react';

import type { Source } from './Render';
import { TOOLTIP_ROOT_ID } from '../../components/Tooltip';

export const SourceLabels: Record<Source, { label: string; tooltip: string }> = {
  automated: {
    label: 'Automated',
    tooltip: 'Automated rendering',
  },
  cdn: {
    label: 'CDN',
    tooltip: '',
  },
  manual: {
    label: 'Manual',
    tooltip: 'Manually requested rendering',
  },
} as const;

export function SourceTableCell({ sourceName }: { sourceName: Source }) {
  const source = SourceLabels[sourceName];

  if (!source) return <span>Unknown</span>;

  return (
    <div data-tooltip-id={TOOLTIP_ROOT_ID} data-tooltip-content={source.tooltip} data-tooltip-place="top">
      {source.label}
    </div>
  );
}

export default Source;
